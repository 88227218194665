import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import logo from '../../../images/logo.svg';
import '../../Login/styles.css';
import IconButton from '../../../components/IconButton';
import { FaExternalLinkAlt, FaLink } from 'react-icons/fa';

export default function CadastroEventoParticipanteContratoEnviado({ match }) {
  const id = match.params.id;
  const token = localStorage.getItem('token');
  const [evento, setEvento] = useState({});
  const history = useHistory();

  const buscar = () => {
    api({
      method: "get",
      action: '/eventoSemLogin/' + id,
    }).then(response => {
      var dadosEvento = response.data.data;
      setEvento(dadosEvento);
    }).catch(err => {
      //console.log(err)
    });
  };//buscar

  useEffect(() => { //onload
    buscar();
  },
    // eslint-disable-next-line
    []);

  return (
    <div id="auth" style={{
      backgroundImage: "none",
      backgroundColor: evento?.cor ?? "#AAAAAA",
    }}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card pt-4">
              <div className="card-body">
                <div className="text-center mb-5">
                  {localStorage.getItem('logomarcaEvento') ?
                    <img alt="" src={localStorage.getItem('logomarcaEvento')} height="48" className='mb-4' /> :
                    <img alt="" src={logo} height="48" className='mb-4' />
                  }
                  <p>Sua participação foi confirmada para o evento <strong>{evento?.nome ?? ""}</strong><br />
                    O contrato foi enviado para o seu e-mail para assinatura eletrônica. Por favor verifique seu email.</p>
                  <p>Grupo Access-Mkt - Horário de atendimento de Segunda a Sexta das 09:00 às 18:00<br />
                    Telefone: (11) 3777-8414</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
