import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaChartPie, FaCodeBranch, FaRegCalendarCheck, FaPen, FaList, FaPlus, FaSearch } from 'react-icons/fa';
import { GiStairsGoal } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactSelect from '../../components/ReactSelect';
import FormButton from '../../components/FormButton';
import Data from '../../components/Data';

export default function RelatorioCompra() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({
        tipo: "1",
        aprovacao: "aprovado",
        empresa: empresa,
    });
    const [slas, setSlas] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Relatório de Faturas</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" method="post" target="_blank" action={process.env.REACT_APP_API_URL + "/venda/relatorioCompra"}>
                                            <input type="hidden" name="token" value={localStorage.getItem('token')} />
                                            <input type="hidden" name="empresa" value={localStorage.getItem('empresa')} />
                                            {['diretor', 'gerentefinanceiro', 'financeiro'].includes(perfil) ?
                                                <input type="hidden" name="despesasInternas" value="sim" /> : null}
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            name="evento"
                                                            value={{ value: filtro.evento, label: filtro.nomeEvento }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <ReactSelect
                                                            name="tipo"
                                                            value={{ value: filtro.tipo, label: filtro.nomeTipo }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "tipo": e.value, "nomeTipo": e.label } });
                                                            }}
                                                            options={[
                                                                { value: "", label: 'Todas' },
                                                                { value: "1", label: 'Fatura' },
                                                                { value: "2", label: 'Requisição' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Fornecedor</label>
                                                        <ReactSelect
                                                            name="cliente"
                                                            value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: ["fornecedor", "empresa"],
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Aprovação</label>
                                                        <ReactSelect
                                                            name="aprovacao"
                                                            value={{ value: filtro.aprovacao, label: filtro.nomeAprovacao }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "aprovacao": e.value, "nomeAprovacao": e.label } });
                                                            }}
                                                            options={[
                                                                { value: "", label: 'Todas' },
                                                                { value: "aprovado", label: 'Aprovado' },
                                                                { value: "reprovado", label: 'Reprovado' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="data1"
                                                        label="Data Emissão"
                                                        value={filtro.data1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="data2"
                                                        label="até a Data Emissão"
                                                        value={filtro.data2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
