import React from 'react';
import { useHistory } from 'react-router-dom';
import ErpTemplate from '../../components/ErpTemplate';

export default function Principal() {
  return (
    <ErpTemplate>
      <div className="main-content container-fluid">
      </div>
    </ErpTemplate>
  );
}
