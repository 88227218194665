import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';

export default function CadastroSlaTipo({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState([]);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/slaTipo/' + id,
                token: token,
            }).then(response => {
                //console.log(response.data.data[0]);
                setDados(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {
        if (id) {//update
            api({
                method: 'put',
                action: '/slaTipo/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/slaTipo");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/slaTipo',
                token: token,
                data: { ...dados, ...{ empresa: empresa } }
            })
                .then(function (response) {
                    history.push("/slaTipo");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Tipo de SLA</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            {/*<div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigo" placeholder="Código"
                                                            value={dados.codigo}
                                                            onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Código</label>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}