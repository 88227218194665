import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaPlane, FaSave, FaUsers, FaUpload } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { bin2hex, data, dataMysql, extract } from '../../util/util';
import Data from '../../components/Data';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import NavItem from '../../components/NavItem';
import { QRCodeCanvas } from 'qrcode.react';
import { ChromePicker } from 'react-color';

export default function CadastroEventoPerguntaQrCode({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");

    const [dados, setDados] = useState({
        empresa: empresa,
        nomeEmpresa: nomeEmpresa
    });
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/evento/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'diretorresponsavel',
                        'cliente',
                        'empresa',
                        'municipio',
                        'pais',
                        'modelotermoaceite',
                        'modelocontrato',
                        'modeloemailconvite',
                        'modelocartaconvite',
                        'modelocarta',
                        'coordenadorrsvp',
                    ]
                });
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if id
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>QrCodes</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="floatingInput">Registrar nova pergunta</label>
                                            <br />
                                            <QRCodeCanvas value={"https://cliente.infoaccess-mkt.com.br/cadastroEventoPergunta/" + id} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="floatingInput">Moderação das perguntas</label>
                                            <br />
                                            <QRCodeCanvas value={"https://cliente.infoaccess-mkt.com.br/eventoPerguntaModeracao/" + id} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="floatingInput">Lista de Perguntas</label>
                                            <br />
                                            <QRCodeCanvas value={"https://cliente.infoaccess-mkt.com.br/eventoPerguntaAprovada/" + id} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate>
    );
}