import React, { useState } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSearch } from 'react-icons/fa';
import Data from '../../components/Data';
import ReactSelect from '../../components/ReactSelect';
import api from '../../services/api';

export default function RelatorioPagamentoOrcamento() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const [filtro, setFiltro] = useState({
        empresa: empresa,
    });

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Relatório de Pagamentos</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" method="post" target="_blank" action={process.env.REACT_APP_API_URL + "/orcamento/relatorioPagamento"}>
                                            <input type="hidden" name="token" value={token} />
                                            <input type="hidden" name="empresa" value={empresa} />
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="vencimento1"
                                                        label="Data Vencimento"
                                                        value={filtro.vencimento1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "vencimento1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="vencimento2"
                                                        label="até a Data Vencimento"
                                                        value={filtro.vencimento2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "vencimento2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            id="cliente"
                                                            name="cliente"
                                                            value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "cliente",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Situação</label>
                                                        <ReactSelect
                                                            name="situacao"
                                                            value={{ value: filtro.situacao, label: filtro.nomeSituacao }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "situacao": e.value, "nomeSituacao": e.label } })}
                                                            options={[
                                                                { value: '1', label: 'Aberto' },
                                                                { value: '2', label: 'Pago' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                </div>
                                            </div>
                                            */}
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
