import React, { useState } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaTable } from 'react-icons/fa';
import Data from '../../../components/Data';
import ReactSelect from '../../../components/ReactSelect';
import api from '../../../services/api';

export default function ExportarEventoExecucao() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const [filtro, setFiltro] = useState({
        empresa: empresa,
    });

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Gerar Planilha BI - Execução</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" method="post" target="_blank" action={process.env.REACT_APP_API_URL + "/evento/exportarEventoExecucao"}>
                                            <input type="hidden" name="token" value={token} />
                                            <input type="hidden" name="empresa" value={empresa} />
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="data1"
                                                        label="Data"
                                                        value={filtro.data1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="data2"
                                                        label="até a Data"
                                                        value={filtro.data2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaTable size={20} /> Gerar Planilha</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
