import React, { useState, useEffect } from "react";
import api from '../services/api';
import Chart from "react-apexcharts";

export default function CompromissosBarras({ label = "Média", ids = "" }) {
    const token = localStorage.getItem('token');
    const [slaGraficoBarras, setSlaGraficoBarras] = useState([]);
    const buscar = () => {
        api({
            method: 'get',
            action: '/slaCompromissoData/compromissosBarras',
            token: token,
            params: { ids: ids }
        })
            .then(response => {
                setSlaGraficoBarras(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => {
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    var chartBarras = {
        chart: {
            stacked: true,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '20%',
                colors: {
                    backgroundBarColors: ['#39539E']
                }
            },
        },
        stroke: {
            width: 0,
        },
        title: {
            floating: true,
            offsetX: -10,
            offsetY: 5,
            text: 'Process 1 - title'
        },
        subtitle: {
            floating: true,
            align: 'right',
            offsetY: 0,
            text: '44%',
            style: {
                fontSize: '20px'
            }
        },
        tooltip: {
            enabled: false
        },
        yaxis: {
            max: 100
        },
        fill: {
            opacity: 1
        }
    };

    return (
        <div className="card">
            <div className="card-body">
                {
                    Object.keys(slaGraficoBarras).map(function (key, index) {
                        return <Chart
                            title={slaGraficoBarras[key].titulo}
                            options={{ ...chartBarras, ...{ title: { text: slaGraficoBarras[key].titulo }, ...{ subtitle: { ...chartBarras.subtitle, ...{ text: slaGraficoBarras[key].media + "%" } } } } }}
                            series={[{
                                data: [slaGraficoBarras[key].media]
                            }]}
                            type="bar"
                            height={70}
                        />;
                    })
                }
            </div>
        </div>
    );
}