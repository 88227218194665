import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown, FaTrash } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMysql, bin2hex, buscarCep, extract, bin2base64, data } from '../../../util/util';
import SignatureCanvas from 'react-signature-canvas'
import NavItem from '../../../components/NavItem';
import { toast } from 'react-toastify';
import ReactSelect from '../../../components/ReactSelect';

export default function CadastroEventoParticipanteAssinatura({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const history = useHistory();
    let elementoAssinatura = {};
    const params = new URLSearchParams(window.location.search);
    const datalista = params.get('datalista');

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/participante/' + id,
                token: token,
                params: { datalista: datalista }
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'enderecopais',
                        'aereomunicipioembarque',
                        'enderecomunicipio',
                        'hotelmunicipio',
                        'banco',
                    ]
                });

                if (dadosRecebidos.base64assinatura) {
                    elementoAssinatura.fromDataURL(
                        "data:image/png;base64," + dadosRecebidos.base64assinatura,
                        { ratio: 1 }
                    );
                }

                dadosRecebidos.mostrarTermo = false; //nunca mostrar termo
                if (dadosRecebidos.evento.termoaceiteassinatura && dadosRecebidos.evento.termoaceiteassinatura !== '') { //se evento tiver termo, mostrar
                    dadosRecebidos.mostrarTermo = true;
                    if (dadosRecebidos.aceitetermoassinatura) { //se participante ja estiver respondido, não mostrar mais
                        dadosRecebidos.mostrarTermo = false;
                    }
                }

                if (datalista === undefined || datalista === null) {
                    dadosRecebidos.data = data(dadosRecebidos.evento.data);
                    dadosRecebidos.nomeData = data(dadosRecebidos.evento.data);
                } else {
                    dadosRecebidos.data = datalista;
                    dadosRecebidos.nomeData = datalista;
                }
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//id id
    };

    const salvar = () => {
        let dadosSalvar = {
            participante: dados.id,
            data: dados.data,
            imagemassinatura: dados.imagemassinatura,
            binarioImagemAssinatura: bin2hex(elementoAssinatura.getCanvas().toDataURL('image/png'))
        };

        api({
            method: 'post',
            action: '/participanteListaPresenca',
            token: token,
            data: dadosSalvar,
        })
            .then(function (response) {
                history.push("/eventoParticipante/" + dados.evento?.id);
            })
            .catch(function (error) {
                //alert(error)
            });
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['listarsvp'].includes(perfil) ? <NavItem to={"/eventoParticipante/" + dados.evento?.id} icon={<FaBackward size={20} />} color="outline-dark">Participantes</NavItem> : null}
                </ul>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        {dados.mostrarTermo ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-floating form-group">
                                                            <textarea
                                                                className="form-control form-control-lg"
                                                                id="termoaceiteassinatura"
                                                                placeholder="Termo de Aceite"
                                                                style={{ minHeight: "400px" }}
                                                                value={dados.evento.termoaceiteassinatura}
                                                                readOnly={true}
                                                            />
                                                            <label htmlFor="floatingInput">Termo de Aceite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                        <button type='button' className="btn btn-primary me-1 mb-1"
                                                            onClick={() => {
                                                                api({
                                                                    method: 'put',
                                                                    action: '/participante/' + id,
                                                                    token: token,
                                                                    data: {
                                                                        ...{ aceitetermoassinatura: "nao" }
                                                                    }
                                                                })
                                                                    .then(function (response) {
                                                                        toast.success('Aceite registrado com sucesso!');
                                                                        dados.aceitetermoassinatura = 'nao';
                                                                        dados.mostrarTermo = false;
                                                                        //setDados(dados); //atualizar state
                                                                        window.location.reload(false);
                                                                    })
                                                                    .catch(function (error) {
                                                                        //alert(error)
                                                                    });
                                                            }}>
                                                            <FaThumbsDown size={20} /> Recusar
                                                        </button>
                                                        <button type='button' className="btn btn-primary me-1 mb-1"
                                                            onClick={() => {
                                                                api({
                                                                    method: 'put',
                                                                    action: '/participante/' + id,
                                                                    token: token,
                                                                    data: {
                                                                        ...{ aceitetermoassinatura: "sim" }
                                                                    }
                                                                })
                                                                    .then(function (response) {
                                                                        toast.success('Aceite registrado com sucesso!');
                                                                        dados.aceitetermoassinatura = 'sim';
                                                                        dados.mostrarTermo = false;
                                                                        //setDados(dados); //atualizar state
                                                                        window.location.reload(false);
                                                                    })
                                                                    .catch(function (error) {
                                                                        //alert(error)
                                                                    });
                                                            }}>
                                                            <FaThumbsUp size={20} /> Aceitar
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-floating form-group">
                                                            <label htmlFor="floatingInput" style={{ display: "contents" }}>Assinatura {dados.nome}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {dados.evento ?
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Data</label>
                                                            <ReactSelect
                                                                value={{ value: dados.data, label: dados.nomeData }}
                                                                onChange={e => setDados({ ...dados, ...{ "data": e.value, "nomeData": e.label } })}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/evento/datas/' + dados.evento?.id,
                                                                        token: token,
                                                                        params: {
                                                                            nome: input,
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map(registro => ({
                                                                                value: registro,
                                                                                label: registro
                                                                            }));
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null}
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-floating form-group">
                                                            <SignatureCanvas
                                                                canvasProps={{
                                                                    width: 300, height: 300,
                                                                    className: 'sigCanvas'
                                                                }}
                                                                ref={(ref) => {
                                                                    elementoAssinatura = ref;
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                        <button type='button' className="btn btn-primary me-1 mb-1"
                                                            onClick={() => {
                                                                elementoAssinatura.clear();
                                                            }}>
                                                            <FaTrash size={20} /> Limpar
                                                        </button>
                                                        <button type="submit" className="btn btn-primary me-1 mb-1">
                                                            <FaSave size={20} /> Salvar</button>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate>
    );
}