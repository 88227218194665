import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave, FaTrash, FaPlus, FaUpload } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '../../components/IconButton';
import { toast } from 'react-toastify';
import { data, dataMysql, pontoVirgula } from '../../util/util';
import NavItem from '../../components/NavItem';
import Data from '../../components/Data';
import ReactInputMask from 'react-input-mask';

export default function CadastroEventoOrdemServico({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const [salas, setSalas] = useState([]);
    const [abs, setAbs] = useState([]);
    const history = useHistory();
    let { state } = useLocation();
    var evento = state?.evento ?? null;

    const buscar = () => {
        if (id) {
            try {
                api({
                    method: "get",
                    action: '/eventoOrdemServico/' + id,
                    token: token,
                }).then(response => {
                    const dadosRecebidos = response.data.data;

                    dadosRecebidos.data = data(dadosRecebidos.data);

                    setDados(dadosRecebidos);
                    setSalas(dadosRecebidos.salas ?? []);
                    setAbs(dadosRecebidos.abs ?? []);
                });
            } catch (err) {
                toast.error('Erro ao carregar dados: ' + (err.response?.data?.error ?? err.message));
            }
        }
    };

    const salvar = () => {
        let dadosSalvar = { ...dados };

        if (dadosSalvar.data) {
            dadosSalvar.data = dataMysql(dadosSalvar.data);
        }

        dadosSalvar.evento = evento;

        const metodo = id ? 'put' : 'post';
        const acao = id ? `/eventoOrdemServico/${id}` : '/eventoOrdemServico';

        api({
            method: metodo,
            action: acao,
            token: token,
            data: {
                dados: dadosSalvar,
                salas: salas,
                abs: abs
            }
        })
            .then(() => {
                toast.success('Ordem de Serviço salva com sucesso!');
                history.push("/eventoOrdemServicoDia/" + evento);
            })
            .catch((error) => {
                toast.error('Erro ao salvar Ordem de Serviço: ' + (error.response?.data?.error ?? error.message));
            });
    };

    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Ordens de Serviço do Evento</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { e.preventDefault(); salvar(); }}>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <h4>Quantidade de Convidados</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <Data
                                                        id="data"
                                                        label="Data"
                                                        value={dados.data}
                                                        onChange={date => setDados({ ...dados, ...{ "data": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="checkin" placeholder="Checkin"
                                                            value={dados.checkin}
                                                            onChange={e => setDados({ ...dados, ...{ "checkin": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Checkin</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50px"></th>
                                                                            <th>Salas</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {salas.map((sala, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <IconButton
                                                                                        title="Remover"
                                                                                        color="warning"
                                                                                        icon={<FaTrash size={20} />}
                                                                                        onClick={() => {
                                                                                            const novasSalas = [...salas];
                                                                                            novasSalas.splice(index, 1);
                                                                                            setSalas(novasSalas);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Título da Sala"
                                                                                                    value={sala.salaTitulo}
                                                                                                    onChange={(e) => {
                                                                                                        const novasSalas = [...salas];
                                                                                                        novasSalas[index].salaTitulo = e.target.value;
                                                                                                        setSalas(novasSalas);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Título da Sala</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Nome da Sala"
                                                                                                    value={sala.salaNome}
                                                                                                    onChange={(e) => {
                                                                                                        const novasSalas = [...salas];
                                                                                                        novasSalas[index].salaNome = e.target.value;
                                                                                                        setSalas(novasSalas);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Nome da Sala</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Layout da Sala"
                                                                                                    value={sala.salaLayout}
                                                                                                    onChange={(e) => {
                                                                                                        const novasSalas = [...salas];
                                                                                                        novasSalas[index].salaLayout = e.target.value;
                                                                                                        setSalas(novasSalas);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Layout</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Sala A/B"
                                                                                                    value={sala.salaAB}
                                                                                                    onChange={(e) => {
                                                                                                        const novasSalas = [...salas];
                                                                                                        novasSalas[index].salaAB = e.target.value;
                                                                                                        setSalas(novasSalas);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">A&B</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Equipamentos da Sala"
                                                                                                    value={sala.salaEquipamentos}
                                                                                                    onChange={(e) => {
                                                                                                        const novasSalas = [...salas];
                                                                                                        novasSalas[index].salaEquipamentos = e.target.value;
                                                                                                        setSalas(novasSalas);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Equipamentos</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    placeholder="Número de Pax"
                                                                                                    value={sala.salaCapacidade}
                                                                                                    onChange={(e) => {
                                                                                                        const novasSalas = [...salas];
                                                                                                        novasSalas[index].salaCapacidade = parseInt(e.target.value, 10);
                                                                                                        setSalas(novasSalas);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Número de Pax</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-floating form-group">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Observação da Sala"
                                                                                            value={sala.salaObservacao}
                                                                                            onChange={(e) => {
                                                                                                const novasSalas = [...salas];
                                                                                                novasSalas[index].salaObservacao = e.target.value;
                                                                                                setSalas(novasSalas);
                                                                                            }}
                                                                                        />
                                                                                        <label htmlFor="floatingInput">Observação</label>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            setSalas([...salas, {}]);
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Sala
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50px"></th>
                                                                            <th>A&B</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {abs.map((ab, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <IconButton
                                                                                        title="Remover"
                                                                                        color="warning"
                                                                                        icon={<FaTrash size={20} />}
                                                                                        onClick={() => {
                                                                                            const novasAbs = [...abs];
                                                                                            novasAbs.splice(index, 1);
                                                                                            setAbs(novasAbs);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>

                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Nome"
                                                                                                    value={ab.abNome}
                                                                                                    onChange={(e) => {
                                                                                                        const novasAbs = [...abs];
                                                                                                        novasAbs[index].abNome = e.target.value;
                                                                                                        setAbs(novasAbs);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Nome</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Local"
                                                                                                    value={ab.abLocal}
                                                                                                    onChange={(e) => {
                                                                                                        const novasAbs = [...abs];
                                                                                                        novasAbs[index].abLocal = e.target.value;
                                                                                                        setAbs(novasAbs);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Local</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <ReactInputMask mask="99:99"
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Horário"
                                                                                                    value={ab.abHorario}
                                                                                                    onChange={(e) => {
                                                                                                        const novasAbs = [...abs];
                                                                                                        novasAbs[index].abHorario = e.target.value;
                                                                                                        setAbs(novasAbs);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Horário</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Composição"
                                                                                                    value={ab.abComposicao}
                                                                                                    onChange={(e) => {
                                                                                                        const novasAbs = [...abs];
                                                                                                        novasAbs[index].abComposicao = e.target.value;
                                                                                                        setAbs(novasAbs);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Composição</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Valor"
                                                                                                    value={pontoVirgula(ab.abValor)}
                                                                                                    onChange={(e) => {
                                                                                                        const novasAbs = [...abs];
                                                                                                        novasAbs[index].abValor = e.target.value;
                                                                                                        setAbs(novasAbs);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    placeholder="Bebidas"
                                                                                                    value={ab.abBebidas}
                                                                                                    onChange={(e) => {
                                                                                                        const novasAbs = [...abs];
                                                                                                        novasAbs[index].abBebidas = e.target.value;
                                                                                                        setAbs(novasAbs);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Bebidas</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-floating form-group">
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    placeholder="Número de Pax"
                                                                                                    value={ab.abQuantidade}
                                                                                                    onChange={(e) => {
                                                                                                        const novasAbs = [...abs];
                                                                                                        novasAbs[index].abQuantidade = parseInt(e.target.value, 10);
                                                                                                        setAbs(novasAbs);
                                                                                                    }}
                                                                                                />
                                                                                                <label htmlFor="floatingInput">Número de Pax</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            setAbs([...abs, {}]);
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar A&B
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}