import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { extract } from '../../util/util';

export default function CadastroSlaCompromisso({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState([]);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/slaCompromisso/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: ['tiposla']
                });
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {
        if (id) {//update
            api({
                method: 'put',
                action: '/slaCompromisso/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/slaCompromisso");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/slaCompromisso',
                token: token,
                data: { ...dados, ...{ empresa: empresa } }
            })
                .then(function (response) {
                    history.push("/slaCompromisso");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Compromisso</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <ReactSelect
                                                            value={{ value: dados.tiposla, label: dados.nomeTiposla }}
                                                            onChange={e => setDados({ ...dados, ...{ "tiposla": e.value, "nomeTiposla": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/slaTipo',
                                                                    token: token
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Contagem</label>
                                                        <ReactSelect
                                                            value={{ value: dados.contagemData, label: dados.nomeContagemData }}
                                                            onChange={e => setDados({ ...dados, ...{ "contagemData": e.value, "nomeContagemData": e.label } })}
                                                            options={[
                                                                { value: 'uteis', label: 'Dias úteis' },
                                                                { value: 'corridos', label: 'Dias corridos' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="prazoMinimo" placeholder="Prazo Mínimo"
                                                            value={dados.prazoMinimo}
                                                            onChange={e => setDados({ ...dados, ...{ "prazoMinimo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Prazo mínimo (dias)</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="prazoMaximo" placeholder="Prazo Máximo"
                                                            value={dados.prazoMaximo}
                                                            onChange={e => setDados({ ...dados, ...{ "prazoMaximo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Prazo máximo (dias)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo de Data do Evento</label>
                                                        <ReactSelect
                                                            value={{ value: dados.tipoDataEvento, label: dados.nomeTipoDataEvento }}
                                                            onChange={e => setDados({ ...dados, ...{ "tipoDataEvento": e.value, "nomeTipoDataEvento": e.label } })}
                                                            options={[
                                                                { value: 'inicio', label: 'Data de início' },
                                                                { value: 'fim', label: 'Data de término' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Controle Interno</label>
                                                        <ReactSelect
                                                            value={{ value: dados.controleInterno, label: dados.nomeControleInterno }}
                                                            onChange={e => setDados({ ...dados, ...{ "controleInterno": e.value, "nomeControleInterno": e.label } })}
                                                            options={[
                                                                { value: 'nao', label: 'Não' },
                                                                { value: 'sim', label: 'Sim' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}