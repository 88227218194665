import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaPen, FaFileInvoice, FaPlus, FaSearch, FaThumbsUp, FaUpload, FaList } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import NavItem from '../../../components/NavItem';
import IconButton from '../../../components/IconButton';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import Alert from '../../../components/Alert';
import ReactSelect from '../../../components/ReactSelect';
import FormButton from '../../../components/FormButton';

export default function PainelEventoCarta({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [eventoCartas, setEventoCartas] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        api({
            method: 'get',
            action: '/eventoCarta',
            token: token,
            params: {
                ...filtro,
                ...{
                    orderBy: {
                        tipo: "asc"
                    }
                }
            }
        })
            .then(response => {
                setEventoCartas(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
                (tableSelect.selectedRows.length === 1) && //apenas 1 selecionado
                    (['diretor', 'rsvp'].includes(perfil)) ? //apenas gerente financeiro 
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "error",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                title: "Excluir",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'delete',
                                            action: '/participante/' + tableSelect.selectedRows[0].id,
                                            token: token
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Excluir</IconButton > : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'rsvp'].includes(perfil)) ?
                    <FormButton
                        small={true}
                        method="post"
                        action={process.env.REACT_APP_API_URL + "/eventoCarta/visualizarExemplo"}
                        icon={<FaFileInvoice size={20} />}
                        color="primary"
                        data={{
                            token: localStorage.getItem('token'),
                            idCarta: tableSelect.selectedRows[0].id
                        }}
                    >Visualizar</FormButton> : null,
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/cadastroEventoCarta" icon={<FaPlus size={20} />} color="primary">Nova Carta</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Modelos de Cartas</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            id="cliente"
                                                            value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "cliente",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows="true"
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        if (row.bancoDados !== undefined) {
                                                            return <IconButton
                                                                small={true}
                                                                to={{
                                                                    pathname: "/cadastroEventoCarta/" + row.id,
                                                                    state: {
                                                                        evento: id
                                                                    }
                                                                }}
                                                                icon={<FaPen size={20} />}
                                                                color="primary">Alterar</IconButton>;
                                                        }
                                                    },
                                                    width: "120px",
                                                },
                                                {
                                                    name: 'Código',
                                                    selector: row => row.codigo ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Tipo',
                                                    selector: row => row.tipo ?? "",
                                                    sortable: true,
                                                    width: "120px",
                                                    cell: (row) => {
                                                        var nomeTipo = '';
                                                        if (row.tipo === undefined || row.tipo === null) {
                                                            return "";
                                                        } else {
                                                            if (row.tipo === 'emailconvite')
                                                                nomeTipo = "E-mail Convite";
                                                            if (row.tipo === 'contrato')
                                                                nomeTipo = "Contrato";
                                                            if (row.tipo === 'convite')
                                                                nomeTipo = "Convite";
                                                            if (row.tipo === 'informativa')
                                                                nomeTipo = "Informativa";
                                                            if (row.tipo === 'termoaceite')
                                                                nomeTipo = "Termo de Aceite";

                                                            return nomeTipo;
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Nome',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Tipo de Evento',
                                                    selector: row => row.eventoTipo ?? "",
                                                    sortable: true,
                                                    width: "140px",
                                                },
                                                {
                                                    name: 'Formato',
                                                    selector: row => row.eventoTipoRealizacao ?? "",
                                                    sortable: true,
                                                    width: "120px",
                                                },
                                                {
                                                    name: 'Nacionalidade',
                                                    selector: row => row.eventoNacionalidade ?? "",
                                                    sortable: true,
                                                    width: "130px",
                                                },
                                                {
                                                    name: 'Cliente',
                                                    selector: row => row.cliente?.nome ?? "",
                                                    sortable: true,
                                                    width: "200px",
                                                    cell: (row) => {
                                                        if (row.cliente) {
                                                            if (row.cliente?.enderecomunicipio) { //tem municipio
                                                                return row.cliente.codigo + ' - ' + row.cliente.nome + ' - ' + row.cliente.enderecomunicipio.nome + ' - ' + row.cliente.enderecomunicipio.uf;
                                                            } else { //nao tem municipio
                                                                return row.cliente.codigo + ' - ' + row.cliente.nome;
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                            ]}
                                            data={eventoCartas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
