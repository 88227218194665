import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export default function Alert({
    html = null,
    denyButton = null,
    confirmButton = null,
    cancelButton = null,
    close = true,
    title = null,
    icon = null,
    callBack = null,
}) {
    var options = {
        showCloseButton: { close },
        focusConfirm: false,
    };

    //options.onConfirm = () => alert('olaaaa');

    if (title) {
        options.title = title;
    }
    if (icon) {
        options.icon = icon;
    }
    if (html) {
        options.html = html;
    }
    if (confirmButton) {
        options.confirmButtonText = confirmButton;
    }
    if (cancelButton) {
        options.showCancelButton = true;
        options.cancelButtonText = cancelButton;
    }
    if (denyButton) {
        options.showDenyButton = true;
        options.denyButtonText = denyButton;
    }
    MySwal.fire(options)
        .then((result) => {
            if (callBack) {
                callBack(result);
            }
        });
    /*
    var options = {
        showCloseButton: { close },
        focusConfirm: false,
    };
    Swal.fire(options);
    */
}