import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaList, FaPen, FaPlus, FaSearch } from 'react-icons/fa';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import DataTable from 'react-data-table-component';

export default function PainelProdutoBu() {
    const token = localStorage.getItem('token');
    const [filtro, setFiltro] = useState([]);
    const [produtoBus, setProdutoBus] = useState([]);

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        api({
            method: 'get',
            action: '/produtoBu',
            token: token,
            params: filtro
        })
            .then(response => {
                setProdutoBus(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/cadastroProdutoBu" icon={<FaPlus size={20} />} color="primary">Novo Bu</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Bu's</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome do serviço"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: row => <IconButton
                                                        small={true}
                                                        to={"/cadastroProdutoBu/" + row.id}
                                                        icon={<FaPen size={20} />}
                                                        color="primary">Alterar</IconButton>,
                                                    width: "120px",
                                                },
                                                {
                                                    name: 'Código',
                                                    selector: row => row.codigo ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Nome',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Unidade de Negócio',
                                                    selector: row => row.unidadenegocio?.nome ?? "",
                                                    sortable: true,
                                                },
                                            ]}
                                            data={produtoBus}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
