import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import logo from '../../images/logo.svg';
import api from '../../services/api';
import './styles.css';
import { toast } from 'react-toastify';

export default function Registro() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const history = useHistory();

  async function handleRegistro(e) {
    e.preventDefault();

    const data = {
      "nome": nome,
      "email": email,
      "senha": senha,
    };

    try {
      await api({
        method: 'post',
        action: '/usuario',
        data: data
      })
        .then(async (res) => {
          if (res.data.status) {
            const responseLogin = await api({
              method: 'post',
              action: '/login',
              data: { email, senha }
            });
            localStorage.setItem('idUsuario', responseLogin.data.id);
            localStorage.setItem('nomeUsuario', responseLogin.data.nome);
            localStorage.setItem('token', responseLogin.data.api_token);
            history.push('/principal');
          }
        });
    } catch (err) {
      toast.error('Erro no cadastro, tente novamente');
    }
  }

  return (
    <div id="auth">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card pt-4">
              <div className="card-body">
                <div className="text-center mb-5">
                  <img alt="" src={logo} height="48" className='mb-4' />
                  <p>Informe seus dados para cadastro</p>
                </div>
                <form onSubmit={handleRegistro}>
                  <div className="form-group position-relative has-icon-left">
                    <label htmlFor="nome">Nome</label>
                    <div className="position-relative">
                      <input type="text" className="form-control" id="nome"
                        value={nome}
                        onChange={e => setNome(e.target.value)} />
                      <div className="form-control-icon">
                        <FaUser />
                      </div>
                    </div>
                  </div>
                  <div className="form-group position-relative has-icon-left">
                    <label htmlFor="email">E-mail</label>
                    <div className="position-relative">
                      <input type="text" className="form-control" id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)} />
                      <div className="form-control-icon">
                        <FaEnvelope />
                      </div>
                    </div>
                  </div>
                  <div className="form-group position-relative has-icon-left">
                    <div className="clearfix">
                      <label htmlFor="senha">Senha</label>
                    </div>
                    <div className="position-relative">
                      <input type="password" className="form-control" id="senha"
                        value={senha}
                        onChange={e => setSenha(e.target.value)} />
                      <div className="form-control-icon">
                        <FaLock />
                      </div>
                    </div>
                  </div>
                  <div className='form-check clearfix my-4'>
                    <div className="float-end">
                      <Link to="/login">
                        <FiArrowLeft />Já possuo cadastro
                        </Link>
                    </div>
                  </div>
                  <div className="clearfix">
                    <button className="btn btn-primary float-end" type="submit">Cadastrar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}