import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaUsers, FaSave, FaThumbsUp } from 'react-icons/fa';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '../../../components/IconButton';
import { ImCancelCircle } from 'react-icons/im';
import Alert from '../../../components/Alert';

export default function EventoVincularParticipanteOperador({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const history = useHistory();
    const [participantes, setParticipantes] = useState([]);
    const [operadores, setOperadores] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const [operadoresMarcados, setOperadoresMarcados] = useState([]);
    let { state } = useLocation();
    var nomeEvento = state?.nomeEvento ?? null;

    const buscar = () => {
        //busca lista de participantes do evento por status
        api({
            method: 'get',
            action: '/evento/participantesPorStatus/' + id,
            token: token,
        })
            .then(response => {
                setParticipantes(response.data.data);
            }).catch(err => {
                //console.log(err)
            });

        //busca lista de operadores ativos
        api({
            method: 'get',
            action: '/participante',
            token: token,
            params: {
                ...{
                    tipoCadastro: "pessoa",
                    situacaoRegistro: "ativo",
                    eventoOperador: id,
                    quantidadeparticipantesvinculados: true,
                    perfil: [9, 8],
                    orderBy: {
                        cargo: 'asc'
                    }
                }
            }
        })
            .then(response => {
                setOperadores(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    const salvar = () => {
        api({
            method: 'put',
            action: '/evento/vincularParticipantesOperadores/' + id,
            token: token,
            data: {
                participantes: participantes,
                operadores: operadoresMarcados
            }
        })
            .then(function (response) {
                history.push("/eventoParticipante/" + id);
                //history.push("/evento?idEvento=" + id);
            })
            .catch(function (error) {
                //alert(error)
            });
    };//salvar

    useEffect(() => { //onload
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";

        var lista = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }) : [];
        setOperadoresMarcados([...lista]);

        if (tableSelect.selectedCount > 0) {
            setTableActions([]);
        } else {
            setTableActions([]);
        }
    }, 
    // eslint-disable-next-line
    [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Evento: {nomeEvento}</h3>
                    <h3>Vincular Participantes aos Operadores</h3>
                </div>
                <form className="form" onSubmit={(e) => {
                    salvar();
                    e.preventDefault();
                }}>
                    <section id="multiple-column-form">
                        <div className="row match-height">
                            {participantes.map(function (participante, index) {
                                participante.marcado = participante.marcado ?? "nao";
                                return <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-check">
                                                            <div className="checkbox">
                                                                <label>
                                                                    <input type="checkbox"
                                                                        className="form-check-input"
                                                                        value={participante.marcado}
                                                                        checked={participante.marcado === "sim" ? true : false}
                                                                        onChange={(value) => {
                                                                            participantes[index].marcado = participantes[index].marcado === "nao" ? "sim" : "nao";
                                                                            setParticipantes([...participantes]);
                                                                        }} />{participante.nomeSituacao}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 d-flex justify-content-end">
                                                        {participante.quantidadeTotal} <div style={{ marginLeft: "10px" }}><FaUsers size={20} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>;
                            })}
                        </div>
                    </section>
                    <section className="section">
                        <div className="row mb-4">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body px-0 pb-0">
                                        <h6 className="c-grey-900 card-title">Operadores</h6>
                                        <div className="table-responsive">
                                            <DataTable
                                                noContextMenu="true"
                                                actions={tableActions}
                                                selectableRows="true"
                                                selectableRowsHighlight="true"
                                                onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                    setTableSelect({ allSelected, selectedCount, selectedRows });
                                                }}
                                                noDataComponent="Nenhum registro"
                                                paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                                pagination="true"
                                                className="table mb-0"
                                                columns={[
                                                    {
                                                        name: 'Código',
                                                        selector: row => row.codigo ?? "",
                                                        sortable: true,
                                                        width: "100px",
                                                        right: true,
                                                    },
                                                    {
                                                        name: 'Cargo',
                                                        selector: row => row.cargo.nome ?? "",
                                                        sortable: true,
                                                        width: "150px",
                                                        right: true,
                                                    },
                                                    {
                                                        name: 'Nome',
                                                        selector: row => row.nome ?? "",
                                                        sortable: true,
                                                    },
                                                    {
                                                        name: 'Vinculados de outros Eventos',
                                                        selector: row => row.quantidadeoutroseventosparticipanteoperador ?? "",
                                                        sortable: true,
                                                        right: true,
                                                    },
                                                    {
                                                        name: 'Vinculados deste Evento',
                                                        selector: row => row.quantidadedesteeventoparticipanteoperador ?? "",
                                                        sortable: true,
                                                        right: true,
                                                    },
                                                    {
                                                        name: '',
                                                        selector: row => row.id,
                                                        right: true,
                                                        width: "120px",
                                                        cell: (row) => {
                                                            return row.quantidadedesteeventoparticipanteoperador > 0 ? <IconButton
                                                                small={true}
                                                                title="Excluir os participantes DESTE EVENTO vinculados ao operador"
                                                                icon={<ImCancelCircle size={20} />}
                                                                onClick={() => {
                                                                    Alert({
                                                                        icon: "error",
                                                                        confirmButton: "Sair",
                                                                        denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                                                        title: "Excluir",
                                                                        callBack: (result) => {
                                                                            if (result.isDenied) {
                                                                                api({
                                                                                    method: 'delete',
                                                                                    action: '/evento/desvincularParticipantesOperadores/' + id + '/' + row.id,
                                                                                    token: token
                                                                                })
                                                                                    .then(function (response) {
                                                                                        buscar();
                                                                                        //alert("Evento cancelado com sucesso!");
                                                                                    })
                                                                                    .catch(function (error) {
                                                                                        //alert(error)
                                                                                    });
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                                }
                                                                color="primary"> Excluir</IconButton> : null;
                                                        },
                                                    },
                                                ]}
                                                data={operadores}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="multiple-column-form">
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary me-1 mb-1">
                                    <FaSave size={20} /> Vincular</button>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </ErpTemplate>
    );
}