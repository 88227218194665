import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { bin2hex, data, dataMysql, extract, pontoVirgula } from '../../../util/util';
import { toast } from 'react-toastify';
import ItemChecklist from '../../../components/ItemChecklist';

export default function CadastroEventoChecklist({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [reload, setReload] = useState(1);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/eventoChecklist/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = response.data.data;
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if id
        else {
            setDados({ ...dados });
        }
    };

    const validar = () => {
        /*
        var tipo = document.querySelector("#tipo");
        if (dados.tipo === undefined || dados.tipo === '') {
            toast.error('É necessário preencher o tipo da carta');
            setTimeout(() => {
                tipo.focus();
            }, 500);
            return false;
        }
        */
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }

        //update
        api({
            method: 'put',
            action: '/eventoChecklist/' + id,
            token: token,
            data: {
                ...dados,
            }
        })
            .then(function (response) {
                //console.log(response.data);
                //history.push("/evento");
                toast.success("As alterações foram salvas com sucesso!");
            })
            .catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            });
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Checklist - {dados.nome} - {data(dados.data)}</h3>
                    <h3>{dados.cliente?.nome ?? ""}</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Atividades</label>
                                                        {(dados.itens?.length ?? 0) > 0 ?
                                                            <div>
                                                                <ItemChecklist dados={dados} stateChanger={setReload} />
                                                            </div> : <>
                                                                <h3>Selecione o modelo de checklist para este evento</h3>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <ReactSelect
                                                                            value={{ value: dados.modelochecklist ?? "", label: dados.nomeModelochecklist ?? "" }}
                                                                            onChange={(e) => {
                                                                                dados.modelochecklist = e.value;
                                                                                dados.nomeModelochecklist = e.label;
                                                                                setDados({ ...dados });
                                                                            }}
                                                                            loadOptions={(input) => {
                                                                                return api({
                                                                                    method: 'get',
                                                                                    action: '/eventoChecklistModelo',
                                                                                    token: token,
                                                                                    params: { nome: input }
                                                                                })
                                                                                    .then(response => {
                                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + "-" + registro.nome }));
                                                                                        return options;
                                                                                    })
                                                                                    .catch(function (error) {
                                                                                        //console.log(error);
                                                                                    });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ marginTop: "10px" }}>
                                                                    <div className="col-12 d-flex justify-content-end">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary me-1 mb-1"
                                                                            onClick={() => {
                                                                                if (dados.modelochecklist === undefined || dados.modelochecklist === null) {//nao tem modelo
                                                                                    toast.error('É necessário selecionar o modelo de checklist');
                                                                                } else {//tem modelo
                                                                                    //console.log(dados);
                                                                                    api({
                                                                                        method: 'post',
                                                                                        action: '/eventoChecklist',
                                                                                        token: token,
                                                                                        data: {
                                                                                            ...dados,
                                                                                            ...{ idEvento: id },
                                                                                        }
                                                                                    })
                                                                                        .then(response => {
                                                                                            window.location.reload();
                                                                                        })
                                                                                        .catch(function (error) {
                                                                                            //console.log(error);
                                                                                        });
                                                                                }//tem modelo
                                                                            }}>
                                                                            <FaSave size={20} /> Iniciar
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {(dados.itens?.length ?? 0) > 0 ?
                                                <div className="row">
                                                    <div className="col-12 d-flex justify-content-end">
                                                        <button type="submit" className="btn btn-primary me-1 mb-1">
                                                            <FaSave size={20} /> Salvar</button>
                                                    </div>
                                                </div> : null}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}