import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { extract } from '../../util/util';
import api from '../../services/api';
import logo from '../../images/logo.svg';
import '../Login/styles.css';
import { toast } from 'react-toastify';

export default function LoginConfirmarDados({ match }) {
  const id = match.params.id;
  const [dados, setDados] = useState({});
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const contrato = params.get('contrato');

  const buscar = () => {
    api({
      method: "get",
      action: '/dadosParticipante/' + id,
    }).then(response => {
      var dadosRecebidos = response.data.data;
      if (dadosRecebidos.evento.logomarca?.link) {//existe logomarca no evento
        localStorage.setItem('logomarcaEvento', dadosRecebidos.evento.logomarca.link);
      } else if (dadosRecebidos.evento.cliente?.logomarca?.link) { //existe logomarca no cliente do evento
        localStorage.setItem('logomarcaEvento', dadosRecebidos.evento.cliente.logomarca.link);
      }

      //se estiver em alguma situação do grupo confirmado, mostrar concluído
      var gruposSituacao = dadosRecebidos.situacaoevento?.grupo ?? [];
      if (gruposSituacao.includes("confirmados") || gruposSituacao.includes("declinaram")) {
        history.push({
          pathname: "/cadastroEventoParticipanteConviteConcluido/" + dadosRecebidos.evento.id,
        });
      } else {
        setDados(dadosRecebidos);
      }
    }).catch(err => {
      //console.log(err)
    });
  };//buscar

  async function salvar(e) {
    e.preventDefault();
    try {
      const response = await api({
        method: 'post',
        action: '/loginRapido/' + id,
      });
      var dadosRecebidos = response.data;

      localStorage.setItem('perfil', "cliente");
      localStorage.setItem('idUsuario', dadosRecebidos.id);
      localStorage.setItem('nomeUsuario', dadosRecebidos.nome);
      localStorage.setItem('token', dadosRecebidos.api_token);

      if ((dados.datacartainformativa ?? "") !== "") { //ainda não foi enviada carta informativa
        history.push("/cadastroEventoParticipanteCartaInformativa/" + id);
      } else { //já foi enviada carta informativa
        //se tiver modelo de carta convite, redirecionar para aceitar convite, senão para cadastro de dados
        if ((dados.evento?.modelocartaconvite ?? "") !== "") {
          history.push("/cadastroEventoParticipanteAceitarConvite/" + id + (contrato ? "?contrato=true" : ""));
        } else if ((dados.evento?.modelotermoaceite ?? "") !== "") { //se tiver termo de aceite
          history.push("/cadastroEventoParticipanteAceitarTermo/" + id); //mostrar termo
        } else { //caso nao tenha termo de aceite
          history.push("/cadastroEventoParticipanteConvite/" + id); //vai direto para cadastro de dados
        }
      }
    } catch (error) {
      toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
    }
  } //salvar

  useEffect(() => { //onload
    buscar();
  },
    // eslint-disable-next-line
    []);

  return (
    <div id="auth" style={
      (dados.evento?.cor !== undefined) ? {
        backgroundImage: "none",
        backgroundColor: dados.evento?.cor,
      } : {
        backgroundImage: "none",
        backgroundColor: "#AAAAAA",
      }
    }>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card pt-4">
              <div className="card-body">
                <div className="text-center mb-5">
                  {localStorage.getItem('logomarcaEvento') ?
                    <img alt="" src={localStorage.getItem('logomarcaEvento')} height="48" className='mb-4' /> :
                    <img alt="" src={logo} height="48" className='mb-4' />
                  }
                  <p>Bem vindo ao evento <strong>{(dados.evento?.nomersvp ?? dados.evento?.nome ?? "")}</strong>!</p>
                </div>
                <form onSubmit={salvar}>
                  <div className="clearfix">
                    <center>
                      <button className="btn btn-primary" type="submit">Iniciar Cadastro</button>
                    </center>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
