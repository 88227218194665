import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaCalendarAlt, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { data, dataMysql, extract } from '../../util/util';

export default function CadastroSla({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({ modelo: "sim" });
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/sla/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: ['tipo']
                });
                //transforma data para javascript
                dadosRecebidos.data = data(dadosRecebidos.data);
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        } else {
            dados.slacompromissodata = [];
        }
    };

    const salvar = () => {
        //if (id && !copiar) {//update
        if (id) {//update
            api({
                method: 'put',
                action: '/sla/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/slaModelo");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/sla',
                token: token,
                data: { ...dados, ...{ empresa: empresa } }
            })
                .then(function (response) {
                    history.push("/slaModelo");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },     
    // eslint-disable-next-line
    []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Modelo de SLA</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigo" placeholder="codigo"
                                                            value={dados.codigo}
                                                            onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Código</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Formato</label>
                                                        <ReactSelect
                                                            value={{ value: dados.tipoRealizacao, label: dados.nomeTipoRealizacao }}
                                                            onChange={e => setDados({ ...dados, ...{ "tipoRealizacao": e.value, "nomeTipoRealizacao": e.label } })}
                                                            options={[
                                                                { value: 'digital', label: 'Digital' },
                                                                { value: 'presencial', label: 'Presencial' },
                                                                { value: 'hibrido', label: 'Híbrido' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <ReactSelect
                                                            value={{ value: dados.tipo, label: dados.nomeTipo }}
                                                            onChange={e => setDados({ ...dados, ...{ "tipo": e.value, "nomeTipo": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/slaTipo',
                                                                    token: token
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Complexidade</label>
                                                        <ReactSelect
                                                            value={{ value: dados.complexidade, label: dados.nomeComplexidade }}
                                                            onChange={e => setDados({ ...dados, ...{ "complexidade": e.value, "nomeComplexidade": e.label } })}
                                                            options={[
                                                                { value: 'baixa', label: 'Baixa' },
                                                                { value: 'media', label: 'Média' },
                                                                { value: 'alta', label: 'Alta' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Diretriz</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <td width="100px"></td>
                                                                            <th>Compromisso</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dados.slacompromissodata ? dados.slacompromissodata.map((row, rowIndex) => {
                                                                            return [
                                                                                <tr>
                                                                                    <td>
                                                                                        <button type="button" className="btn btn-warning me-1 mb-1"
                                                                                            onClick={() => {
                                                                                                dados.slacompromissodata.splice(rowIndex, 1);
                                                                                                setDados({ ...dados });
                                                                                            }}>
                                                                                            <FaTrash size={20} /></button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <ReactSelect
                                                                                            value={{ value: row.compromisso.id, label: row.compromisso.nome }}
                                                                                            onChange={(e) => {
                                                                                                dados.slacompromissodata[rowIndex].compromisso.id = e.value;
                                                                                                dados.slacompromissodata[rowIndex].compromisso.nome = e.label;
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                            defaultOptions
                                                                                            loadOptions={(input) => {
                                                                                                return api({
                                                                                                    method: 'get',
                                                                                                    action: '/slaCompromisso',
                                                                                                    token: token,
                                                                                                    params: {
                                                                                                        nome: input,
                                                                                                        tiposla: dados.tipo
                                                                                                    }
                                                                                                })
                                                                                                    .then(response => {
                                                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                                        return options;
                                                                                                    })
                                                                                                    .catch(function (error) {
                                                                                                        //console.log(error);
                                                                                                    });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ];
                                                                        }) : null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            dados.slacompromissodata.push({ data: null, compromisso: { id: null, nome: null } });
                                                            setDados({ ...dados });
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Compromisso</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}