import React from 'react';

function MostrarHTML({ htmlContent }) {
    const sanitizedHTML = htmlContent;

    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    );
}

export default MostrarHTML;
