import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';

export default function CadastroEventoResponsavelCliente({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState([]);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/eventoResponsavelCliente/' + id,
                token: token,
            }).then(response => {
                var dadosRecebidos = response.data.data;

                //transforma dados no formato aceito pelo reactselect
                if (dadosRecebidos.cliente?.constructor !== Array)
                    dadosRecebidos.cliente = [];
                dadosRecebidos.cliente = dadosRecebidos.cliente.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.unidadenegocio?.constructor !== Array)
                    dadosRecebidos.unidadenegocio = [];
                dadosRecebidos.unidadenegocio = dadosRecebidos.unidadenegocio.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.bu?.constructor !== Array)
                    dadosRecebidos.bu = [];
                dadosRecebidos.bu = dadosRecebidos.bu.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {

        if (dados.cliente && Array.isArray(dados.cliente)) {
            dados.cliente = dados.cliente.map(item => item.value).join(',');
        }

        if (dados.unidadenegocio?.constructor === Array) {
            dados.unidadenegocio = dados.unidadenegocio.map(item => item.value).join(',');
        }

        if (dados.bu?.constructor === Array) {
            dados.bu = dados.bu.map(item => item.value).join(',');
        }

        if (id) {//update
            api({
                method: 'put',
                action: '/eventoResponsavelCliente/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/eventoResponsavelCliente");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/eventoResponsavelCliente',
                token: token,
                data: { ...dados, ...{ empresa: empresa } }
            })
                .then(function (response) {
                    history.push("/eventoResponsavelCliente");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Responsável Cliente</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            isMulti={true}
                                                            value={dados.cliente}
                                                            onChange={(e) => {
                                                                dados.cliente = dados.cliente || [];
                                                                dados.cliente = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "cliente",
                                                                        situacaoRegistro: "ativo"
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Unidade de Negócios</label>
                                                        <ReactSelect
                                                            isMulti={true}
                                                            value={dados.unidadenegocio ?? []}
                                                            onChange={(e) => {
                                                                dados.unidadenegocio = dados.unidadenegocio || [];
                                                                dados.unidadenegocio = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produtoUnidadeNegocio',
                                                                    token: token,
                                                                    params: { nome: input }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>BU</label>
                                                        <ReactSelect
                                                            isMulti={true}
                                                            value={dados.bu ?? []}
                                                            onChange={(e) => {
                                                                dados.bu = dados.bu || [];
                                                                dados.bu = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produtoBu',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="email" placeholder="Usuário"
                                                            value={dados.email}
                                                            onChange={e => setDados({ ...dados, ...{ "email": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Usuário</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="password" className="form-control form-control-lg" id="senha" placeholder="Senha"
                                                            value={dados.senha}
                                                            onChange={e => setDados({ ...dados, ...{ "senha": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Senha</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}