import React, { useState, useEffect } from "react";
import api from '../services/api';
import Chart from "react-apexcharts";

export default function MediaExecucaoRadial({ label = "Média", ids = "" }) {
    const token = localStorage.getItem('token');
    const [media, setMedia] = useState(0);
    const buscar = () => {
        api({
            method: 'get',
            action: '/slaCompromissoData/mediaExecucao',
            token: token,
            params: { ids: ids }
        })
            .then(response => {
                //                console.log(response.data.data);
                setMedia(response.data.data.media);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => {
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    var chart = {
        //series: [76, 67, 61, 30],
        //series: Object.keys(slaGrafico1).map(function (key, index) {
        //    return Math.round(slaGrafico1[key].calculado);
        //}),
        series: [media],
        options: {
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '30%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    }
                },
            },
            colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
            //labels: ['Vimeo', 'Messenger', 'Facebook', 'LinkedIn'],
            //labels: Object.keys(slaGrafico1).map(function (key, index) {
            //    return slaGrafico1[key].titulo;
            //}),
            labels: ["Média"],
            legend: {
                show: true,
                floating: true,
                fontSize: '16px',
                position: 'left',
                offsetX: 10,
                offsetY: 15,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%";
                },
                itemMargin: {
                    vertical: 3
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }]
        },
    };//chart

    return (
        <div className="card">
            <div className="card-body">
                <Chart
                    options={chart.options}
                    series={chart.series}
                    type="radialBar"
                    height={400}
                />
            </div>
        </div>
    );
}