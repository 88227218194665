import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { bin2hex, data, dataMysql, extract, pontoVirgula } from '../../util/util';
import Data from '../../components/Data';
import { toast } from 'react-toastify';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import InputMask from 'react-input-mask';

export default function CadastroEvento({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    //dataCompetencia: new Date(),
    const [dados, setDados] = useState({
        responsavel: [idUsuario],
        nomeResponsavel: nomeUsuario,
        empresa: empresa,
        nomeEmpresa: nomeEmpresa
    });
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/evento/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'diretorresponsavel',
                        'cliente',
                        'empresa',
                        'municipio',
                        'pais',
                        'modelotermoaceite',
                        'modeloemailconvite',
                        'modelocontrato',
                        'modelocartaconvite',
                        'modelocarta',
                        'coordenadorrsvp',
                    ]
                });
                //transforma data para javascript
                dadosRecebidos.data = data(dadosRecebidos.data);
                dadosRecebidos.dataFim = data(dadosRecebidos.dataFim);
                dadosRecebidos.dataLimiteAceite = data(dadosRecebidos.dataLimiteAceite);
                dadosRecebidos.dataCompetencia = data(dadosRecebidos.dataCompetencia);
                dadosRecebidos.replanejadoInicio = data(dadosRecebidos.replanejadoInicio);
                dadosRecebidos.replanejadoFim = data(dadosRecebidos.replanejadoFim);
                dadosRecebidos.previsaoFaturamento = data(dadosRecebidos.previsaoFaturamento);

                if (dadosRecebidos.responsavel?.constructor !== Array)
                    dadosRecebidos.responsavel = [];
                dadosRecebidos.responsavel = dadosRecebidos.responsavel.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.responsavelcliente?.constructor !== Array)
                    dadosRecebidos.responsavelcliente = [];
                dadosRecebidos.responsavelcliente = dadosRecebidos.responsavelcliente.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if id
        else {
            setDados({
                ...dados,
                ...{
                    pais: '30',
                    nomePais: 'Brasil'
                }
            });
        }
    };

    const validar = () => {
        var complexidade = document.querySelector("#complexidade");
        if (dados.complexidade === undefined || dados.complexidade === '') {
            toast.error('É necessário preencher a complexidade');
            setTimeout(() => {
                complexidade.focus();
            }, 500);
            setTabState("geral");
            return false;
        }
        var nacionalidade = document.querySelector("#nacionalidade");
        if (dados.nacionalidade === undefined || dados.nacionalidade === '') {
            toast.error('É necessário preencher a nacionalidade');
            setTimeout(() => {
                nacionalidade.focus();
            }, 500);
            setTabState("geral");
            return false;
        }
        var nome = document.querySelector("#nome");
        if (nome.value === '') {
            toast.error('É necessário preencher o Nome do evento');
            setTimeout(() => {
                nome.focus();
            }, 500);
            setTabState("geral");
            return false;
        }
        var cliente = document.querySelector("#cliente");
        if (dados.cliente === undefined || dados.cliente === '') {
            toast.error('É necessário preencher o Cliente');
            setTimeout(() => {
                cliente.focus();
            }, 500);
            setTabState("geral");
            return false;
        }

        var data = document.querySelector("#data");
        if (data.value === '') {
            toast.error('É necessário preencher a Data de Início');
            setTimeout(() => {
                data.focus();
            }, 500);
            setTabState("datas");
            return false;
        }

        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }
        if (dados.data) {
            dados.data = dataMysql(dados.data);
        }
        if (dados.dataFim) {
            dados.dataFim = dataMysql(dados.dataFim);
        }
        if (dados.dataLimiteAceite) {
            dados.dataLimiteAceite = dataMysql(dados.dataLimiteAceite);
        }
        if (dados.dataCompetencia) {
            dados.dataCompetencia = dataMysql(dados.dataCompetencia);
        }
        if (dados.replanejadoInicio) {
            dados.replanejadoInicio = dataMysql(dados.replanejadoInicio);
        }
        if (dados.replanejadoFim) {
            dados.replanejadoFim = dataMysql(dados.replanejadoFim);
        }
        if (dados.previsaoFaturamento) {
            dados.previsaoFaturamento = dataMysql(dados.previsaoFaturamento);
        }

        if (dados.responsavel?.constructor === Array) {
            dados.responsavel = dados.responsavel.map(item => item.value).join(',');
        }

        if (dados.responsavelcliente?.constructor === Array) {
            dados.responsavelcliente = dados.responsavelcliente.map(item => item.value).join(',');
        }

        if (id) {//update
            api({
                method: 'put',
                action: '/evento/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/evento");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert
            api({
                method: 'post',
                action: '/evento',
                token: token,
                data: dados
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/evento");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Evento</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <Tabs className="justify-content-end" activeKey={tabState} onSelect={(key) => { setTabState(key); }} defaultActiveKey="geral">
                                                <Tab eventKey="geral" title="Geral">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Empresa</label>
                                                                <ReactSelect
                                                                    id="empresa"
                                                                    value={{ value: dados.empresa, label: dados.nomeEmpresa }}
                                                                    onChange={e => setDados({ ...dados, ...{ "empresa": e.value, "nomeEmpresa": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "empresa",
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Cliente</label>
                                                                <ReactSelect
                                                                    id="cliente"
                                                                    value={{ value: dados.cliente, label: dados.nomeCliente }}
                                                                    onChange={e => setDados({ ...dados, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "cliente",
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="identificacao" placeholder="Identificação"
                                                                    value={dados.identificacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "identificacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Identificação</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Complexidade</label>
                                                                <ReactSelect
                                                                    id="complexidade"
                                                                    value={{ value: dados.complexidade, label: dados.nomeComplexidade }}
                                                                    onChange={e => setDados({ ...dados, ...{ "complexidade": e.value, "nomeComplexidade": e.label } })}
                                                                    options={[
                                                                        { value: 'baixa', label: 'Baixa' },
                                                                        { value: 'media', label: 'Média' },
                                                                        { value: 'alta', label: 'Alta' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Nacional ou Internacional</label>
                                                                <ReactSelect
                                                                    id="nacionalidade"
                                                                    value={{ value: dados.nacionalidade, label: dados.nomeNacionalidade }}
                                                                    onChange={e => setDados({ ...dados, ...{ "nacionalidade": e.value, "nomeNacionalidade": e.label } })}
                                                                    options={[
                                                                        { value: 'nacional', label: 'Nacional' },
                                                                        { value: 'internacional', label: 'Internacional' }
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                    value={dados.nome}
                                                                    onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome do Evento</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="codigoEm" placeholder="Código EM"
                                                                    value={dados.codigoEm}
                                                                    onChange={e => setDados({ ...dados, ...{ "codigoEm": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Código EM</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Tipo</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.tipo, label: dados.nomeTipo }}
                                                                    onChange={e => setDados({ ...dados, ...{ "tipo": e.value, "nomeTipo": e.label } })}
                                                                    options={[
                                                                        { value: 'advisoryboard', label: 'Advisory Board' },
                                                                        { value: 'estandecotapat', label: 'Estande e/ou Cota de Patrocínio' },
                                                                        { value: 'congresso', label: 'Congresso' },
                                                                        { value: 'simpsanofeventerc', label: 'Simpósio Sanofi em Evento de Terceiro' },
                                                                        { value: 'congestadcotapat', label: 'Congresso - Estande e/ou Cota de Patrocínio' },
                                                                        { value: 'estcotpatsimpsanoeveterc', label: 'Estande e/ou Cota de Patrocínio - Simpósio Sanofi em Evento de Terceiro' },
                                                                        { value: 'estcotpatcongsimpsanoeveterc', label: 'Estande e/ou Cota de Patrocínio - Congresso - Simpósio Sanofi em Evento de Terceiro' },
                                                                        { value: 'orgsede', label: 'Organizado Pela Sede' },
                                                                        { value: 'speakmeet', label: 'Speaker Meeting (One-Time)' },
                                                                        { value: 'scieneducpromee', label: 'Scientific Educational Program/Meeting' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Formato</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.tipoRealizacao, label: dados.nomeTipoRealizacao }}
                                                                    onChange={e => setDados({ ...dados, ...{ "tipoRealizacao": e.value, "nomeTipoRealizacao": e.label } })}
                                                                    options={[
                                                                        { value: 'digital', label: 'Digital' },
                                                                        { value: 'presencial', label: 'Presencial' },
                                                                        { value: 'hibrido', label: 'Híbrido' }
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Tipo de Lista</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.tipoIngresso, label: dados.nomeTipoIngresso }}
                                                                    onChange={e => setDados({ ...dados, ...{ "tipoIngresso": e.value, "nomeTipoIngresso": e.label } })}
                                                                    options={[
                                                                        { value: 'adesao', label: 'Adesão' },
                                                                        { value: 'lista', label: 'Lista Prévia' }
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="link" placeholder="Link"
                                                                    value={dados.link}
                                                                    onChange={e => setDados({ ...dados, ...{ "link": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Link</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-floating form-group">
                                                                        <input type="file" multiple={false} className="form-control form-control-lg" id="logomarca" placeholder="Logomarca (.jpeg)"
                                                                            value={dados.nomeLogomarca}
                                                                            onChange={(e) => {
                                                                                var nomeLogomarca = e.target.value;
                                                                                //transforma binario em json hexadecimal
                                                                                const fileReader = new FileReader();
                                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                                fileReader.onload = e => {
                                                                                    setDados({
                                                                                        ...dados,
                                                                                        ...{
                                                                                            "nomeLogomarca": nomeLogomarca,
                                                                                            "binarioLogomarca": bin2hex(e.target.result),
                                                                                        }
                                                                                    });
                                                                                };
                                                                            }}
                                                                        />
                                                                        <label htmlFor="floatingInput">Logomarca (.jpeg)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dados.logomarca?.link ?
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <img alt="" src={dados.logomarca.link} width={"400px"}></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-floating form-group">
                                                                        <input type="file" multiple={false} className="form-control form-control-lg" id="imagemfundo" placeholder="Imagem de Fundo (.jpeg)"
                                                                            value={dados.nomeImagemfundo}
                                                                            onChange={(e) => {
                                                                                var nomeImagemfundo = e.target.value;
                                                                                //transforma binario em json hexadecimal
                                                                                const fileReader = new FileReader();
                                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                                fileReader.onload = e => {
                                                                                    setDados({
                                                                                        ...dados,
                                                                                        ...{
                                                                                            "nomeImagemfundo": nomeImagemfundo,
                                                                                            "binarioImagemfundo": bin2hex(e.target.result),
                                                                                        }
                                                                                    });
                                                                                };
                                                                            }}
                                                                        />
                                                                        <label htmlFor="floatingInput">Imagem de Fundo (.jpeg)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dados.imagemfundo?.link ?
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <img alt="" src={dados.imagemfundo.link} width={"400px"}></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="datas" title="Datas">
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"data"}
                                                                label="Data Inicial"
                                                                value={dados.data}
                                                                onChange={date => setDados({ ...dados, ...{ "data": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="hora" placeholder="Horário Inicial"
                                                                    value={dados.hora}
                                                                    onChange={e => setDados({ ...dados, ...{ "hora": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Horário Inicial</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                label="Data Final"
                                                                value={dados.dataFim}
                                                                onChange={date => setDados({ ...dados, ...{ "dataFim": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="horaFim" placeholder="Horário Final"
                                                                    value={dados.horaFim}
                                                                    onChange={e => setDados({ ...dados, ...{ "horaFim": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Horário Final</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <Data
                                                                label="Replanejado Início"
                                                                value={dados.replanejadoInicio}
                                                                onChange={date => setDados({ ...dados, ...{ "replanejadoInicio": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                label="Replanejado Término"
                                                                value={dados.replanejadoFim}
                                                                onChange={date => setDados({ ...dados, ...{ "replanejadoFim": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                label="Data SLA Eventos"
                                                                value={dados.dataCompetencia}
                                                                onChange={date => setDados({ ...dados, ...{ "dataCompetencia": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"dataLimiteAceite"}
                                                                label="Data Limite Aceite"
                                                                value={dados.dataLimiteAceite}
                                                                onChange={date => setDados({ ...dados, ...{ "dataLimiteAceite": date } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="quantidades" title="Dados Participantes">
                                                    <h2>Quantidade de Convidados</h2>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadePessoas" placeholder="Quant. Conv. Confirmados Previamente"
                                                                    value={dados.quantidadePessoas}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadePessoas": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quant. Conv. Confirmados Previamente</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeConvidados" placeholder="Quantidade de Convidados"
                                                                    value={dados.quantidadeConvidados}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeConvidados": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quantidade de Convidados</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeAereo" placeholder="Quantidade Aereo"
                                                                    value={dados.quantidadeAereo}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeAereo": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quantidade Aéreo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeConvidadosInclusosLocal" placeholder="Quant. Conv. Inclusos Local"
                                                                    value={dados.quantidadeConvidadosInclusosLocal}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeConvidadosInclusosLocal": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quant. Conv. Inclusos Local</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeConvidadosNaoCompareceram" placeholder="Quant. Conv. Não Compareceram"
                                                                    value={dados.quantidadeConvidadosNaoCompareceram}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeConvidadosNaoCompareceram": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quant. Conv. Não Compareceram</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeParticipantesPosEvento" placeholder="Quant. Participantes Pós Evento"
                                                                    value={dados.quantidadeParticipantesPosEvento}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeParticipantesPosEvento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quant. Participantes Pós Evento</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeStaffs" placeholder="Quantidade de STAFFS"
                                                                    value={dados.quantidadeStaffs}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeStaffs": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quantidade de STAFFS</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeStaffsTerceiros" placeholder="Quant. STAFFS Terceiros"
                                                                    value={dados.quantidadeStaffsTerceiros}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeStaffsTerceiros": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quant. STAFFS Terceiros</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadePresentesTotal" placeholder="Quant. Presentes Total"
                                                                    value={dados.quantidadePresentesTotal}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadePresentesTotal": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quant. Presentes Total</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h2>Pesquisa</h2>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="npsRecomendariaEvento" placeholder="NPS Recomendaria o Evento"
                                                                    value={dados.npsRecomendariaEvento}
                                                                    onChange={e => setDados({ ...dados, ...{ "npsRecomendariaEvento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">NPS Recomendaria o Evento</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="npsSatisfacaoEvento" placeholder="NPS Satisfação Evento"
                                                                    value={dados.npsSatisfacaoEvento}
                                                                    onChange={e => setDados({ ...dados, ...{ "npsSatisfacaoEvento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">NPS Satisfação Evento</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="quantidadeRespondentesPesquisa" placeholder="Quantidade Respondentes Pesquisa"
                                                                    value={dados.quantidadeRespondentesPesquisa}
                                                                    onChange={e => setDados({ ...dados, ...{ "quantidadeRespondentesPesquisa": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Quantidade Respondentes Pesquisa</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg right" id="taxaConversaoRespondentes" placeholder="Taxa Conversão Respondentes (%)"
                                                                    value={pontoVirgula(dados.taxaConversaoRespondentes)}
                                                                    onChange={e => setDados({ ...dados, ...{ "taxaConversaoRespondentes": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Taxa Conversão Respondentes (%)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h2>Watching Time</h2>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="mediaWatchingTime" placeholder="Média Watching Time (min)"
                                                                    value={dados.mediaWatchingTime}
                                                                    onChange={e => setDados({ ...dados, ...{ "mediaWatchingTime": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Média Watching Time (min)</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="duracaoEvento" placeholder="Duração Evento (min)"
                                                                    value={dados.duracaoEvento}
                                                                    onChange={e => setDados({ ...dados, ...{ "duracaoEvento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Duração Evento (min)</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg right" id="mediaPermanenciaConvidados" placeholder="Média Permanência Convidados (%)"
                                                                    value={pontoVirgula(dados.mediaPermanenciaConvidados)}
                                                                    onChange={e => setDados({ ...dados, ...{ "mediaPermanenciaConvidados": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Média Permanência Convidados (min)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h4>Termo de Aceite da Assinatura</h4>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="termoaceiteassinatura"
                                                                    placeholder="Termo de Aceite"
                                                                    style={{ minHeight: "400px" }}
                                                                    value={dados.termoaceiteassinatura}
                                                                    onChange={e => setDados({ ...dados, ...{ "termoaceiteassinatura": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Termo de Aceite</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="responsaveis" title="Responsáveis">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Responsável na Agência</label>
                                                                <ReactSelect
                                                                    readOnly={['diretor', 'gerente', 'gerentebackoffice', 'backoffice'].includes(perfil) ? false : true}
                                                                    isMulti={true}
                                                                    value={dados.responsavel ?? []}
                                                                    onChange={(e) => {
                                                                        dados.responsavel = dados.responsavel || [];
                                                                        dados.responsavel = e;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "pessoa",
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Diretor Responsável</label>
                                                                <ReactSelect
                                                                    readOnly={['diretor', 'gerente', 'gerentebackoffice', 'backoffice'].includes(perfil) ? false : true}
                                                                    value={{ value: dados.diretorresponsavel, label: dados.nomeDiretorresponsavel }}
                                                                    onChange={e => setDados({ ...dados, ...{ "diretorresponsavel": e.value, "nomeDiretorresponsavel": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "pessoa",
                                                                                perfil: [1], // 1 - Diretor
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Responsável no Cliente (Focal Point Eventos)</label>
                                                                <ReactSelect
                                                                    key={dados.cliente ?? null}
                                                                    isMulti={true}
                                                                    value={dados.responsavelcliente ?? []}
                                                                    onChange={(e) => {
                                                                        dados.responsavelcliente = dados.responsavelcliente || [];
                                                                        dados.responsavelcliente = e;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/eventoResponsavelCliente',
                                                                            token: token,
                                                                            params: {
                                                                                cliente: dados.cliente ?? null,
                                                                                nome: input,
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: registro.nome
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="local" title="Local">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="local" placeholder="Local"
                                                                    value={dados.local}
                                                                    onChange={e => setDados({ ...dados, ...{ "local": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Local</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="endereco" placeholder="Endereço"
                                                                    value={dados.endereco}
                                                                    onChange={e => setDados({ ...dados, ...{ "endereco": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Endereço</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label>Cidade</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.municipio, label: dados.nomeMunicipio }}
                                                                    onChange={e => setDados({ ...dados, ...{ "municipio": e.value, "nomeMunicipio": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/municipio',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="municipioExterior" placeholder="Município Exterior"
                                                                    value={dados.municipioExterior}
                                                                    onChange={e => setDados({ ...dados, ...{ "municipioExterior": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Município Exterior</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="bairro" placeholder="Bairro"
                                                                    value={dados.bairro}
                                                                    onChange={e => setDados({ ...dados, ...{ "bairro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Bairro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>País</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.pais, label: dados.nomePais }}
                                                                    onChange={e => setDados({ ...dados, ...{ "pais": e.value, "nomePais": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/pais',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="faturamento" title="Faturamento">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg right" id="previsaoReceita" placeholder="Previsão de Receita"
                                                                    value={pontoVirgula(dados.previsaoReceita)}
                                                                    onChange={(e) => {
                                                                        dados.previsaoReceita = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Valor Previsão de Receita</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                id="previsaoFaturamento"
                                                                label="Previsão de Faturamento"
                                                                value={dados.previsaoFaturamento}
                                                                onChange={date => setDados({ ...dados, ...{ "previsaoFaturamento": date } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg right" id="valorBudget" placeholder="Budget VCard"
                                                                    value={pontoVirgula(dados.valorBudget)}
                                                                    onChange={(e) => {
                                                                        dados.valorBudget = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Valor Budget VCard</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg right" id="numeroVcard" placeholder="Número do VCard"
                                                                    value={dados.numeroVcard}
                                                                    onChange={(e) => {
                                                                        dados.numeroVcard = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Número do VCard</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}