import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { bin2hex, data, dataMysql, extract, pontoVirgula } from '../../util/util';
import Data from '../../components/Data';
import { toast } from 'react-toastify';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import InputMask from 'react-input-mask';

export default function CadastroEventoPergunta({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    //dataCompetencia: new Date(),
    const [dados, setDados] = useState({
        responsavel: [idUsuario],
        nomeResponsavel: nomeUsuario,
        empresa: empresa,
        nomeEmpresa: nomeEmpresa
    });
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/evento/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'diretorresponsavel',
                        'cliente',
                        'empresa',
                        'municipio',
                        'pais',
                        'modelotermoaceite',
                        'modeloemailconvite',
                        'modelocontrato',
                        'modelocartaconvite',
                        'modelocarta',
                        'coordenadorrsvp',
                    ]
                });
                //transforma data para javascript
                dadosRecebidos.data = data(dadosRecebidos.data);
                dadosRecebidos.dataFim = data(dadosRecebidos.dataFim);
                dadosRecebidos.dataLimiteAceite = data(dadosRecebidos.dataLimiteAceite);
                dadosRecebidos.dataCompetencia = data(dadosRecebidos.dataCompetencia);
                dadosRecebidos.replanejadoInicio = data(dadosRecebidos.replanejadoInicio);
                dadosRecebidos.replanejadoFim = data(dadosRecebidos.replanejadoFim);
                dadosRecebidos.previsaoFaturamento = data(dadosRecebidos.previsaoFaturamento);

                if (dadosRecebidos.responsavel?.constructor !== Array)
                    dadosRecebidos.responsavel = [];
                dadosRecebidos.responsavel = dadosRecebidos.responsavel.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.responsavelcliente?.constructor !== Array)
                    dadosRecebidos.responsavelcliente = [];
                dadosRecebidos.responsavelcliente = dadosRecebidos.responsavelcliente.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if id
        else {
            setDados({
                ...dados,
                ...{
                    sistemaPergunta: 'sim',
                    pais: '30',
                    nomePais: 'Brasil'
                }
            });
        }
    };

    const validar = () => {
        var nome = document.querySelector("#nome");
        if (nome.value === '') {
            toast.error('É necessário preencher o Nome do evento');
            setTimeout(() => {
                nome.focus();
            }, 500);
            setTabState("geral");
            return false;
        }
        var cliente = document.querySelector("#cliente");
        if (dados.cliente === undefined || dados.cliente === '') {
            toast.error('É necessário preencher o Cliente');
            setTimeout(() => {
                cliente.focus();
            }, 500);
            setTabState("geral");
            return false;
        }

        var data = document.querySelector("#data");
        if (data.value === '') {
            toast.error('É necessário preencher a Data de Início');
            setTimeout(() => {
                data.focus();
            }, 500);
            setTabState("datas");
            return false;
        }

        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }
        if (dados.data) {
            dados.data = dataMysql(dados.data);
        }
        if (dados.dataFim) {
            dados.dataFim = dataMysql(dados.dataFim);
        }
        if (dados.dataLimiteAceite) {
            dados.dataLimiteAceite = dataMysql(dados.dataLimiteAceite);
        }
        if (dados.dataCompetencia) {
            dados.dataCompetencia = dataMysql(dados.dataCompetencia);
        }
        if (dados.replanejadoInicio) {
            dados.replanejadoInicio = dataMysql(dados.replanejadoInicio);
        }
        if (dados.replanejadoFim) {
            dados.replanejadoFim = dataMysql(dados.replanejadoFim);
        }
        if (dados.previsaoFaturamento) {
            dados.previsaoFaturamento = dataMysql(dados.previsaoFaturamento);
        }

        if (dados.responsavel?.constructor === Array) {
            dados.responsavel = dados.responsavel.map(item => item.value).join(',');
        }

        if (dados.responsavelcliente?.constructor === Array) {
            dados.responsavelcliente = dados.responsavelcliente.map(item => item.value).join(',');
        }

        if (id) {//update
            api({
                method: 'put',
                action: '/evento/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/eventoPergunta");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert
            api({
                method: 'post',
                action: '/evento',
                token: token,
                data: dados
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/eventoPergunta");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Evento</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <Tabs className="justify-content-end" activeKey={tabState} onSelect={(key) => { setTabState(key); }} defaultActiveKey="geral">
                                                <Tab eventKey="geral" title="Geral">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Empresa</label>
                                                                <ReactSelect
                                                                    id="empresa"
                                                                    value={{ value: dados.empresa, label: dados.nomeEmpresa }}
                                                                    onChange={e => setDados({ ...dados, ...{ "empresa": e.value, "nomeEmpresa": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "empresa",
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Cliente</label>
                                                                <ReactSelect
                                                                    id="cliente"
                                                                    value={{ value: dados.cliente, label: dados.nomeCliente }}
                                                                    onChange={e => setDados({ ...dados, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participante',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                tipoCadastro: "cliente",
                                                                                situacaoRegistro: "ativo",
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.codigo +
                                                                                        ' - ' + registro.nome +
                                                                                        (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                        (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                        (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                        (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="identificacao" placeholder="Identificação"
                                                                    value={dados.identificacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "identificacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Identificação</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                    value={dados.nome}
                                                                    onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome do Evento</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-floating form-group">
                                                                        <input type="file" multiple={false} className="form-control form-control-lg" id="logomarca" placeholder="Logomarca (.jpeg)"
                                                                            value={dados.nomeLogomarca}
                                                                            onChange={(e) => {
                                                                                var nomeLogomarca = e.target.value;
                                                                                //transforma binario em json hexadecimal
                                                                                const fileReader = new FileReader();
                                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                                fileReader.onload = e => {
                                                                                    setDados({
                                                                                        ...dados,
                                                                                        ...{
                                                                                            "nomeLogomarca": nomeLogomarca,
                                                                                            "binarioLogomarca": bin2hex(e.target.result),
                                                                                        }
                                                                                    });
                                                                                };
                                                                            }}
                                                                        />
                                                                        <label htmlFor="floatingInput">Logomarca (.jpeg)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dados.logomarca?.link ?
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <img alt="" src={dados.logomarca.link} width={"400px"}></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-floating form-group">
                                                                        <input type="file" multiple={false} className="form-control form-control-lg" id="imagemfundo" placeholder="Imagem de Fundo (.jpeg)"
                                                                            value={dados.nomeImagemfundo}
                                                                            onChange={(e) => {
                                                                                var nomeImagemfundo = e.target.value;
                                                                                //transforma binario em json hexadecimal
                                                                                const fileReader = new FileReader();
                                                                                fileReader.readAsBinaryString(e.target.files[0]);
                                                                                fileReader.onload = e => {
                                                                                    setDados({
                                                                                        ...dados,
                                                                                        ...{
                                                                                            "nomeImagemfundo": nomeImagemfundo,
                                                                                            "binarioImagemfundo": bin2hex(e.target.result),
                                                                                        }
                                                                                    });
                                                                                };
                                                                            }}
                                                                        />
                                                                        <label htmlFor="floatingInput">Imagem de Fundo (.jpeg)</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {dados.imagemfundo?.link ?
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <img alt="" src={dados.imagemfundo.link} width={"400px"}></img>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="datas" title="Datas">
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"data"}
                                                                label="Data Inicial"
                                                                value={dados.data}
                                                                onChange={date => setDados({ ...dados, ...{ "data": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="hora" placeholder="Horário Inicial"
                                                                    value={dados.hora}
                                                                    onChange={e => setDados({ ...dados, ...{ "hora": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Horário Inicial</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                label="Data Final"
                                                                value={dados.dataFim}
                                                                onChange={date => setDados({ ...dados, ...{ "dataFim": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="horaFim" placeholder="Horário Final"
                                                                    value={dados.horaFim}
                                                                    onChange={e => setDados({ ...dados, ...{ "horaFim": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Horário Final</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="local" title="Local">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="local" placeholder="Local"
                                                                    value={dados.local}
                                                                    onChange={e => setDados({ ...dados, ...{ "local": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Local</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="endereco" placeholder="Endereço"
                                                                    value={dados.endereco}
                                                                    onChange={e => setDados({ ...dados, ...{ "endereco": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Endereço</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label>Cidade</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.municipio, label: dados.nomeMunicipio }}
                                                                    onChange={e => setDados({ ...dados, ...{ "municipio": e.value, "nomeMunicipio": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/municipio',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="municipioExterior" placeholder="Município Exterior"
                                                                    value={dados.municipioExterior}
                                                                    onChange={e => setDados({ ...dados, ...{ "municipioExterior": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Município Exterior</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="bairro" placeholder="Bairro"
                                                                    value={dados.bairro}
                                                                    onChange={e => setDados({ ...dados, ...{ "bairro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Bairro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>País</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.pais, label: dados.nomePais }}
                                                                    onChange={e => setDados({ ...dados, ...{ "pais": e.value, "nomePais": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/pais',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}