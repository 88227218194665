import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaThumbsUp, FaThumbsDown, FaEye, FaPlane, FaPlaneDeparture, FaPlaneArrival, FaEdit } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import NavItem from '../../../components/NavItem';
import { dataMysql, buscarCep, extract, data, hora, dataHoraMysql } from '../../../util/util';
import Data from '../../../components/Data';
import FormButton from '../../../components/FormButton';
import IconButton from '../../../components/IconButton';
import ReactSelect from '../../../components/ReactSelect';
import Alert from '../../../components/Alert';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ModalButton from '../../../components/ModalButton';

export default function CadastroEventoPalestrante({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const [dadosModalHistorico, setDadosModalHistorico] = useState({});
    const [historico, setHistorico] = useState([]);
    const [tableActions, setTableActions] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const history = useHistory();
    let { state } = useLocation();
    var evento = state?.evento ?? null;

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/participante/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'enderecopais',
                        'aereomunicipioembarque',
                        'enderecomunicipio',
                        'tipoparticipante',
                        'hotelmunicipio',
                        'banco',
                    ]
                });
                evento = dadosRecebidos.evento.id;
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//id id
        else {
            setDados({
                ...dados,
                ...{
                    evento: evento,
                    tipoCadastro: 'palestrante',
                    tipoparticipante: 3, //3 - palestrante
                }
            });
        }
    };

    const salvar = () => {
        let dadosSalvar = { ...dados }; //faz alterações antes de enviar para salvar
        delete dadosSalvar.enderecopais;
        delete dadosSalvar.aereomunicipioembarque;
        delete dadosSalvar.enderecomunicipio;
        delete dadosSalvar.hotelmunicipio;
        delete dadosSalvar.banco;
        delete dadosSalvar.situacaoevento;

        if (id) {//update
            delete dadosSalvar.evento; //ja tem evento nao precisa alterar
            api({
                method: 'put',
                action: '/participante/' + id,
                token: token,
                data: dadosSalvar,
            })
                .then(function (response) {
                    toast.success("As alterações foram salvas com sucesso!");
                    history.push("/eventoPalestrante/" + evento);
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/participante',
                token: token,
                data: dadosSalvar
            })
                .then(function (response) {
                    history.push("/eventoPalestrante/" + evento);
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Evento: {dados.evento?.nome}</h3>
                    <h3>Palestrante</h3>
                </div>
                <div className="row">
                    <div style={{ marginBottom: "10px" }} className="col-sm-12 d-flex justify-content-end">
                    </div>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <Tabs className="justify-content-end" defaultActiveKey="geral">
                                                <Tab eventKey="geral" title="Geral">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="codigo" placeholder="Código"
                                                                    value={dados.codigo}
                                                                    onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Código</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                    value={dados.nome}
                                                                    onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="telefone" placeholder="Telefone"
                                                                    value={dados.telefone}
                                                                    onChange={e => setDados({ ...dados, ...{ "telefone": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="telefone2" placeholder="Telefone 2"
                                                                    value={dados.telefone2}
                                                                    onChange={e => setDados({ ...dados, ...{ "telefone2": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone 2</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="(99) 99999-9999" className="form-control form-control-lg" id="celular" placeholder="Celular"
                                                                    value={dados.celular}
                                                                    onChange={e => setDados({ ...dados, ...{ "celular": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Celular</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="email" placeholder="E-mail"
                                                                    value={dados.email}
                                                                    onChange={e => setDados({ ...dados, ...{ "email": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">E-mail</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="emailsecundario" placeholder="Outros E-mails"
                                                                    value={dados.emailsecundario}
                                                                    onChange={e => setDados({ ...dados, ...{ "emailsecundario": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Outros E-mails</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="especialidade" placeholder="Especialidade"
                                                                    value={dados.especialidade}
                                                                    onChange={e => setDados({ ...dados, ...{ "especialidade": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Especialidade</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="crm" placeholder="CRM"
                                                                    value={dados.crm}
                                                                    onChange={e => setDados({ ...dados, ...{ "crm": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">CRM</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Estado do CRM</label>
                                                                <ReactSelect
                                                                    id={"crmuf"}
                                                                    value={{ value: dados.crmuf, label: dados.crmuf }}
                                                                    onChange={e => setDados({ ...dados, ...{ "crmuf": e.value, "nomeUf": e.label } })}
                                                                    options={[
                                                                        { value: "AC", label: "AC" },
                                                                        { value: "AL", label: "AL" },
                                                                        { value: "AP", label: "AP" },
                                                                        { value: "AM", label: "AM" },
                                                                        { value: "BA", label: "BA" },
                                                                        { value: "CE", label: "CE" },
                                                                        { value: "DF", label: "DF" },
                                                                        { value: "ES", label: "ES" },
                                                                        { value: "GO", label: "GO" },
                                                                        { value: "MA", label: "MA" },
                                                                        { value: "MT", label: "MT" },
                                                                        { value: "MS", label: "MS" },
                                                                        { value: "MG", label: "MG" },
                                                                        { value: "PA", label: "PA" },
                                                                        { value: "PB", label: "PB" },
                                                                        { value: "PR", label: "PR" },
                                                                        { value: "PE", label: "PE" },
                                                                        { value: "PI", label: "PI" },
                                                                        { value: "RJ", label: "RJ" },
                                                                        { value: "RN", label: "RN" },
                                                                        { value: "RS", label: "RS" },
                                                                        { value: "RO", label: "RO" },
                                                                        { value: "RR", label: "RR" },
                                                                        { value: "SC", label: "SC" },
                                                                        { value: "SP", label: "SP" },
                                                                        { value: "SE", label: "SE" },
                                                                        { value: "TO", label: "TO" },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="instituicao" placeholder="Instituição"
                                                                    value={dados.instituicao}
                                                                    onChange={e => setDados({ ...dados, ...{ "instituicao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Instituição</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            <div className="row fixoRodape">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}