import React, { useState } from "react";
import IconButton from './IconButton';
import { Modal, Header, Body } from "react-bootstrap";

export default function ModalButton({ title, small = false, icon, color, label, children, onHide = null }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <IconButton
                title={title}
                small={small}
                icon={icon}
                color={color}
                onClick={handleShow}>{label}</IconButton>
            <Modal size={"lg"} show={show} onHide={() => {
                if (onHide)
                    onHide();
                handleClose();
            }}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-content">
                        {children}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}