import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import { pontoVirgula } from '../../../util/util';

export default function CadastroCaixa({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/financeiroCaixa/' + id,
                token: token,
            }).then(response => { //extrai dados internos para o array principal
                var dadosRecebidos = response.data.data;
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        } else {
            setDados({ principal: "nao" });
        }
    };

    const salvar = () => {
        if (id) {//update
            api({
                method: 'put',
                action: '/financeiroCaixa/' + id,
                token: token,
                data: dados,
            })
                .then(function (response) {
                    history.push("/financeiroCaixa");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/financeiroCaixa',
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/financeiroCaixa");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Caixa</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-check">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox"
                                                                    className="form-check-input"
                                                                    value={dados.principal}
                                                                    checked={dados.principal === "sim" ? true : false}
                                                                    onChange={(value) => {
                                                                        dados.principal = dados.principal === "nao" ? "sim" : "nao";
                                                                        setDados({ ...dados });
                                                                    }} />Principal</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="diaVencimentoCartao" placeholder="Dia Vencimento Cartão"
                                                            value={dados.diaVencimentoCartao}
                                                            onChange={e => setDados({ ...dados, ...{ "diaVencimentoCartao": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Dia Vencimento Cartão</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="diaCorteCartao" placeholder="Dia Corte Cartão"
                                                            value={dados.diaCorteCartao}
                                                            onChange={e => setDados({ ...dados, ...{ "diaCorteCartao": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Dia Corte Cartão</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="cartaolimite" placeholder="Limite do Cartão"
                                                            value={pontoVirgula(dados.cartaolimite)}
                                                            onChange={(e) => {
                                                                dados.cartaolimite = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Limite do cartão</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="cartaosaldo" placeholder="Saldo do Cartão"
                                                            value={pontoVirgula(dados.cartaosaldo)}
                                                            onChange={(e) => {
                                                                dados.cartaosaldo = e.target.value;
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">Saldo do cartão</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}