import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaPen, FaPlus, FaThumbsUp, FaSearch, FaTable, FaList, FaTruck, FaFileAlt, FaUpload, FaCopy } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import Alert from '../../components/Alert';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactSelect from '../../components/ReactSelect';
import Data from '../../components/Data';
import FormButton from '../../components/FormButton';
import { useLocation } from 'react-router-dom';
import { moeda } from '../../util/util';

export default function PainelOrcamento() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const usuario = localStorage.getItem("idUsuario");

    const [filtro, setFiltro] = useState({
        empresa: empresa
    }); //1 - orcamento
    const [orcamentos, setOrcamentos] = useState([]);
    const [tableSelect, setTableSelect] = useState([]);
    const [tableActions, setTableActions] = useState([]);
    const params = new URLSearchParams(window.location.search);
    const idOrcamento = params.get('idOrcamento');

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        api({
            method: 'get',
            action: '/orcamento',
            token: token,
            params: {
                ...filtro,
                ...{
                    id: idOrcamento,
                    perfil: perfil
                }
            }
        })
            .then(response => {
                setOrcamentos(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        [filtro.tipo, filtro.evento]);

    useEffect(() => { //onload
        var arrayids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }) : [];

        if (tableSelect.selectedCount > 0) {
            setTableActions([
                tableSelect.selectedRows.length === 1 ? //apenas 1 selecionado
                    <IconButton
                        to={{
                            pathname: "/cadastroOrcamento/" + tableSelect.selectedRows[0].id,
                            state: { copiar: true }
                        }}
                        icon={<FaCopy size={20} />}
                        color="primary">Copiar</IconButton> : null,
                tableSelect.selectedRows.length > 0 ?
                    <FormButton
                        method="post"
                        action={process.env.REACT_APP_API_URL + "/orcamento/gerarPlanilhaExcel"}
                        icon={<FaTable size={20} />}
                        color="primary"
                        data={{
                            token: localStorage.getItem('token'),
                            rows: tableSelect.selectedRows.map((row) => {
                                return row.id;
                            })
                        }}
                    >Excel Orçamento</FormButton> :
                    null,
                tableSelect.selectedRows.length > 1 ?
                    <FormButton
                        method="post"
                        action={process.env.REACT_APP_API_URL + "/orcamento/gerarResumoAgrupadoExcel"}
                        icon={<FaTable size={20} />}
                        color="primary"
                        data={{
                            token: localStorage.getItem('token'),
                            rows: tableSelect.selectedRows.map((row) => {
                                return row.id;
                            })
                        }}
                    >Resumo Agrupado</FormButton> :
                    null,
                tableSelect.selectedRows.length === 1 ?
                    <FormButton
                        method="post"
                        action={process.env.REACT_APP_API_URL + "/orcamento/gerarPlanilhaExcelPagamentos"}
                        icon={<FaTable size={20} />}
                        color="primary"
                        data={{
                            token: localStorage.getItem('token'),
                            rows: tableSelect.selectedRows.map((row) => {
                                return row.id;
                            })
                        }}
                    >Excel Pagamentos</FormButton> :
                    null,
                tableSelect.selectedRows.length === 1 ? //apenas 1 selecionado
                    <IconButton
                        icon={<ImCancelCircle size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "error",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                title: "Excluir",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'delete',
                                            action: '/orcamento/' + tableSelect.selectedRows[0].id,
                                            token: token
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Excluir</IconButton > : null,
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/cadastroOrcamento" icon={<FaPlus size={20} />} color="primary">Novo Orçamento</NavItem>
                    <NavItem to="/importarOrcamento" icon={<FaUpload size={20} />} color="outline-dark">Importar Orçamento do Excel</NavItem>
                    <NavItem to="/servicoOrcamento" icon={<FaList size={20} />} color="outline-dark">Itens Orçamentários</NavItem>
                    <NavItem to="/produtoOrcamento" icon={<FaList size={20} />} color="outline-dark">Produtos</NavItem>
                    <NavItem
                        to={{
                            pathname: "/fornecedor",
                            state: {
                                tipoCadastro: "orcamento"
                            }
                        }}
                        icon={<FaTruck size={20} />}
                        color="outline-dark">Fornecedores</NavItem>
                    <NavItem to="/orcamentoRelatorioPagamento" icon={<FaFileAlt size={20} />} color="outline-dark">Relatório de Pagamentos</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Orçamentos</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            id="cliente"
                                                            value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "cliente",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.evento, label: filtro.nomeEvento }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <Data
                                                        label="Data"
                                                        value={filtro.data1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-4">
                                                    <Data
                                                        label="até a Data"
                                                        value={filtro.data2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows="true"
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        if (['diretor', 'gerentefinanceiro'].includes(perfil) || usuario === row.operador.id) {
                                                            return <IconButton
                                                                small={true}
                                                                to={"/cadastroOrcamento/" + row.id}
                                                                icon={<FaPen size={20} />}
                                                                color="primary">Alterar</IconButton>;
                                                        }
                                                    },
                                                    width: "110px",
                                                },
                                                {
                                                    name: 'Número',
                                                    selector: row => row.codigo ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Título',
                                                    selector: row => row.titulo ?? "",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Cliente',
                                                    selector: row => row.evento?.cliente?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        if (row.evento?.cliente) {
                                                            if (row.evento?.cliente?.enderecomunicipio) { //tem municipio
                                                                return row.evento?.cliente.codigo + ' - ' + row.evento?.cliente.nome + ' - ' + row.evento?.cliente.enderecomunicipio.nome + ' - ' + row.evento?.cliente.enderecomunicipio.uf;
                                                            } else { //nao tem municipio
                                                                return row.evento?.cliente.codigo + ' - ' + row.evento?.cliente.nome;
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Evento',
                                                    selector: row => row.evento?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.evento ? row.evento.codigo + ' - ' + row.evento.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Data Evento',
                                                    selector: row => row.evento?.data ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.evento?.data ? moment(row.evento.data).format("DD/MM/YYYY") : "";
                                                    },
                                                },
                                                {
                                                    name: 'Data Orçamento',
                                                    selector: row => row.data ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.data ? " " + moment(row.data).format("DD/MM/YYYY") : "";
                                                    },
                                                },
                                                {
                                                    name: 'Usuário',
                                                    selector: row => row.operador?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.operador ? row.operador.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Valor',
                                                    selector: row => row.valorTotal ?? "",
                                                    sortable: true,
                                                    right: true,
                                                    cell: (row) => {
                                                        var valorTotal = row.valorTotal;
                                                        var textoValorTotal = <div width="100%"><strong>{moeda(valorTotal)}</strong></div>;
                                                        return <div className="right">
                                                            {textoValorTotal}
                                                        </div>;
                                                    },
                                                },
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        if (['diretor', 'gerentefinanceiro'].includes(perfil) || usuario === row.operador.id) {
                                                            return <IconButton
                                                                small={true}
                                                                to={"/cadastroPagamentoOrcamento/" + row.id}
                                                                icon={<FaPen size={20} />}
                                                                color="primary">Pagamento</IconButton>;
                                                        }
                                                    },
                                                    width: "130px",
                                                },
                                            ]}
                                            data={orcamentos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
