import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaThumbsUp, FaThumbsDown, FaEye, FaPlane, FaPlaneDeparture, FaPlaneArrival, FaEdit } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import NavItem from '../../../components/NavItem';
import { dataMysql, buscarCep, extract, data, hora, dataHoraMysql } from '../../../util/util';
import Data from '../../../components/Data';
import FormButton from '../../../components/FormButton';
import IconButton from '../../../components/IconButton';
import ReactSelect from '../../../components/ReactSelect';
import Alert from '../../../components/Alert';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ModalButton from '../../../components/ModalButton';

export default function CadastroEventoParticipante({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const [dadosModalHistorico, setDadosModalHistorico] = useState({});
    const [historico, setHistorico] = useState([]);
    const [tableActions, setTableActions] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const history = useHistory();
    let { state } = useLocation();
    var evento = state?.evento ?? null;

    const preencherCep = (cep, dadosAtuais) => {
        buscarCep(cep).then((resposta) => {
            if (!resposta.erro) {
                setDados({
                    ...dadosAtuais,
                    ...{
                        enderecobairro: resposta.bairro,
                        enderecocomplemento: resposta.complemento,
                        enderecologradouro: resposta.logradouro,
                        enderecopais: "30",
                        nomeEnderecopais: "Brasil",
                        enderecomunicipio: resposta.ibge,
                        nomeEnderecomunicipio: resposta.localidade
                    }
                });
            }
        });
    }

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/participante/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'enderecopais',
                        'aereomunicipioembarque',
                        'enderecomunicipio',
                        'tipoparticipante',
                        'hotelmunicipio',
                        'banco',
                    ]
                });
                evento = dadosRecebidos.evento.id;
                dadosRecebidos.hoteldatacheckin = data(dadosRecebidos.hoteldatacheckin);
                dadosRecebidos.hoteldatacheckout = data(dadosRecebidos.hoteldatacheckout);
                dadosRecebidos.datanascimento = data(dadosRecebidos.datanascimento);
                dadosRecebidos.dataopcaoaereo = data(dadosRecebidos.dataopcaoaereo);
                dadosRecebidos.horacartainformativa = hora(dadosRecebidos.datacartainformativa);
                dadosRecebidos.datacartainformativa = data(dadosRecebidos.datacartainformativa);
                dadosRecebidos.dataconfirmacaocartainformativa = data(dadosRecebidos.dataconfirmacaocartainformativa);
                dadosRecebidos.horacartaconvite = hora(dadosRecebidos.datacartaconvite);
                dadosRecebidos.datacartaconvite = data(dadosRecebidos.datacartaconvite);
                dadosRecebidos.datacartaassinada = data(dadosRecebidos.datacartaassinada);
                dadosRecebidos.dataconfirmacaodados = data(dadosRecebidos.dataconfirmacaodados);
                dadosRecebidos.horaescolhaaereo = hora(dadosRecebidos.dataescolhaaereo);
                dadosRecebidos.dataescolhaaereo = data(dadosRecebidos.dataescolhaaereo);
                dadosRecebidos.datainscricao = data(dadosRecebidos.datainscricao);
                setDados(dadosRecebidos);

                //atualiza lista de historico do participante
                buscarHistorico(id);

            }).catch(err => {
                //console.log(err)
            });
        }//id id
        else {
            setDados({
                ...dados,
                ...{
                    evento: evento,
                    tipoCadastro: 'participante',
                }
            });
        }
    };
    const buscarHistorico = (idParticipante) => {
        api({
            method: "get",
            action: '/participanteHistoricoSituacao',
            token: token,
            params: { participante: idParticipante }
        }).then(response => {
            var dadosRecebidosHistorico = response.data.data;
            setHistorico(dadosRecebidosHistorico);
        }).catch(err => {
            //console.log(err)
        });
    };

    const salvar = () => {
        let dadosSalvar = { ...dados }; //faz alterações antes de enviar para salvar
        if (dadosSalvar.hoteldatacheckin) {
            dadosSalvar.hoteldatacheckin = dataMysql(dadosSalvar.hoteldatacheckin);
        }
        if (dadosSalvar.hoteldatacheckout) {
            dadosSalvar.hoteldatacheckout = dataMysql(dadosSalvar.hoteldatacheckout);
        }
        if (dadosSalvar.datanascimento) {
            dadosSalvar.datanascimento = dataMysql(dadosSalvar.datanascimento);
        }
        if (dadosSalvar.dataopcaoaereo) {
            dadosSalvar.dataopcaoaereo = dataMysql(dadosSalvar.dataopcaoaereo);
        }
        if (dadosSalvar.datacartainformativa) {
            dadosSalvar.datacartainformativa = dataHoraMysql(dadosSalvar.datacartainformativa + ' ' + dadosSalvar.horacartainformativa);
        }
        if (dadosSalvar.dataconfirmacaocartainformativa) {
            dadosSalvar.dataconfirmacaocartainformativa = dataMysql(dadosSalvar.dataconfirmacaocartainformativa);
        }
        if (dadosSalvar.datacartaconvite) {
            dadosSalvar.datacartaconvite = dataHoraMysql(dadosSalvar.datacartaconvite + ' ' + dadosSalvar.horacartaconvite);
        }
        if (dadosSalvar.datacartaassinada) {
            dadosSalvar.datacartaassinada = dataMysql(dadosSalvar.datacartaassinada);
        }
        if (dadosSalvar.dataconfirmacaodados) {
            dadosSalvar.dataconfirmacaodados = dataMysql(dadosSalvar.dataconfirmacaodados);
        }
        if (dadosSalvar.dataescolhaaereo) {
            dadosSalvar.dataescolhaaereo = dataHoraMysql(dadosSalvar.dataescolhaaereo + ' ' + dadosSalvar.horaescolhaaereo);
        }
        if (dadosSalvar.datainscricao) {
            dadosSalvar.datainscricao = dataMysql(dadosSalvar.datainscricao);
        }

        delete dadosSalvar.situacaoevento;

        if (id) {//update
            delete dadosSalvar.evento; //ja tem evento nao precisa alterar
            api({
                method: 'put',
                action: '/participante/' + id,
                token: token,
                data: dadosSalvar,
            })
                .then(function (response) {
                    toast.success("As alterações foram salvas com sucesso!");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/participante',
                token: token,
                data: dadosSalvar
            })
                .then(function (response) {
                    history.push("/eventoParticipante/" + evento);
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to={"/cadastroEventoRsvpOpcaoAereo/" + dados.evento?.id + "?idParticipante=" + id} icon={<FaPlane size={20} />} color="outline-dark">Opções de Aéreo</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Evento: {dados.evento?.nome}</h3>
                    <h3>Participante</h3>
                </div>
                <div className="row">
                    <div style={{ marginBottom: "10px" }} className="col-sm-12 d-flex justify-content-end">
                        <IconButton
                            small={true}
                            icon={<RiMailSendLine size={20} />}
                            onClick={() => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Contrato?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarContrato',
                                                token: token,
                                                data: {
                                                    idParticipantes: id
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' contratos enviados com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                            }
                            color="primary"> Enviar Contrato</IconButton >
                        <div style={{ width: "20px" }}></div>
                        <FormButton
                            small={true}
                            method="post"
                            action={process.env.REACT_APP_API_URL + "/eventoCarta/visualizarCarta"}
                            icon={<FaEye size={20} />}
                            color="primary"
                            data={{
                                token: token,
                                tipoCarta: "modelocartaconvite",
                                idParticipantes: id //exemplo: "234523452345,324532453,23452345"
                            }}
                        >Visualizar Convite</FormButton>
                        <div style={{ width: "5px" }}></div>
                        <IconButton
                            small={true}
                            icon={<RiMailSendLine size={20} />}
                            onClick={() => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Carta Convite?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarCartaConvite',
                                                token: token,
                                                data: {
                                                    idParticipantes: id
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' cartas enviadas com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                            }
                            color="primary"> Enviar Convite</IconButton >
                        <div style={{ width: "20px" }}></div>
                        <FormButton
                            small={true}
                            method="post"
                            action={process.env.REACT_APP_API_URL + "/eventoCarta/visualizarCarta"}
                            icon={<FaEye size={20} />}
                            color="primary"
                            data={{
                                token: localStorage.getItem('token'),
                                tipoCarta: "modelocarta",
                                idParticipantes: id //exemplo: "234523452345"
                            }}
                        >Visualizar Informativa</FormButton>
                        <div style={{ width: "5px" }}></div>
                        <IconButton
                            small={true}
                            icon={<RiMailSendLine size={20} />}
                            onClick={() => {
                                Alert({
                                    icon: "question",
                                    confirmButton: <><FaThumbsUp size={20} /> Enviar</>,
                                    denyButton: <><FaThumbsDown size={20} /> Sair</>,
                                    title: "Enviar Carta Informativa?",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isConfirmed) {
                                            api({
                                                method: 'post',
                                                action: '/eventoCarta/enviarCartaInformativa',
                                                token: token,
                                                data: {
                                                    idParticipantes: id,
                                                }
                                            })
                                                .then(function (response) {
                                                    if (response.data.data.quantidadeSucesso > 0) {
                                                        toast.success(response.data.data.quantidadeSucesso + ' cartas enviadas com sucesso');
                                                    }
                                                    response.data.data.erros.forEach(function (erro) {
                                                        toast.error(erro.mensagem);
                                                    });
                                                })
                                                .catch(function (error) {
                                                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                });
                                        }
                                    }
                                });
                            }
                            }
                            color="primary"> Enviar Informativa</IconButton>
                    </div>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                {dados.datacartaconvite ?
                                                    <span title={"Carta convite enviada em: " + (dados.datacartaconvite ?? "") + ' ' + (dados.horacartaconvite ?? "")} className="badge bg-info">Carta convite enviada em: {(dados.datacartaconvite ?? "") + ' ' + (dados.horacartaconvite ?? "")}</span>
                                                    : null}
                                                {dados.datacartaassinada ?
                                                    <span title={"Carta convite confirmada em: " + (dados.datacartaassinada ?? "")} className="badge bg-success">Carta convite confirmada em: {(dados.datacartaassinada ?? "")}</span>
                                                    : null}
                                                {dados.datacartainformativa ?
                                                    <span title={"Carta informativa enviada em: " + (dados.datacartainformativa ?? "") + ' ' + (dados.horacartainformativa ?? "")} className="badge bg-info">Carta informativa enviada em: {(dados.datacartainformativa ?? "") + ' ' + (dados.horacartainformativa ?? "")}</span>
                                                    : null}
                                                {dados.dataconfirmacaocartainformativa ?
                                                    <span title={"Carta informativa confirmada em: " + (dados.dataconfirmacaocartainformativa ?? "")} className="badge bg-success">Carta informativa confirmada em: {(dados.dataconfirmacaocartainformativa ?? "")}</span>
                                                    : null}
                                            </div>
                                        </div>

                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <Tabs className="justify-content-end" defaultActiveKey="geral">
                                                <Tab eventKey="geral" title="Geral">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="codigo" placeholder="Código"
                                                                    value={dados.codigo}
                                                                    onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Código</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="999.999.999-99" className="form-control form-control-lg" id="cpf" placeholder="CPF"
                                                                    value={dados.cpf}
                                                                    onChange={e => setDados({ ...dados, ...{ "cpf": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">CPF</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="rg" placeholder="RG"
                                                                    value={dados.rg}
                                                                    onChange={e => setDados({ ...dados, ...{ "rg": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">RG</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="identificacao" placeholder="Identificação Laboratório"
                                                                    value={dados.identificacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "identificacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Identificação Laboratório</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                    value={dados.nome}
                                                                    onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="contato" placeholder="Nome para o Crachá"
                                                                    value={dados.contato}
                                                                    onChange={e => setDados({ ...dados, ...{ "contato": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome para o Crachá</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="telefone" placeholder="Telefone"
                                                                    value={dados.telefone}
                                                                    onChange={e => setDados({ ...dados, ...{ "telefone": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="telefone2" placeholder="Telefone 2"
                                                                    value={dados.telefone2}
                                                                    onChange={e => setDados({ ...dados, ...{ "telefone2": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone 2</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="(99) 99999-9999" className="form-control form-control-lg" id="celular" placeholder="Celular"
                                                                    value={dados.celular}
                                                                    onChange={e => setDados({ ...dados, ...{ "celular": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Celular</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="email" placeholder="E-mail"
                                                                    value={dados.email}
                                                                    onChange={e => setDados({ ...dados, ...{ "email": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">E-mail</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="emailsecundario" placeholder="Outros E-mails"
                                                                    value={dados.emailsecundario}
                                                                    onChange={e => setDados({ ...dados, ...{ "emailsecundario": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Outros E-mails</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="especialidade" placeholder="Especialidade"
                                                                    value={dados.especialidade}
                                                                    onChange={e => setDados({ ...dados, ...{ "especialidade": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Especialidade</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="crm" placeholder="CRM"
                                                                    value={dados.crm}
                                                                    onChange={e => setDados({ ...dados, ...{ "crm": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">CRM</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Estado do CRM</label>
                                                                <ReactSelect
                                                                    id={"crmuf"}
                                                                    value={{ value: dados.crmuf, label: dados.crmuf }}
                                                                    onChange={e => setDados({ ...dados, ...{ "crmuf": e.value, "nomeUf": e.label } })}
                                                                    options={[
                                                                        { value: "AC", label: "AC" },
                                                                        { value: "AL", label: "AL" },
                                                                        { value: "AP", label: "AP" },
                                                                        { value: "AM", label: "AM" },
                                                                        { value: "BA", label: "BA" },
                                                                        { value: "CE", label: "CE" },
                                                                        { value: "DF", label: "DF" },
                                                                        { value: "ES", label: "ES" },
                                                                        { value: "GO", label: "GO" },
                                                                        { value: "MA", label: "MA" },
                                                                        { value: "MT", label: "MT" },
                                                                        { value: "MS", label: "MS" },
                                                                        { value: "MG", label: "MG" },
                                                                        { value: "PA", label: "PA" },
                                                                        { value: "PB", label: "PB" },
                                                                        { value: "PR", label: "PR" },
                                                                        { value: "PE", label: "PE" },
                                                                        { value: "PI", label: "PI" },
                                                                        { value: "RJ", label: "RJ" },
                                                                        { value: "RN", label: "RN" },
                                                                        { value: "RS", label: "RS" },
                                                                        { value: "RO", label: "RO" },
                                                                        { value: "RR", label: "RR" },
                                                                        { value: "SC", label: "SC" },
                                                                        { value: "SP", label: "SP" },
                                                                        { value: "SE", label: "SE" },
                                                                        { value: "TO", label: "TO" },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="instituicao" placeholder="Instituição"
                                                                    value={dados.instituicao}
                                                                    onChange={e => setDados({ ...dados, ...{ "instituicao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Instituição</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="passaporte" placeholder="Passaporte"
                                                                    value={dados.passaporte}
                                                                    onChange={e => setDados({ ...dados, ...{ "passaporte": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Passaporte</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="passaportevencimento" placeholder="Vencimento do Passaporte"
                                                                    value={dados.passaportevencimento}
                                                                    onChange={e => setDados({ ...dados, ...{ "passaportevencimento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Vencimento do Passaporte</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                id={"datanascimento"}
                                                                label="Data de Nascimento"
                                                                value={dados.datanascimento}
                                                                onChange={date => setDados({ ...dados, ...{ "datanascimento": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="produto" placeholder="Produto / Linha"
                                                                    value={dados.produto}
                                                                    onChange={e => setDados({ ...dados, ...{ "produto": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Produto / Linha</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="centrodecusto" placeholder="Centro de Custo"
                                                                    value={dados.centrodecusto}
                                                                    onChange={e => setDados({ ...dados, ...{ "centrodecusto": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Centro de Custo</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="ordeminterna" placeholder="Ordem Interna"
                                                                    value={dados.ordeminterna}
                                                                    onChange={e => setDados({ ...dados, ...{ "ordeminterna": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Ordem Interna</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="gerentecomercial" placeholder="Gerente Comercial"
                                                                    value={dados.gerentecomercial}
                                                                    onChange={e => setDados({ ...dados, ...{ "gerentecomercial": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Gerente Comercial</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="gerentedistrital" placeholder="Gerente Distrital"
                                                                    value={dados.gerentedistrital}
                                                                    onChange={e => setDados({ ...dados, ...{ "gerentedistrital": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Gerente Distrital</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="gerentedistritalemail" placeholder="E-mail Gerente Distrital"
                                                                    value={dados.gerentedistritalemail}
                                                                    onChange={e => setDados({ ...dados, ...{ "gerentedistritalemail": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">E-mail Gerente Distrital</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="representante" placeholder="Representante"
                                                                    value={dados.representante}
                                                                    onChange={e => setDados({ ...dados, ...{ "representante": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Representante</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="representanteemail" placeholder="E-mail Representante"
                                                                    value={dados.representanteemail}
                                                                    onChange={e => setDados({ ...dados, ...{ "representanteemail": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">E-mail Representante</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="regional" placeholder="Regional / Região"
                                                                    value={dados.regional}
                                                                    onChange={e => setDados({ ...dados, ...{ "regional": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Regional / Região</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Presente no Evento</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.presentenoevento, label: dados.nomePresentenoevento }}
                                                                    onChange={e => setDados({ ...dados, ...{ "presentenoevento": e.value, "nomePresentenoevento": e.label } })}
                                                                    options={[
                                                                        { value: 'nulo', label: '' },
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="socio" placeholder="Sócio"
                                                                    value={dados.socio}
                                                                    onChange={e => setDados({ ...dados, ...{ "socio": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Sócio</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Tipo do Participante</label>
                                                                <ReactSelect
                                                                    id="tipoparticipante"
                                                                    value={{ value: dados.tipoparticipante, label: dados.nomeTipoparticipante }}
                                                                    onChange={e => setDados({ ...dados, ...{ "tipoparticipante": e.value, "nomeTipoparticipante": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participanteTipo',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="restricaoalimentar" placeholder="Restrição Alimentar"
                                                                    value={dados.restricaoalimentar}
                                                                    onChange={e => setDados({ ...dados, ...{ "restricaoalimentar": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Restrição Alimentar</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="pcd" placeholder="Restrição Alimentar"
                                                                    value={dados.pcd}
                                                                    onChange={e => setDados({ ...dados, ...{ "pcd": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">PCD</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="idconvidado" placeholder="ID"
                                                                    value={dados.idconvidado}
                                                                    onChange={(e) => {
                                                                        dados.idconvidado = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">ID</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Data
                                                                        id={"datacartaconvite"}
                                                                        label="Data Carta Convite"
                                                                        value={dados.datacartaconvite}
                                                                        onChange={date => setDados({ ...dados, ...{ "datacartaconvite": date } })}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-floating form-group">
                                                                        <InputMask
                                                                            mask="99:99"
                                                                            className="form-control form-control-lg campoHora"
                                                                            id="horacartaconvite"
                                                                            placeholder="Hora Carta Convite"
                                                                            value={dados.horacartaconvite}
                                                                            onChange={e => setDados({ ...dados, ...{ "horacartaconvite": e.target.value } })}
                                                                        />
                                                                        <label htmlFor="floatingInput">Hora Carta Convite</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                id={"datacartaassinada"}
                                                                label="Data Aceite Convite"
                                                                value={dados.datacartaassinada}
                                                                onChange={date => setDados({ ...dados, ...{ "datacartaassinada": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                id={"dataopcaoaereo"}
                                                                label="Data Opção de Aéreo"
                                                                value={dados.dataopcaoaereo}
                                                                onChange={date => setDados({ ...dados, ...{ "dataopcaoaereo": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                id={"dataconfirmacaodados"}
                                                                label="Data Confirmação Dados"
                                                                value={dados.dataconfirmacaodados}
                                                                onChange={date => setDados({ ...dados, ...{ "dataconfirmacaodados": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Data
                                                                        id={"dataescolhaaereo"}
                                                                        label="Data Escolha Aéreo"
                                                                        value={dados.dataescolhaaereo}
                                                                        onChange={date => setDados({ ...dados, ...{ "dataescolhaaereo": date } })}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-floating form-group">
                                                                        <InputMask
                                                                            mask="99:99"
                                                                            className="form-control form-control-lg campoHora"
                                                                            id="horaescolhaaereo"
                                                                            placeholder="Hora Escolha Aéreo"
                                                                            value={dados.horaescolhaaereo}
                                                                            onChange={e => setDados({ ...dados, ...{ "horaescolhaaereo": e.target.value } })}
                                                                        />
                                                                        <label htmlFor="floatingInput">Hora Escolha Aéreo</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                id={"datainscricao"}
                                                                label="Data Inscrição"
                                                                value={dados.datainscricao}
                                                                onChange={date => setDados({ ...dados, ...{ "datainscricao": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Data
                                                                        id={"datacartainformativa"}
                                                                        label="Data Carta Informativa"
                                                                        value={dados.datacartainformativa}
                                                                        onChange={date => setDados({ ...dados, ...{ "datacartainformativa": date } })}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-floating form-group">
                                                                        <InputMask
                                                                            mask="99:99"
                                                                            className="form-control form-control-lg campoHora"
                                                                            id="horacartainformativa"
                                                                            placeholder="Hora Carta Informativa"
                                                                            value={dados.horacartainformativa}
                                                                            onChange={e => setDados({ ...dados, ...{ "horacartainformativa": e.target.value } })}
                                                                        />
                                                                        <label htmlFor="floatingInput">Hora Carta Informativa</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <Data
                                                                id={"dataconfirmacaocartainformativa"}
                                                                label="Data Confirmação Informativa"
                                                                value={dados.dataconfirmacaocartainformativa}
                                                                onChange={date => setDados({ ...dados, ...{ "dataconfirmacaocartainformativa": date } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="colunasnomeaveis" title="Colunas Nomeáveis">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna1" placeholder={dados.nomecoluna1}
                                                                    value={dados.coluna1}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna1": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna1}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna2" placeholder={dados.nomecoluna2}
                                                                    value={dados.coluna2}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna2": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna2}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna3" placeholder={dados.nomecoluna3}
                                                                    value={dados.coluna3}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna3": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna3}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna4" placeholder={dados.nomecoluna4}
                                                                    value={dados.coluna4}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna4": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna4}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna5" placeholder={dados.nomecoluna5}
                                                                    value={dados.coluna5}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna5": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna5}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna6" placeholder={dados.nomecoluna6}
                                                                    value={dados.coluna6}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna6": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna6}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna7" placeholder={dados.nomecoluna7}
                                                                    value={dados.coluna7}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna7": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna7}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna8" placeholder={dados.nomecoluna8}
                                                                    value={dados.coluna8}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna8": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna8}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna9" placeholder={dados.nomecoluna9}
                                                                    value={dados.coluna9}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna9": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna9}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="coluna10" placeholder={dados.nomecoluna10}
                                                                    value={dados.coluna10}
                                                                    onChange={e => setDados({ ...dados, ...{ "coluna10": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">{dados.nomecoluna10}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="endereco" title="Endereço">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecologradouro" placeholder="Rua / Logradouro"
                                                                    value={dados.enderecologradouro}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecologradouro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Rua / Logradouro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="endereconumero" placeholder="Número"
                                                                    value={dados.endereconumero}
                                                                    onChange={e => setDados({ ...dados, ...{ "endereconumero": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Número</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecocomplemento" placeholder="Complemento"
                                                                    value={dados.enderecocomplemento}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecocomplemento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Complemento</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecocep" placeholder="CEP"
                                                                    value={dados.enderecocep}
                                                                    onChange={(e) => {
                                                                        dados.enderecocep = e.target.value;
                                                                        preencherCep(e.target.value, dados);
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">CEP</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecobairro" placeholder="Bairro"
                                                                    value={dados.enderecobairro}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecobairro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Bairro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Estado</label>
                                                                <ReactSelect
                                                                    id={"enderecouf"}
                                                                    value={{ value: dados.enderecouf, label: dados.enderecouf }}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecouf": e.value, "nomeUf": e.label } })}
                                                                    options={[
                                                                        { value: "AC", label: "AC" },
                                                                        { value: "AL", label: "AL" },
                                                                        { value: "AP", label: "AP" },
                                                                        { value: "AM", label: "AM" },
                                                                        { value: "BA", label: "BA" },
                                                                        { value: "CE", label: "CE" },
                                                                        { value: "DF", label: "DF" },
                                                                        { value: "ES", label: "ES" },
                                                                        { value: "GO", label: "GO" },
                                                                        { value: "MA", label: "MA" },
                                                                        { value: "MT", label: "MT" },
                                                                        { value: "MS", label: "MS" },
                                                                        { value: "MG", label: "MG" },
                                                                        { value: "PA", label: "PA" },
                                                                        { value: "PB", label: "PB" },
                                                                        { value: "PR", label: "PR" },
                                                                        { value: "PE", label: "PE" },
                                                                        { value: "PI", label: "PI" },
                                                                        { value: "RJ", label: "RJ" },
                                                                        { value: "RN", label: "RN" },
                                                                        { value: "RS", label: "RS" },
                                                                        { value: "RO", label: "RO" },
                                                                        { value: "RR", label: "RR" },
                                                                        { value: "SC", label: "SC" },
                                                                        { value: "SP", label: "SP" },
                                                                        { value: "SE", label: "SE" },
                                                                        { value: "TO", label: "TO" },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Cidade</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.enderecomunicipio, label: dados.nomeEnderecomunicipio }}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecomunicipio": e.value, "nomeEnderecomunicipio": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/municipio',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                uf: dados.enderecouf ?? null
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>País</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.enderecopais, label: dados.nomeEnderecopais }}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecopais": e.value, "nomeEnderecopais": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/pais',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="hotel" title={<><input type="checkbox" readOnly checked={dados.hotelhospedagem === 'sim' ? true : false}></input> Hospedagem</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Hospedagem?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.hotelhospedagem, label: dados.nomeHotelhospedagem }}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelhospedagem": e.value, "nomeHotelhospedagem": e.label } })}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="hotelnome" placeholder="Hotel"
                                                                    value={dados.hotelnome}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelnome": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Hotel</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="hoteltipoquarto" placeholder="Tipo de Quarto"
                                                                    value={dados.hoteltipoquarto}
                                                                    onChange={e => setDados({ ...dados, ...{ "hoteltipoquarto": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Tipo de Quarto</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="hotellogradouro" placeholder="Rua/Logradouro"
                                                                    value={dados.hotellogradouro}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotellogradouro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Rua/Logradouro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="hotelbairro" placeholder="Bairro"
                                                                    value={dados.hotelbairro}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelbairro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Bairro</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Cidade</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.hotelmunicipio, label: dados.nomeHotelmunicipio }}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelmunicipio": e.value, "nomeHotelmunicipio": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/municipio',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="hoteltelefone" placeholder="Telefone"
                                                                    value={dados.hoteltelefone}
                                                                    onChange={e => setDados({ ...dados, ...{ "hoteltelefone": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"hoteldatacheckin"}
                                                                label="Data do checkin"
                                                                value={dados.hoteldatacheckin}
                                                                onChange={date => setDados({ ...dados, ...{ "hoteldatacheckin": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="hotelhoracheckin" placeholder="Hora do checkin"
                                                                    value={dados.hotelhoracheckin}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelhoracheckin": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Hora do checkin</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                id={"hoteldatacheckout"}
                                                                label="Data do checkout"
                                                                value={dados.hoteldatacheckout}
                                                                onChange={date => setDados({ ...dados, ...{ "hoteldatacheckout": date } })}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="99:99" className="form-control form-control-lg" id="hotelhoracheckout" placeholder="Hora do checkout"
                                                                    value={dados.hotelhoracheckout}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelhoracheckout": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Hora do checkout</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="hotelobservacao"
                                                                    placeholder="Observação"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.hotelobservacao ?? ""}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelobservacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Observação</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="aereo" title={<><input type="checkbox" readOnly checked={dados.aereo === 'sim' ? true : false}></input> Aéreo</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Aéreo?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.aereo, label: dados.nomeAereo }}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereo": e.value, "nomeAereo": e.label } })}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Bagagem Despachada?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.bagagemDespachada, label: dados.nomeBagagemDespachada }}
                                                                    onChange={e => setDados({ ...dados, ...{ "bagagemDespachada": e.value, "nomeBagagemDespachada": e.label } })}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <h4>Dados Ida <FaPlaneDeparture size={20} /></h4>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidadata" placeholder="Data"
                                                                    value={dados.aereoidadata}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidadata": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Data</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidaorigem" placeholder="Origem"
                                                                    value={dados.aereoidaorigem}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidaorigem": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Origem</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidacompanhia" placeholder="Companhia Aérea"
                                                                    value={dados.aereoidacompanhia}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidacompanhia": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Companhia Aérea</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidavoo" placeholder="Vôo"
                                                                    value={dados.aereoidavoo}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidavoo": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Vôo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidahorasaida" placeholder="Hora Saída"
                                                                    value={dados.aereoidahorasaida}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidahorasaida": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Saída</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidahorachegada" placeholder="Hora Chegada"
                                                                    value={dados.aereoidahorachegada}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidahorachegada": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Chegada</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidadestino" placeholder="Destino"
                                                                    value={dados.aereoidadestino}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidadestino": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Destino</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidalocalizador" placeholder="Localizador"
                                                                    value={dados.aereoidalocalizador}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidalocalizador": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Localizador</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidaeticket" placeholder="E-ticket"
                                                                    value={dados.aereoidaeticket}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidaeticket": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">E-ticket</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoidaconexao" placeholder="Conexão"
                                                                    value={dados.aereoidaconexao}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidaconexao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Conexão</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label>Cidade Embarque</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.aereomunicipioembarque, label: dados.nomeAereomunicipioembarque }}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereomunicipioembarque": e.value, "nomeAereomunicipioembarque": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/municipio',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                uf: dados.enderecouf ?? null
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aeroportoOrigem" placeholder="Aeroporto de Origem"
                                                                    value={dados.aeroportoOrigem}
                                                                    onChange={e => setDados({ ...dados, ...{ "aeroportoOrigem": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Aeroporto de Origem</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="aereoidaobservacao"
                                                                    placeholder="Observação Ida"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.aereoidaobservacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoidaobservacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Ida</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <h4>Dados Retorno <FaPlaneArrival size={20} /></h4>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornodata" placeholder="Data"
                                                                    value={dados.aereoretornodata}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornodata": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Data</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornoorigem" placeholder="Origem"
                                                                    value={dados.aereoretornoorigem}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornoorigem": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Origem</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornocompanhia" placeholder="Companhia Aérea"
                                                                    value={dados.aereoretornocompanhia}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornocompanhia": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Companhia Aérea</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornovoo" placeholder="Vôo"
                                                                    value={dados.aereoretornovoo}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornovoo": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Vôo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornohorasaida" placeholder="Hora Saída"
                                                                    value={dados.aereoretornohorasaida}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornohorasaida": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Saída</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornohorachegada" placeholder="Hora Chegada"
                                                                    value={dados.aereoretornohorachegada}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornohorachegada": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Hora Chegada</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornodestino" placeholder="Destino"
                                                                    value={dados.aereoretornodestino}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornodestino": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Destino</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornolocalizador" placeholder="Localizador"
                                                                    value={dados.aereoretornolocalizador}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornolocalizador": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Localizador</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornoeticket" placeholder="E-ticket"
                                                                    value={dados.aereoretornoeticket}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornoeticket": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">E-ticket</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="aereoretornoconexao" placeholder="Conexão"
                                                                    value={dados.aereoretornoconexao}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornoconexao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Conexão</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="aereoretornoobservacao"
                                                                    placeholder="Observação"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.aereoretornoobservacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "aereoretornoobservacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Retorno</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <h4>Opções de aéreo disponíveis</h4>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body" style={{ padding: "0.5rem" }}>
                                                                <h4>Ida <FaPlaneDeparture size={20} /></h4>
                                                                <div className="table-responsive">
                                                                    <table className='table mb-0'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Data</th>
                                                                                <th>Cia</th>
                                                                                <th>Voo</th>
                                                                                <th>Origem</th>
                                                                                <th>Destino</th>
                                                                                <th>Saida</th>
                                                                                <th>Chegada</th>
                                                                            </tr>
                                                                            {dados.evento?.eventoaereo ? dados.evento.eventoaereo.map((row, rowIndex) => {
                                                                                return [
                                                                                    <>
                                                                                        <tr>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idadata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idacompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idavoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idaorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idadestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idahorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].idahorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ];
                                                                            }) : null}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body" style={{ padding: "0.5rem" }}>
                                                                <h4>Volta <FaPlaneArrival size={20} /></h4>
                                                                <div className="table-responsive">
                                                                    <table className='table mb-0'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Data</th>
                                                                                <th>Cia</th>
                                                                                <th>Voo</th>
                                                                                <th>Origem</th>
                                                                                <th>Destino</th>
                                                                                <th>Saida</th>
                                                                                <th>Chegada</th>
                                                                            </tr>
                                                                            {dados.evento?.eventoaereo ? dados.evento.eventoaereo.map((row, rowIndex) => {
                                                                                return [
                                                                                    <>
                                                                                        <tr>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornodata ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornocompanhia ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornovoo ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornoorigem ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornodestino ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornohorasaida ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                            <td>{(dados.evento.eventoaereo[rowIndex].retornohorachegada ?? '').split("|").map((valor) => { return (<>{valor}<br /></>); })}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ];
                                                                            }) : null}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="inscricao" title={<><input type="checkbox" readOnly checked={dados.inscricao === 'sim' ? true : false}></input> Inscrição</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Inscrição?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.inscricao, label: dados.nomeInscricao }}
                                                                    onChange={e => setDados({ ...dados, ...{ "inscricao": e.value, "nomeInscricao": e.label } })}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="inscricaonumero" placeholder="Número de Inscrição"
                                                                    value={dados.inscricaonumero}
                                                                    onChange={e => setDados({ ...dados, ...{ "inscricaonumero": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Número de Inscrição</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="transfer" title={<><input type="checkbox" readOnly checked={dados.transfer === 'sim' ? true : false}></input> Transfer</>}>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Transfer?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.transfer, label: dados.nomeTransfer }}
                                                                    onChange={e => setDados({ ...dados, ...{ "transfer": e.value, "nomeTransfer": e.label } })}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="transferobservacao"
                                                                    placeholder="Observação Transfer"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.transferobservacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "transferobservacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Transfer</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Possui Transfer Extra?</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.transferextra, label: dados.nomeTransferextra }}
                                                                    onChange={(e) => {
                                                                        dados.transferextra = e.value;
                                                                        dados.nomeTransferextra = e.label;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' },
                                                                        { value: 'cld', label: 'Cancelado' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="transferextraobservacao"
                                                                    placeholder="Observação Transfer Extra"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.transferextraobservacao}
                                                                    onChange={(e) => {
                                                                        dados.transferextraobservacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observação Transfer Extra</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="observacao" title="Observações Importantes">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="observacao"
                                                                    placeholder="Observações Importantes"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.observacao}
                                                                    onChange={(e) => {
                                                                        dados.observacao = e.target.value;
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Observações Importantes</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            <div className="row fixoRodape">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <h4>Dados de Histórico</h4>
                                            <form className="form" onSubmit={(e) => {
                                                //valida se tem status
                                                var modalsituacao = document.querySelector("#modalsituacao");
                                                if (dadosModalHistorico.situacao === undefined || dadosModalHistorico.situacao === null || dadosModalHistorico.situacao === '') {
                                                    toast.error('É necessário preencher o status');
                                                    setTimeout(() => {
                                                        modalsituacao.focus();
                                                    }, 500);
                                                    e.preventDefault();
                                                    return false;
                                                }

                                                //valida se tem data e hora da situação
                                                var campodatanotificacao = document.querySelector("#datanotificacao");
                                                var campohoranotificacao = document.querySelector("#horanotificacao");
                                                if (dadosModalHistorico.pedirdata === "sim") {
                                                    //if (dadosModalHistorico.datanotificacao === undefined || dadosModalHistorico.datanotificacao === null || dadosModalHistorico.datanotificacao === '') {
                                                    //    toast.error('É necessário preencher a data');
                                                    //    setTimeout(() => {
                                                    //        campodatanotificacao.focus();
                                                    //    }, 500);
                                                    //    e.preventDefault();
                                                    //    return false;
                                                    //}

                                                    if (dadosModalHistorico.datanotificacao === undefined || dadosModalHistorico.datanotificacao === null || dadosModalHistorico.datanotificacao === '') {
                                                    } else {
                                                        if (dadosModalHistorico.horanotificacao === undefined || dadosModalHistorico.horanotificacao === null || dadosModalHistorico.horanotificacao === '') {
                                                            toast.error('É necessário preencher a hora');
                                                            setTimeout(() => {
                                                                campohoranotificacao.focus();
                                                            }, 500);
                                                            e.preventDefault();
                                                            return false;
                                                        }
                                                    }
                                                }

                                                var datanotificacao = null;
                                                var horanotificacao = null;
                                                if (dadosModalHistorico.datanotificacao !== undefined && dadosModalHistorico.datanotificacao !== null && dadosModalHistorico.datanotificacao !== '') {
                                                    datanotificacao = dataMysql(dadosModalHistorico.datanotificacao);
                                                    if (dadosModalHistorico.horanotificacao !== undefined && dadosModalHistorico.horanotificacao !== null && dadosModalHistorico.horanotificacao !== '') {
                                                        horanotificacao = dadosModalHistorico.horanotificacao;
                                                    }
                                                }

                                                //tem observação
                                                api({
                                                    method: 'post',
                                                    action: '/participanteHistoricoSituacao',
                                                    token: token,
                                                    data: {
                                                        evento: dados.evento?.id,
                                                        participante: id,
                                                        situacao: dadosModalHistorico.situacao,
                                                        historico: dadosModalHistorico.historico,
                                                        datanotificacao: datanotificacao,
                                                        horanotificacao: horanotificacao,
                                                    }
                                                })
                                                    .then(function (response) {
                                                        //limpa campos
                                                        dadosModalHistorico.pedirdata = "nao";
                                                        dadosModalHistorico.datanotificacao = null;
                                                        dadosModalHistorico.horanotificacao = '';
                                                        dadosModalHistorico.historico = '';
                                                        dadosModalHistorico.situacao = null;
                                                        dadosModalHistorico.nomeSituacao = null;
                                                        setDadosModalHistorico({ ...dadosModalHistorico });
                                                        buscarHistorico(id);
                                                        toast.success("Registrado com sucesso!");
                                                    })
                                                    .catch(function (error) {
                                                        toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                    });
                                                e.preventDefault();
                                            }}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Status</label>
                                                            <ReactSelect
                                                                id="modalsituacao"
                                                                value={{ value: dadosModalHistorico.situacao, label: dadosModalHistorico.nomeSituacao, pedirdata: dadosModalHistorico.pedirdata }}
                                                                onChange={(e) => {
                                                                    dadosModalHistorico.situacao = e.value;
                                                                    dadosModalHistorico.nomeSituacao = e.label;
                                                                    dadosModalHistorico.pedirdata = e.pedirdata;
                                                                    setDadosModalHistorico({ ...dadosModalHistorico });
                                                                }}
                                                                loadOptions={(input) => {
                                                                    return api({
                                                                        method: 'get',
                                                                        action: '/eventoParticipanteSituacao',
                                                                        token: token,
                                                                        params: {
                                                                            situacaoRegistro: "ativo",
                                                                            nome: input
                                                                        }
                                                                    })
                                                                        .then(response => {
                                                                            let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome, pedirdata: registro.pedirdata }));
                                                                            return options;
                                                                        })
                                                                        .catch(function (error) {
                                                                            //console.log(error);
                                                                        });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {(dadosModalHistorico.pedirdata === "sim") ?
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <Data
                                                                title="Opcional"
                                                                id={"datanotificacao"}
                                                                label="Data"
                                                                value={dadosModalHistorico.datanotificacao ?? ""}
                                                                onChange={(date) => {
                                                                    dadosModalHistorico.datanotificacao = date;
                                                                    setDadosModalHistorico({ ...dadosModalHistorico });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-floating form-group">
                                                                <InputMask
                                                                    title="Opcional"
                                                                    mask="99:99"
                                                                    className="form-control form-control-lg"
                                                                    id="horanotificacao"
                                                                    placeholder="Hora"
                                                                    value={dadosModalHistorico.horanotificacao ?? ""}
                                                                    onChange={(e) => {
                                                                        dadosModalHistorico.horanotificacao = e.target.value;
                                                                        setDadosModalHistorico({ ...dadosModalHistorico });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">Hora</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-floating form-group">
                                                            <textarea
                                                                className="form-control form-control-lg"
                                                                id="modalhistorico"
                                                                placeholder="Observação"
                                                                style={{ minHeight: "100px" }}
                                                                value={dadosModalHistorico.historico}
                                                                onChange={(e) => {
                                                                    dadosModalHistorico.historico = e.target.value;
                                                                    setDadosModalHistorico({ ...dadosModalHistorico });
                                                                }}
                                                            />
                                                            <label htmlFor="floatingInput">Observação</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 d-flex justify-content-end">
                                                        <button type="submit" className="btn btn-primary me-1 mb-1">
                                                            <FaSave size={20} /> Criar Observação</button>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="table-responsive">
                                                <DataTable
                                                    noContextMenu="true"
                                                    actions={tableActions}
                                                    selectableRows="false"
                                                    selectableRowsHighlight="true"
                                                    onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                        setTableSelect({ allSelected, selectedCount, selectedRows });
                                                    }}
                                                    noDataComponent="Nenhum registro"
                                                    paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                                    pagination="true"
                                                    className="table mb-0"
                                                    columns={[
                                                        {
                                                            name: 'Operador',
                                                            selector: (row) => {
                                                                return row.operador?.nome ?? "";
                                                            },
                                                            cell: (row) => {
                                                                return row.operador?.nome ?? "";
                                                            },
                                                            width: "200px",
                                                        },
                                                        {
                                                            name: 'Data',
                                                            selector: (row) => {
                                                                return row.created_at ?? "";
                                                            },
                                                            width: "120px",
                                                            cell: (row) => {
                                                                if (row.created_at) {
                                                                    return moment(row.created_at).format("DD/MM/YYYY HH:mm");
                                                                } else {
                                                                    return "";
                                                                }
                                                            },
                                                        },
                                                        {
                                                            name: 'Status',
                                                            selector: (row) => {
                                                                return row.situacao?.nome ?? "";
                                                            },
                                                            cell: (row) => {
                                                                return row.situacao?.nome ?? "";
                                                            },
                                                            width: "200px",
                                                        },
                                                        {
                                                            name: 'Observação',
                                                            selector: (row) => {
                                                                return row.historico ?? "";
                                                            },
                                                            cell: (row) => {
                                                                if (row.historico) {
                                                                    return row.historico;
                                                                } else {
                                                                    return "";
                                                                }
                                                            },
                                                        },
                                                        {
                                                            cell: (row, index) => {
                                                                return <ModalButton
                                                                    title="Alterar"
                                                                    icon={<FaEdit size={20} />}
                                                                    color="primary"
                                                                    onHide={() => {
                                                                        api({
                                                                            method: 'put',
                                                                            action: '/participanteHistoricoSituacao/' + row.id,
                                                                            token: token,
                                                                            data: {
                                                                                historico: row.historico,
                                                                            }
                                                                        })
                                                                            .then(function (response) {
                                                                                toast.success('Dados registrados com sucesso');
                                                                            })
                                                                            .catch(function (error) {
                                                                                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                                                            });
                                                                    }}
                                                                >
                                                                    <div className='row'>
                                                                        <div className='col-sm-12'>
                                                                            <div className="form-floating form-group">
                                                                                <input type="text" className="form-control form-control-lg" id="historico" placeholder="Observação"
                                                                                    value={row.historico}
                                                                                    onChange={(e) => {
                                                                                        historico[index].historico = e.target.value;
                                                                                        setHistorico([...historico]);
                                                                                    }}
                                                                                />
                                                                                <label htmlFor="floatingInput">Observação</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ModalButton>;
                                                            },
                                                        },
                                                    ]}
                                                    data={historico}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}