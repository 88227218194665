import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaCircle, FaCircleNotch, FaExpeditedssl, FaGgCircle, FaList, FaPlus, FaRegCircle, FaSave, FaTrash } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { rand18, data, dataMysql, extract, pontoVirgula, virgulaPonto, semValor } from '../../util/util';
import Data from '../../components/Data';
import IconButton from '../../components/IconButton';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import ModalButton from '../../components/ModalButton';

function verificaSeExiste(dados, data, index) {
    //encontra o item atual
    var resultado = false;
    dados.orcamentoitem.forEach((orcamentoItem, indexOrcamentoItem) => {
        orcamentoItem.subitem.forEach((subitem, indexEncontrado) => {
            if (subitem.chaveunica === dados.itemSomarAtual) {
                var arrayChavesSomar = subitem.chavessomar ? subitem.chavessomar.split(",") : [];
                if (arrayChavesSomar.includes(data.subitem[index].chaveunica?.toString() ?? '')) {
                    resultado = true;
                }
            }
        });
    });
    return resultado;
}

function adicionaCheck(dados, data, indexSubitemAdicionar) {
    //busca item de total atual
    dados.orcamentoitem.forEach((orcamentoItem, indexOrcamentoItem) => {
        orcamentoItem.subitem.forEach((subitem, indexSubItem) => {
            if (subitem.chaveunica === dados.itemSomarAtual) {
                if (dados.itemSomarAtual === data.subitem[indexSubitemAdicionar].chaveunica) {
                    toast.error('não é possível adicionar ele mesmo');
                } else {
                    //adiciona no chavessomar dele esse valor
                    var arrayChavesSomar = subitem.chavessomar ? subitem.chavessomar.split(",") : [];
                    arrayChavesSomar.push(data.subitem[indexSubitemAdicionar].chaveunica);//adiciona
                    dados.orcamentoitem[indexOrcamentoItem].subitem[indexSubItem].chavessomar = arrayChavesSomar.join(",");
                }
            }
        });
    });
}

function removeCheck(dados, data, indexSubitemAdicionar) {
    //busca item de total atual
    dados.orcamentoitem.forEach((orcamentoItem, indexOrcamentoItem) => {
        orcamentoItem.subitem.forEach((subitem, indexSubItem) => {
            if (subitem.chaveunica === dados.itemSomarAtual) {
                //adiciona no chavessomar dele esse valor
                var arrayChavesSomar = subitem.chavessomar ? subitem.chavessomar.split(",") : [];
                arrayChavesSomar = arrayChavesSomar.filter(item => item !== data.subitem[indexSubitemAdicionar].chaveunica.toString());//remove
                dados.orcamentoitem[indexOrcamentoItem].subitem[indexSubItem].chavessomar = arrayChavesSomar.join(",");
            }
        });
    });
}

const ExpanableComponent = ({ data, callBack, token, dados, setDados, calculaTotalOrcamento, copiaValores, copiaValoresInferior, ...props }) => {
    return <table className='table mb-0' id="table1">
        <thead>
            <tr>
                <td colSpan="4"><center>Pré Evento</center></td>
                <td colSpan="4" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                    <center>Negociado <Button
                        style={{ zoom: "0.7" }}
                        size="sm"
                        title="Copiar valores anteriores"
                        onClick={() => {
                            copiaValores("PreEvento", "Negociado", data.indexSuperior);
                            setDados({ ...dados });
                        }}>copiar</Button>
                    </center>
                </td>
                <td colSpan="4" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                    <center>Fechamento <Button
                        style={{ zoom: "0.7" }}
                        size="sm"
                        title="Copiar valores anteriores"
                        onClick={() => {
                            copiaValores("Negociado", "Fechamento", data.indexSuperior);
                            setDados({ ...dados });
                        }}>copiar</Button>
                    </center>
                </td>
            </tr>
        </thead>
        <tbody>
            {data.subitem ? data.subitem.map((row, rowIndex) => {
                return [
                    <>
                        <tr style={{ borderBottom: "1px solid rgba(0,0,0,0)" }}>
                            <td colSpan="4">
                                <div className='row'>
                                    <div className='col-sm-1' style={{ paddingLeft: "10px", paddingTop: "2px" }}>
                                        <ModalButton
                                            title="Mais Informações"
                                            icon={<FaList size={20} />}
                                            color="primary"
                                        >
                                            <div className='row'>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Fornecedor Custos</label>
                                                        <ReactSelect
                                                            placeholder={"Fornecedor"}
                                                            value={row.fornecedor !== null ? { value: row.fornecedor?.id ?? null, label: row.fornecedor?.nome ?? null } : null}
                                                            onChange={(e) => {
                                                                data.subitem[rowIndex].fornecedor = {};
                                                                data.subitem[rowIndex].fornecedor.id = e.value;
                                                                data.subitem[rowIndex].fornecedor.nome = e.label;

                                                                data.subitem[rowIndex].fornecedorpagamento = {};
                                                                data.subitem[rowIndex].fornecedorpagamento.id = e.value;
                                                                data.subitem[rowIndex].fornecedorpagamento.nome = e.label;

                                                                setDados({ ...dados });
                                                            }}
                                                            defaultOptions
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: ["orcamento", "empresa"],
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Fornecedor Pagamento</label>
                                                        <ReactSelect
                                                            placeholder={"Fornecedor Pagamento"}
                                                            value={row.fornecedorpagamento !== null ? { value: row.fornecedorpagamento?.id ?? null, label: row.fornecedorpagamento?.nome ?? null } : null}
                                                            onChange={(e) => {
                                                                data.subitem[rowIndex].fornecedorpagamento = {};
                                                                data.subitem[rowIndex].fornecedorpagamento.id = e.value;
                                                                data.subitem[rowIndex].fornecedorpagamento.nome = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            defaultOptions
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: ["orcamento", "empresa"],
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="imposto" placeholder="% Imposto"
                                                            value={pontoVirgula(data.subitem[rowIndex].imposto)}
                                                            onChange={(e) => {
                                                                data.subitem[rowIndex].imposto = e.target.value;
                                                                calculaTotalOrcamento();
                                                                setDados({ ...dados });
                                                            }}
                                                        />
                                                        <label htmlFor="floatingInput">% Imposto</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Moeda</label>
                                                        <ReactSelect
                                                            placeholder={"Moeda"}
                                                            value={{ value: row.moeda ?? "", label: row.nomeMoeda ?? "" }}
                                                            onChange={(e) => {
                                                                data.subitem[rowIndex].moeda = e.value;
                                                                data.subitem[rowIndex].nomeMoeda = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'real', label: 'Real' },
                                                                { value: 'dolar', label: 'Dolar' },
                                                                { value: 'euro', label: 'Euro' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Compôe o FEE?</label>
                                                        <ReactSelect
                                                            placeholder={"Fee"}
                                                            value={{ value: row.compoeFee ?? "", label: row.nomeCompoeFee ?? "" }}
                                                            onChange={(e) => {
                                                                data.subitem[rowIndex].compoeFee = e.value;
                                                                data.subitem[rowIndex].nomeCompoeFee = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'sim', label: 'Sim' },
                                                                { value: 'nao', label: 'Nâo' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Tipo Pagamento</label>
                                                        <ReactSelect
                                                            placeholder={"Pagamento"}
                                                            value={{ value: row.tipopagamento ?? "", label: row.nomeTipopagamento ?? "" }}
                                                            onChange={(e) => {
                                                                data.subitem[rowIndex].tipopagamento = e.value;
                                                                data.subitem[rowIndex].nomeTipopagamento = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'normal', label: 'Normal' },
                                                                { value: 'antecipado', label: 'Antecipado' },
                                                                { value: 'nenhum', label: 'Sem Pagamento' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <label>Tipo Faturamento</label>
                                                        <ReactSelect
                                                            placeholder={"Tipo"}
                                                            value={{ value: row.tipofaturamento ?? "", label: row.nomeTipofaturamento ?? "" }}
                                                            onChange={(e) => {
                                                                data.subitem[rowIndex].tipofaturamento = e.value;
                                                                data.subitem[rowIndex].nomeTipofaturamento = e.label;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'nd', label: 'ND' },
                                                                { value: 'nf', label: 'NF' },
                                                                { value: 'notaFatura', label: 'Nota Fatura' },
                                                                { value: 'pagamentoDireto', label: 'Pagamento Direto' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalButton>
                                        {(!data.subitem[rowIndex].produto?.somarExceto) ?
                                            <input
                                                style={{
                                                    border: '1px solid blue',
                                                    /*coloca sombra azula agora*/
                                                    boxShadow: '0 0 4px 3px yellow',
                                                    visibility: (dados.itemSomarAtual && dados.itemSomarAtual !== data.subitem[rowIndex].chaveunica) ? 'visible' : 'hidden'
                                                }}
                                                type="checkbox"
                                                className="form-check-input"
                                                value={data.subitem[rowIndex].chaveunica}
                                                checked={verificaSeExiste(dados, data, rowIndex)}
                                                onChange={(value) => {
                                                    if (verificaSeExiste(dados, data, rowIndex)) {
                                                        removeCheck(dados, data, rowIndex);
                                                    }
                                                    else {
                                                        adicionaCheck(dados, data, rowIndex);
                                                    }
                                                    calculaTotalOrcamento();
                                                    setDados({ ...dados });
                                                }} />
                                            : null
                                        }
                                    </div>
                                    <div className='col-sm-10' style={{ paddingRight: '0px' }}>
                                        <ReactSelect
                                            placeholder={"Sub Item"}
                                            value={row.produto !== null ? { value: row.produto?.id ?? null, label: row.produto?.nome ?? null } : null}
                                            onChange={(e) => {
                                                data.subitem[rowIndex].produto = {};
                                                data.subitem[rowIndex].produto.id = e.value;
                                                data.subitem[rowIndex].produto.nome = e.label;
                                                if (e.preco !== undefined) {
                                                    if (semValor(data.subitem[rowIndex].valorUnitarioPreEvento))
                                                        data.subitem[rowIndex].valorUnitarioPreEvento = e.preco;
                                                    //if (semValor(data.subitem[rowIndex].valorUnitarioNegociado))
                                                    //    data.subitem[rowIndex].valorUnitarioNegociado = e.preco;
                                                    //if (semValor(data.subitem[rowIndex].valorUnitarioFechamento))
                                                    //    data.subitem[rowIndex].valorUnitarioFechamento = e.preco;
                                                }
                                                if (e.quantidade !== undefined) {
                                                    if (semValor(data.subitem[rowIndex].quantidadePreEvento))
                                                        data.subitem[rowIndex].quantidadePreEvento = e.quantidade;
                                                    //if (semValor(data.subitem[rowIndex].quantidadeNegociado))
                                                    //    data.subitem[rowIndex].quantidadeNegociado = e.quantidade;
                                                    //if (semValor(data.subitem[rowIndex].quantidadeFechamento))
                                                    //    data.subitem[rowIndex].quantidadeFechamento = e.quantidade;
                                                }
                                                data.subitem[rowIndex].codigoFornecedor = e.codigoFornecedor;
                                                //data.subitem[rowIndex].observacao = e.observacao;
                                                calculaTotalOrcamento();
                                                setDados({ ...dados });
                                            }}
                                            defaultOptions
                                            loadOptions={(input) => {
                                                return api({
                                                    method: 'get',
                                                    action: '/produto',
                                                    token: token,
                                                    params: {
                                                        nome: input,
                                                        situacaoRegistro: 'ativo',
                                                        modulo: 'servicoOrcamento',
                                                        cliente: dados.cliente?.id ?? null,
                                                        superior: data.produto ? data.produto.id : null
                                                    }
                                                })
                                                    .then(response => {
                                                        let options = response.data.data.map((registro) => {
                                                            var codigoFornecedor = '';// Fora de Catálogo
                                                            if (registro.codigoFornecedor) {
                                                                codigoFornecedor = registro.codigoFornecedor;
                                                            }
                                                            return {
                                                                value: registro.id,
                                                                label: (codigoFornecedor ? codigoFornecedor + " - " : "") + registro.nome,
                                                                preco: registro.preco,
                                                                quantidade: registro.quantidade,
                                                                codigoFornecedor: codigoFornecedor
                                                            };
                                                        });
                                                        return options;
                                                    })
                                                    .catch(function (error) {
                                                        //console.log(error);
                                                    });
                                            }}
                                        />
                                    </div>
                                    <div className='col-sm-1' style={{ paddingLeft: '2px', paddingTop: '2px' }}>
                                        <IconButton
                                            style={{ zoom: "0.7" }}
                                            title="Remover"
                                            color="warning"
                                            icon={<FaTrash size={10} />}
                                            onClick={() => {
                                                data.subitem.splice(rowIndex, 1);
                                                calculaTotalOrcamento();
                                                setDados({ ...dados });
                                            }}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td colSpan={4} style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", textAlign: "right" }}>
                                <Button
                                    style={{ zoom: "0.7" }}
                                    size="sm"
                                    title="Copiar valores anteriores"
                                    onClick={() => {
                                        copiaValoresInferior("PreEvento", "Negociado", data.subitem[rowIndex].indexInferior);
                                        setDados({ ...dados });
                                    }}>copiar</Button>
                            </td>
                            <td colSpan={4} style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", textAlign: "right" }}>
                                <Button
                                    style={{ zoom: "0.7" }}
                                    size="sm"
                                    title="Copiar valores anteriores"
                                    onClick={() => {
                                        copiaValoresInferior("Negociado", "Fechamento", data.subitem[rowIndex].indexInferior);
                                        setDados({ ...dados });
                                    }}>copiar</Button>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: "1px solid rgba(0,0,0,0)" }}>
                            <td>
                                <div className="form-floating form-group" style={{ position: 'relative' }}>
                                    {(!data.subitem[rowIndex].produto?.somarExceto) ?
                                        dados.itemMostrarAtual === data.subitem[rowIndex].chaveunica ?
                                            <button
                                                title="Marcar para somar outros itens"
                                                style={{ position: 'absolute', right: '0px', zoom: "0.7", visibility: ((dados.itemSomarAtual) && (dados.itemSomarAtual !== data.subitem[rowIndex].chaveunica)) ? 'hidden' : 'visible' }}
                                                type="button"
                                                className="btn btn-sm btn-primary" onClick={() => {
                                                    //toast.info('Você pode marcar os itens que serão somados para compor este valor');
                                                    if (dados.itemSomarAtual) {
                                                        dados.itemSomarAtual = null;
                                                    } else {
                                                        dados.itemSomarAtual = data.subitem[rowIndex].chaveunica;
                                                    }
                                                    setDados({ ...dados });
                                                }}
                                            >{dados.itemSomarAtual ? 'ok' : '+'}</button> : null
                                        : null
                                    }
                                    <input type="text" className="form-control form-control-lg right" id="valorUnitarioPreEvento" placeholder="Valor"
                                        value={pontoVirgula(data.subitem[rowIndex].valorUnitarioPreEvento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].valorUnitarioPreEvento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                        onFocus={(e) => {
                                            dados.itemMostrarAtual = data.subitem[rowIndex].chaveunica;
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Valor</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="diariaPreEvento" placeholder="Diárias"
                                        value={pontoVirgula(data.subitem[rowIndex].diariaPreEvento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].diariaPreEvento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Diárias</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="quantidadePreEvento" placeholder="Quantidade"
                                        value={pontoVirgula(data.subitem[rowIndex].quantidadePreEvento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].quantidadePreEvento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Quantidade</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="servicoPreEvento" placeholder="% Serviço"
                                        value={pontoVirgula(data.subitem[rowIndex].servicoPreEvento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].servicoPreEvento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">% Serviço</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="valorUnitarioNegociado" placeholder="Valor"
                                        value={pontoVirgula(data.subitem[rowIndex].valorUnitarioNegociado)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].valorUnitarioNegociado = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Valor</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="diariaNegociado" placeholder="Diárias"
                                        value={pontoVirgula(data.subitem[rowIndex].diariaNegociado)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].diariaNegociado = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Diárias</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="quantidadeNegociado" placeholder="Quantidade"
                                        value={pontoVirgula(data.subitem[rowIndex].quantidadeNegociado)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].quantidadeNegociado = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Quantidade</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="servicoNegociado" placeholder="% Serviço"
                                        value={pontoVirgula(data.subitem[rowIndex].servicoNegociado)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].servicoNegociado = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">% Serviço</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="valorUnitarioFechamento" placeholder="Valor"
                                        value={pontoVirgula(data.subitem[rowIndex].valorUnitarioFechamento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].valorUnitarioFechamento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Valor</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="diariaFechamento" placeholder="Diárias"
                                        value={pontoVirgula(data.subitem[rowIndex].diariaFechamento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].diariaFechamento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });

                                        }}
                                    />
                                    <label htmlFor="floatingInput">Diárias</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="quantidadeFechamento" placeholder="Quantidade"
                                        value={pontoVirgula(data.subitem[rowIndex].quantidadeFechamento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].quantidadeFechamento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Quantidade</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" id="servicoFechamento" placeholder="% Serviço"
                                        value={pontoVirgula(data.subitem[rowIndex].servicoFechamento)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].servicoFechamento = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">% Serviço</label>
                                </div>
                            </td>
                        </tr>
                        <tr style={{ zoom: "0.7", borderBottom: "1px solid" }} >
                            <td colSpan="3">
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg" id="observacaoPreEvento" placeholder="Observação Pré Evento"
                                        value={row.observacaoPreEvento}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].observacaoPreEvento = e.target.value;
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Observação Pré Evento</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-floating form-group">
                                    <input readOnly type="text" className="form-control form-control-lg right" id="totalPreEvento" placeholder="Total"
                                        value={pontoVirgula(data.subitem[rowIndex].totalPreEvento)}
                                    />
                                    <label htmlFor="floatingInput">Total</label>
                                </div>
                            </td>
                            <td colSpan="3" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg" id="observacaoNegociado" placeholder="Observação Negociado"
                                        value={row.observacaoNegociado}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].observacaoNegociado = e.target.value;
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Observação Negociado</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div className="form-floating form-group">
                                    <input readOnly type="text" className="form-control form-control-lg right" id="totalNegociado" placeholder="Total"
                                        value={pontoVirgula(data.subitem[rowIndex].totalNegociado)}
                                    />
                                    <label htmlFor="floatingInput">Total</label>
                                </div>
                            </td>
                            <td colSpan="3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg" id="observacaoFechamento" placeholder="Observação Fechamento"
                                        value={row.observacaoFechamento}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].observacaoFechamento = e.target.value;
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Observação Fechamento</label>
                                </div>
                            </td>
                            <td style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                                <div className="form-floating form-group">
                                    <input readOnly type="text" className="form-control form-control-lg right" id="totalFechamento" placeholder="Total"
                                        value={pontoVirgula(data.subitem[rowIndex].totalFechamento)}
                                    />
                                    <label htmlFor="floatingInput">Total</label>
                                </div>
                            </td>
                        </tr>
                    </>
                ];
            }) : null}
            <tr>
                <td colSpan="3">
                    <button type="button" className="btn btn-sm btn-primary me-1 mb-1"
                        onClick={() => {
                            data.subitem.push({
                                moeda: "real",
                                compoeFee: "sim",
                                tipopagamento: "normal",
                                quantidade: "1",
                                chaveunica: rand18(),
                            });
                            setDados({ ...dados });
                        }}>
                        <FaPlus size={20} /> Adicionar Item</button>
                </td>
                <td>
                    <div className="form-floating form-group">
                        <input readOnly type="text" className="form-control form-control-lg right" id="valorTotalPreEvento" placeholder="Total"
                            value={pontoVirgula(data.valorTotalPreEvento)}
                        />
                        <label htmlFor="floatingInput">Total</label>
                    </div>
                </td>
                <td colSpan="3" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                </td>
                <td style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                    <div className="form-floating form-group">
                        <input readOnly type="text" className="form-control form-control-lg right" id="valorTotalNegociado" placeholder="Total"
                            value={pontoVirgula(data.valorTotalNegociado)}
                        />
                        <label htmlFor="floatingInput">Total</label>
                    </div>
                </td>
                <td colSpan="3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                </td>
                <td style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                    <div className="form-floating form-group">
                        <input readOnly type="text" className="form-control form-control-lg right" id="valorTotalFechamento" placeholder="Total"
                            value={pontoVirgula(data.valorTotalFechamento)}
                        />
                        <label htmlFor="floatingInput">Total</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table >;
}

export default function CadastroOrcamento({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const usuario = localStorage.getItem("idUsuario");
    const token = localStorage.getItem('token');
    let [dados, setDados] = useState({
        modulo: 1,
        orcamentoitem: [],
    });// 1 - orcamento
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    let { state } = useLocation();
    const copiar = (state?.copiar ?? false);

    const calculaTotalOrcamento = () => {
        calculaTotalOrcamento2();
        calculaTotalOrcamento2();
    }//calculaTotalOrcamento

    const calculaTotalOrcamento2 = () => {
        var valorTotalFinalPreEvento = 0;
        var valorTotalFinalNegociado = 0;
        var valorTotalFinalFechamento = 0;
        dados.orcamentoitem.forEach(function (valor, indexSuperior) {
            dados.orcamentoitem[indexSuperior].indexSuperior = indexSuperior;
            dados.orcamentoitem[indexSuperior].valorTotalPreEvento = 0;
            dados.orcamentoitem[indexSuperior].valorTotalNegociado = 0;
            dados.orcamentoitem[indexSuperior].valorTotalFechamento = 0;
            dados.orcamentoitem[indexSuperior].subitem.forEach(function (valor, indexInferior) {
                dados.orcamentoitem[indexSuperior].subitem[indexInferior].indexInferior = indexInferior;
                var quantidadePreEvento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].quantidadePreEvento);
                var valorUnitarioPreEvento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].valorUnitarioPreEvento);
                var diariaPreEvento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].diariaPreEvento);
                var servicoPreEvento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].servicoPreEvento);

                var quantidadeNegociado = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].quantidadeNegociado);
                var valorUnitarioNegociado = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].valorUnitarioNegociado);
                var diariaNegociado = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].diariaNegociado);
                var servicoNegociado = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].servicoNegociado);

                var quantidadeFechamento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].quantidadeFechamento);
                var valorUnitarioFechamento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].valorUnitarioFechamento);
                var diariaFechamento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].diariaFechamento);
                var servicoFechamento = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior].servicoFechamento);

                var totalPreEvento = quantidadePreEvento * diariaPreEvento * valorUnitarioPreEvento;
                totalPreEvento = totalPreEvento * (1 + (servicoPreEvento / 100));
                var totalNegociado = quantidadeNegociado * diariaNegociado * valorUnitarioNegociado;
                totalNegociado = totalNegociado * (1 + (servicoNegociado / 100));
                var totalFechamento = quantidadeFechamento * diariaFechamento * valorUnitarioFechamento;
                totalFechamento = totalFechamento * (1 + (servicoFechamento / 100));

                dados.orcamentoitem[indexSuperior].valorTotalPreEvento += totalPreEvento;
                dados.orcamentoitem[indexSuperior].valorTotalNegociado += totalNegociado;
                dados.orcamentoitem[indexSuperior].valorTotalFechamento += totalFechamento;

                dados.orcamentoitem[indexSuperior].subitem[indexInferior].totalPreEvento = totalPreEvento.toFixed(2);
                dados.orcamentoitem[indexSuperior].subitem[indexInferior].totalNegociado = totalNegociado.toFixed(2);
                dados.orcamentoitem[indexSuperior].subitem[indexInferior].totalFechamento = totalFechamento.toFixed(2);
            });

            valorTotalFinalPreEvento += dados.orcamentoitem[indexSuperior].valorTotalPreEvento;
            valorTotalFinalNegociado += dados.orcamentoitem[indexSuperior].valorTotalNegociado;
            valorTotalFinalFechamento += dados.orcamentoitem[indexSuperior].valorTotalFechamento;

            dados.orcamentoitem[indexSuperior].valorTotalPreEvento = dados.orcamentoitem[indexSuperior].valorTotalPreEvento.toFixed(2);
            dados.orcamentoitem[indexSuperior].valorTotalNegociado = dados.orcamentoitem[indexSuperior].valorTotalNegociado.toFixed(2);
            dados.orcamentoitem[indexSuperior].valorTotalFechamento = dados.orcamentoitem[indexSuperior].valorTotalFechamento.toFixed(2);
        });

        //calcula valor de itens que são o somatorio de outros itens
        dados.orcamentoitem.forEach(function (itemSuperiorX, indexSuperiorX) { //loop nos itens superiores
            dados.orcamentoitem[indexSuperiorX].subitem.forEach(function (subitemX, indexInferiorX) { //loop nos subitens
                //calcula valor do item se ele for resultado da soma de outros
                var arrayChavesSomar = dados.orcamentoitem[indexSuperiorX].subitem[indexInferiorX].chavessomar ? dados.orcamentoitem[indexSuperiorX].subitem[indexInferiorX].chavessomar.split(",") : [];
                var totalSomado = 0;
                if (arrayChavesSomar.length > 0) {
                    arrayChavesSomar.forEach((chaveUnicaItemSomar) => {
                        //busca item pra achar o preço
                        dados.orcamentoitem.forEach((orcamentoItemX, indexOrcamentoItem) => {
                            orcamentoItemX.subitem.forEach((subitemX2, indexSubItem) => {
                                if (chaveUnicaItemSomar === subitemX2.chaveunica) {
                                    totalSomado += virgulaPonto(subitemX2.totalPreEvento);
                                }
                            });
                        });
                    });
                    dados.orcamentoitem[indexSuperiorX].subitem[indexInferiorX].valorUnitarioPreEvento = pontoVirgula(totalSomado);
                }//se for soma de outros itens
            });
        });

        //calcula total de itens que sao somatorio de outros grupos (exceto alguns grupos e eles mesmos)
        dados.orcamentoitem.forEach(function (itemSuperiorY, indexSuperiorY) { //loop nos itens superiores
            dados.orcamentoitem[indexSuperiorY].subitem.forEach(function (subitemY, indexInferiorY) { //loop nos subitens
                var gruposNaoSomar = dados.orcamentoitem[indexSuperiorY].subitem[indexInferiorY].produto?.somarExceto ? dados.orcamentoitem[indexSuperiorY].subitem[indexInferiorY].produto.somarExceto.split(",") : [];
                if (gruposNaoSomar.length > 0) { // é um item que deve executar somatoria
                    var totalGrupos = 0;
                    //somar todos os itens retirando apenas os itens dos grupos que não devem ser somados
                    dados.orcamentoitem.forEach(function (itemSuperiorY2, indexSuperiorY2) { //loop nos itens superiores
                        dados.orcamentoitem[indexSuperiorY2].subitem.forEach(function (subitemY2, indexInferiorY2) { //loop nos subitens
                            //nao inclui na soma nenhum item dos grupos excluidos, e também nao inclui si mesmo
                            if (gruposNaoSomar.includes(itemSuperiorY2.produto.id) || (subitemY.produto.id === subitemY2.produto.id)) {
                                //console.log("nao deve somar");
                            } else {
                                //console.log("deve somar sim");
                                totalGrupos += virgulaPonto(subitemY2.totalPreEvento);
                            }
                        });
                    });
                    dados.orcamentoitem[indexSuperiorY].subitem[indexInferiorY].valorUnitarioPreEvento = pontoVirgula(totalGrupos);
                }
            });
        });

        calculaPreenchimento(dados);

        dados.valorTotalPreEvento = valorTotalFinalPreEvento.toFixed(2);
        dados.valorTotalNegociado = valorTotalFinalNegociado.toFixed(2);
        dados.valorTotalFechamento = valorTotalFinalFechamento.toFixed(2);
    }//calculaTotalOrcamento

    const calculaPreenchimento = (dados) => {
        //verifica status de preenchimento para mostrar no cabeçalho
        dados.orcamentoitem.forEach(function (valor, indexSuperior) {
            dados.orcamentoitem[indexSuperior]["statuspreenchimento"] = "embranco";
            dados.orcamentoitem[indexSuperior].subitem.forEach(function (valor, indexInferior) {
                var subitem = dados.orcamentoitem[indexSuperior].subitem[indexInferior];
                if ((subitem.totalPreEvento > 0) || (subitem.totalNegociado > 0) || (subitem.totalFechamento > 0)) {
                    dados.orcamentoitem[indexSuperior]["statuspreenchimento"] = "preenchendo";
                }
                if ((subitem.totalPreEvento > 0) && (subitem.totalNegociado > 0) && (subitem.totalFechamento > 0)) {
                    dados.orcamentoitem[indexSuperior]["statuspreenchimento"] = "completo";
                }
            });
        });//verifica status preenchimento
    }//calculaPreenchimento

    const copiaValores = (anterior, atual, indexSuperiorCopiar) => {
        dados.orcamentoitem.forEach(function (valor, indexSuperior) {
            if (indexSuperior === indexSuperiorCopiar) {
                dados.orcamentoitem[indexSuperior].subitem.forEach(function (valor, indexInferior) {
                    var valorUnitario = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["valorUnitario" + anterior]);
                    var diaria = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["diaria" + anterior]);
                    var quantidade = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["quantidade" + anterior]);
                    var servico = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["servico" + anterior]);
                    var total = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["total" + anterior]);

                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["valorUnitario" + atual] = valorUnitario.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["diaria" + atual] = diaria.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["quantidade" + atual] = quantidade.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["servico" + atual] = servico.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["total" + atual] = total.toString();
                });
            }
        });
        calculaTotalOrcamento();
    }//copiaValores

    const copiaValoresInferior = (anterior, atual, indexInferiorCopiar) => {
        dados.orcamentoitem.forEach(function (valor, indexSuperior) {
            dados.orcamentoitem[indexSuperior].subitem.forEach(function (valor, indexInferior) {
                if (indexInferior === indexInferiorCopiar) {
                    var valorUnitario = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["valorUnitario" + anterior]);
                    var diaria = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["diaria" + anterior]);
                    var quantidade = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["quantidade" + anterior]);
                    var servico = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["servico" + anterior]);
                    var total = virgulaPonto(dados.orcamentoitem[indexSuperior].subitem[indexInferior]["total" + anterior]);

                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["valorUnitario" + atual] = valorUnitario.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["diaria" + atual] = diaria.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["quantidade" + atual] = quantidade.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["servico" + atual] = servico.toString();
                    dados.orcamentoitem[indexSuperior].subitem[indexInferior]["total" + atual] = total.toString();
                }
            });
        });
        calculaTotalOrcamento();
    }//copiaValoresInferior

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/orcamento/' + id,
                token: token,
            }).then(response => {
                //extrai cliente do evento para ter acesso e restringir serviços
                var dadosCliente = response.data.data.evento?.cliente ?? null;
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: ['evento']
                });

                //ao copiar nao repetir codigo/numero
                if (copiar)
                    delete dadosRecebidos.codigo;

                //transforma data para javascript
                dadosRecebidos.data = data(dadosRecebidos.data);
                dadosRecebidos.cliente = dadosCliente; //extrai cliente para serviços terem acesso

                calculaPreenchimento(dadosRecebidos);
                dados = dadosRecebidos;
                calculaTotalOrcamento();
                setDados(dados);
            }).catch(err => {
                // console.log(err)
            });
        } else {
            api({
                method: "get",
                action: '/orcamento/0',
                token: token,
            }).then(response => {
                var dadosRecebidos = response.data.data;
                dadosRecebidos.data = moment().format('DD/MM/YYYY');
                setDados(dadosRecebidos);
            }).catch(err => {
                // console.log(err)
            });
            //dados.orcamentoitem = [];
        }
    }; //buscar

    const validar = () => {
        if (dados.orcamentoproduto?.length > 0) { //se tiver produtos, obrigar preencher
            var temEmBranco = false;
            dados.orcamentoproduto.forEach((element, index) => {
                if (element.produto?.nome === undefined || element.produto?.nome === null) {
                    toast.error('É necessário selecionar o produto ' + (index + 1).toString());
                    temEmBranco = true;
                    return false;
                }
            })
            if (temEmBranco) {
                return false;
            }
        }
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }

        if (dados.data) {
            dados.data = dataMysql(dados.data);
        }

        if (id && !copiar) {//update
            api({
                method: 'put',
                action: '/orcamento/' + id,
                token: token,
                data: {
                    ...dados,
                    ...{ operador: usuario }
                }
            })
                .then(function (response) {
                    history.push("/orcamento");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert
            api({
                method: 'post',
                action: '/orcamento',
                token: token,
                data: {
                    ...dados,
                    ...{ empresa: empresa },
                    ...{ operador: usuario }
                }
            })
                .then(function (response) {
                    history.push("/orcamento");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        }
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>{(copiar ? "Copiar" : "")} Orçamento</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="titulo" placeholder="Título"
                                                                    value={dados.titulo}
                                                                    onChange={e => setDados({ ...dados, ...{ "titulo": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Título</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <label>Evento</label>
                                                                <ReactSelect
                                                                    placeholder={"Evento"}
                                                                    value={{ value: dados.evento, label: dados.nomeEvento }}
                                                                    onChange={(e) => {
                                                                        dados.cliente = e.cliente;
                                                                        setDados({ ...dados, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                                    }}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/evento',
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                                situacao: ["ativo", "fechado"]
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    var label = registro.nome;
                                                                                    if (registro.identificacao) {
                                                                                        label = registro.identificacao + " - " + label;
                                                                                    }
                                                                                    if (registro.cliente) {
                                                                                        label = label + ' - ' + registro.cliente.nome;
                                                                                    }
                                                                                    label = registro.codigo + " - " + label;
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: label,
                                                                                        disabled: registro.situacao === "fechado" ? true : false,
                                                                                        cliente: registro.cliente ?? null
                                                                                    }
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <Data
                                                                label="Data"
                                                                value={dados.data}
                                                                onChange={date => setDados({ ...dados, ...{ "data": date } })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="row mb-4">
                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-body px-0 pb-0">
                                                                    <div className="table-responsive">
                                                                        <table className='table mb-0' id="table1">
                                                                            <thead>
                                                                                <tr>
                                                                                    <td width="50px"></td>
                                                                                    <td>Produtos</td>
                                                                                    <td width="200px"></td>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {dados.orcamentoproduto ? dados.orcamentoproduto.map((row, rowIndex) => {
                                                                                    return [
                                                                                        <tr>
                                                                                            <td>
                                                                                                <IconButton
                                                                                                    title="Remover"
                                                                                                    color="warning"
                                                                                                    icon={<FaTrash size={20} />}
                                                                                                    onClick={() => {
                                                                                                        dados.orcamentoproduto.splice(rowIndex, 1);
                                                                                                        setDados({ ...dados });
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <ReactSelect
                                                                                                    key={dados.evento ?? null}
                                                                                                    placeholder={"Produto"}
                                                                                                    value={row.produto !== null ? { value: row.produto?.id ?? null, label: row.produto?.nome ?? null } : null}
                                                                                                    onChange={(e) => {
                                                                                                        dados.orcamentoproduto[rowIndex].produto = {};
                                                                                                        dados.orcamentoproduto[rowIndex].produto.id = e.value;
                                                                                                        dados.orcamentoproduto[rowIndex].produto.nome = e.label;
                                                                                                        setDados({ ...dados });
                                                                                                    }}
                                                                                                    defaultOptions
                                                                                                    loadOptions={(input) => {
                                                                                                        return api({
                                                                                                            method: 'get',
                                                                                                            action: '/produto',
                                                                                                            token: token,
                                                                                                            params: {
                                                                                                                nome: input,
                                                                                                                cliente: dados.cliente?.id ?? null,
                                                                                                                modulo: 'orcamento'
                                                                                                            }
                                                                                                        })
                                                                                                            .then(response => {
                                                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                                                return options;
                                                                                                            })
                                                                                                            .catch(function (error) {
                                                                                                                //console.log(error);
                                                                                                            });
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-floating form-group">
                                                                                                    <input type="text" className="form-control form-control-lg right" id="percentual" placeholder="%"
                                                                                                        value={pontoVirgula(dados.orcamentoproduto[rowIndex].percentual)}
                                                                                                        onChange={(e) => {
                                                                                                            dados.orcamentoproduto[rowIndex].percentual = e.target.value;
                                                                                                            setDados({ ...dados });
                                                                                                        }}
                                                                                                    />
                                                                                                    <label htmlFor="floatingInput">Percentual (%)</label>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ];
                                                                                }) : null}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 d-flex">
                                                            <button type="button" className="btn btn-primary me-1 mb-1"
                                                                onClick={() => {
                                                                    if (dados.orcamentoproduto === undefined || dados.orcamentoproduto === null) dados.orcamentoproduto = [];
                                                                    dados.orcamentoproduto.push({
                                                                        produto: {
                                                                            id: null,
                                                                            nome: null
                                                                        },
                                                                    });
                                                                    setDados({ ...dados });
                                                                }}>
                                                                <FaPlus size={20} /> Adicionar Produto</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <DataTable
                                                                expandableRows
                                                                expandableRowsComponent={ExpanableComponent}
                                                                expandableRowsComponentProps={{
                                                                    token: token,
                                                                    setDados: setDados,
                                                                    calculaTotalOrcamento: calculaTotalOrcamento,
                                                                    copiaValores: copiaValores,
                                                                    copiaValoresInferior: copiaValoresInferior,
                                                                    dados: dados
                                                                }}
                                                                noDataComponent="Nenhum registro"
                                                                className="table mb-0"
                                                                columns={[
                                                                    {
                                                                        width: "400px",
                                                                        cell: (row, index) =>
                                                                            <div style={{ width: '400px' }}>
                                                                                <ReactSelect
                                                                                    placeholder={"Item Superior"}
                                                                                    value={row.produto !== null ? { value: row.produto?.id ?? null, label: row.produto?.nome ?? null } : null}
                                                                                    onChange={(e) => {
                                                                                        dados.orcamentoitem[index].produto = {};
                                                                                        dados.orcamentoitem[index].produto.id = e.value;
                                                                                        dados.orcamentoitem[index].produto.nome = e.label;
                                                                                        setDados({ ...dados });
                                                                                    }}
                                                                                    defaultOptions
                                                                                    loadOptions={(input) => {
                                                                                        return api({
                                                                                            method: 'get',
                                                                                            action: '/produto',
                                                                                            token: token,
                                                                                            params: {
                                                                                                situacaoRegistro: 'ativo',
                                                                                                nome: input,
                                                                                                modulo: 'servicoOrcamento',
                                                                                                superiorIsNull: true
                                                                                            }
                                                                                        })
                                                                                            .then(response => {
                                                                                                let options = response.data.data.map((registro) => {
                                                                                                    return { value: registro.id, label: registro.codigo + ' - ' + registro.nome };
                                                                                                });
                                                                                                return options;
                                                                                            })
                                                                                            .catch(function (error) {
                                                                                                //console.log(error);
                                                                                            });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                    },
                                                                    {
                                                                        width: "50px",
                                                                        cell: (row, index) => <IconButton
                                                                            title="Remover"
                                                                            color="warning"
                                                                            icon={<FaTrash size={20} />}
                                                                            onClick={() => {
                                                                                dados.orcamentoitem.splice(index, 1);
                                                                                calculaTotalOrcamento();
                                                                                setDados({ ...dados });
                                                                            }}
                                                                        />
                                                                    },
                                                                    {
                                                                        width: "50px",
                                                                        cell: (row, index) => {
                                                                            if (dados.orcamentoitem[index]?.["statuspreenchimento"] !== undefined) {
                                                                                if (dados.orcamentoitem[index]["statuspreenchimento"] === 'embranco') {
                                                                                    return <FaRegCircle size={20} />;
                                                                                }
                                                                                if (dados.orcamentoitem[index]["statuspreenchimento"] === 'preenchendo') {
                                                                                    return <FaCircle size={20} color={"#FFFF00"} />;
                                                                                }
                                                                                if (dados.orcamentoitem[index]["statuspreenchimento"] === 'completo') {
                                                                                    return <FaCircle size={20} color={"#00FF00"} />;
                                                                                }
                                                                            } else {
                                                                                return <FaRegCircle size={20} />;
                                                                            }
                                                                        }
                                                                    },
                                                                ]}
                                                                data={dados.orcamentoitem}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            dados.orcamentoitem.push({
                                                                quantidade: "1",
                                                                subitem: []
                                                            });
                                                            setDados({ ...dados });
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Grupo</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}