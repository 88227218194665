import React from 'react';
import { Link } from 'react-router-dom';
import { bin2base64 } from '../util/util';

export default function MenuItem({ location, icon, to, children }) {
    var className = location === to ? "sidebar-item active" : "sidebar-item";
    return (
        <li className={className}>
            <Link to={to} className="sidebar-link">
                {icon}
                <span>{children}</span>
            </Link>
        </li>
    );
}