import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave, FaTrash, FaPlus, FaUpload } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import IconButton from '../../components/IconButton';
import { toast } from 'react-toastify';
import { pontoVirgula } from '../../util/util';
import NavItem from '../../components/NavItem';

export default function CadastroEventoSatisfacaoPergunta({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const [perguntasSatisfacao, setPerguntasSatisfacao] = useState([]);
    const [perguntasConversao, setPerguntasConversao] = useState([]);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/eventoSatisfacaoPergunta/' + id,
                token: token,
            }).then(response => {
                setDados({
                    nome: response.data.data.dadosEvento.nome ?? null,
                    quantidadeConvidados: response.data.data.dadosEvento.quantidadeConvidados ?? null,
                    quantidadeConvidadosNaoCompareceram: response.data.data.dadosEvento.quantidadeConvidadosNaoCompareceram ?? null,
                    duracaoEvento: response.data.data.dadosEvento.duracaoEvento ?? null,
                    mediaPermanenciaConvidados: response.data.data.dadosEvento.mediaPermanenciaConvidados ?? null
                });
                setPerguntasSatisfacao(response.data.data.perguntasSatisfacao);
                setPerguntasConversao(response.data.data.perguntasConversao);
            }).catch(err => {
                toast.error('Erro ao carregar dados: ' + (err.response?.data?.error ?? err.message));
            });
        }
    };

    const salvar = () => {

        delete dados.nome; //não precisa enviar

        api({
            method: 'put',
            action: '/eventoSatisfacaoPergunta/' + id,
            token: token,
            data: {
                dados: dados,
                perguntasSatisfacao: perguntasSatisfacao,
                perguntasConversao: perguntasConversao
            }
        })
            .then(function (response) {
                toast.success('Perguntas salvas com sucesso!');
                history.push("/eventoSatisfacaoPergunta");
            })
            .catch(function (error) {
                toast.error('Erro ao salvar perguntas: ' + (error.response?.data?.error ?? error.message));
            });
    };

    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem
                        to={{
                            pathname: "/eventoImportarPerguntasSatisfacao/" + id,
                            state: {
                                nomeEvento: dados.nome
                            }
                        }}
                        icon={<FaUpload size={20} />}
                        color="outline-dark">Importar Planilha</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Perguntas de Satisfação do Evento</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { e.preventDefault(); salvar(); }}>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <h4>Quantidade de Convidados</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="quantidadeConvidados" placeholder="Quantidade de Convidados"
                                                            value={dados.quantidadeConvidados}
                                                            onChange={e => setDados({ ...dados, ...{ "quantidadeConvidados": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Quantidade de Convidados</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="quantidadeConvidadosNaoCompareceram" placeholder="Quant. Conv. Não Compareceram"
                                                            value={dados.quantidadeConvidadosNaoCompareceram}
                                                            onChange={e => setDados({ ...dados, ...{ "quantidadeConvidadosNaoCompareceram": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Quant. Conv. Não Compareceram</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4>Watching Time</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="duracaoEvento" placeholder="Duração Evento (min)"
                                                            value={dados.duracaoEvento}
                                                            onChange={e => setDados({ ...dados, ...{ "duracaoEvento": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Duração Evento (min)</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="mediaPermanenciaConvidados" placeholder="Média Permanência Convidados (min)"
                                                            value={pontoVirgula(dados.mediaPermanenciaConvidados)}
                                                            onChange={e => setDados({ ...dados, ...{ "mediaPermanenciaConvidados": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Média Permanência Convidados (min)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th colSpan="10" style={{ textAlign: "center" }}>Nota</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50px"></th>
                                                                            <th>Perguntas de Satisfação</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>1</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>2</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>3</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>4</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>5</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>6</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>7</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>8</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>9</th>
                                                                            <th width="65px" style={{ textAlign: "center" }}>10</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {perguntasSatisfacao.map((pergunta, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <IconButton
                                                                                        title="Remover"
                                                                                        color="warning"
                                                                                        icon={<FaTrash size={20} />}
                                                                                        onClick={() => {
                                                                                            const novasPerguntas = [...perguntasSatisfacao];
                                                                                            novasPerguntas.splice(index, 1);
                                                                                            setPerguntasSatisfacao(novasPerguntas);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={pergunta.pergunta}
                                                                                        onChange={(e) => {
                                                                                            const novasPerguntas = [...perguntasSatisfacao];
                                                                                            novasPerguntas[index].pergunta = e.target.value;
                                                                                            setPerguntasSatisfacao(novasPerguntas);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                {[...Array(10)].map((_, i) => (
                                                                                    <td key={i}>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            value={parseInt(pergunta[`nota${i + 1}`])}
                                                                                            onChange={(e) => {
                                                                                                const novasPerguntas = [...perguntasSatisfacao];
                                                                                                novasPerguntas[index][`nota${i + 1}`] = parseInt(e.target.value);
                                                                                                setPerguntasSatisfacao(novasPerguntas);
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            setPerguntasSatisfacao([...perguntasSatisfacao, { pergunta: '', nota1: null }]);
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Pergunta
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th width="50px"></th>
                                                                            <th>Perguntas de Conversão</th>
                                                                            <th width="200px">Nota</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {perguntasConversao.map((pergunta, index) => (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <IconButton
                                                                                        title="Remover"
                                                                                        color="warning"
                                                                                        icon={<FaTrash size={20} />}
                                                                                        onClick={() => {
                                                                                            const novasPerguntas = [...perguntasConversao];
                                                                                            novasPerguntas.splice(index, 1);
                                                                                            setPerguntasConversao(novasPerguntas);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={pergunta.pergunta}
                                                                                        onChange={(e) => {
                                                                                            const novasPerguntas = [...perguntasConversao];
                                                                                            novasPerguntas[index].pergunta = e.target.value;
                                                                                            setPerguntasConversao(novasPerguntas);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={pontoVirgula(pergunta.nota1)}
                                                                                        onChange={(e) => {
                                                                                            const novasPerguntas = [...perguntasConversao];
                                                                                            novasPerguntas[index].nota1 = e.target.value;
                                                                                            setPerguntasConversao(novasPerguntas);
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            setPerguntasConversao([
                                                                ...perguntasConversao,
                                                                {
                                                                    pergunta: '',
                                                                    nota1: null,
                                                                    nota2: null,
                                                                    nota3: null,
                                                                    nota4: null,
                                                                    nota5: null,
                                                                    nota6: null,
                                                                    nota7: null,
                                                                    nota8: null,
                                                                    nota9: null,
                                                                    nota10: null
                                                                }]);
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Pergunta
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}