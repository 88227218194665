import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaChartPie, FaArrowUp, FaList, FaArrowDown, FaFileInvoiceDollar, FaTable, FaFileExcel, FaFileArchive, FaFileAlt } from 'react-icons/fa';
import { GiStairsGoal } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactSelect from '../../components/ReactSelect';
import FormButton from '../../components/FormButton';
import Data from '../../components/Data';

export default function PainelFinanceiro() {
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['diretor', 'gerentefinanceiro', 'financeiro'].includes(perfil) ?
                        <NavItem to="/financeiroParcelaPagar" icon={<FaArrowUp size={20} />} color="outline-dark">Pagar</NavItem> :
                        null}
                    {['diretor', 'gerentefinanceiro', 'financeiro'].includes(perfil) ?
                        <NavItem to="/financeiroParcelaReceber" icon={<FaArrowDown size={20} />} color="outline-dark">Receber</NavItem> :
                        null}
                    {['diretor', 'gerentefinanceiro', 'financeiro'].includes(perfil) ?
                        <NavItem to="/venda" icon={<FaFileInvoiceDollar size={20} />} color="outline-dark">Faturas Clientes</NavItem> :
                        null}
                    {['diretor', 'gerentefinanceiro', 'financeiro', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil) ?
                        <NavItem to="/compra" icon={<FaFileInvoiceDollar size={20} />} color="outline-dark">Faturas Fornecedores</NavItem> :
                        null}
                    {['diretor', 'gerentefinanceiro'].includes(perfil) ?
                        <NavItem to="/produto" icon={<FaList size={20} />} color="outline-dark">Lista de Serviços</NavItem> :
                        null}
                    {['diretor', 'gerentefinanceiro'].includes(perfil) ?
                        <NavItem to="/financeiroCaixa" icon={<FaList size={20} />} color="outline-dark">Caixas</NavItem> :
                        null}
                    {['diretor', 'gerentefinanceiro'].includes(perfil) ?
                        <NavItem to="/financeiroPlanoConta" icon={<FaList size={20} />} color="outline-dark">Plano de Contas</NavItem> :
                        null}
                    {['diretor', 'gerentefinanceiro', 'financeiro'].includes(perfil) ?
                        <NavItem to={{
                            pathname: "/financeiroRelatorioDre",
                        }}
                            icon={<FaFileAlt size={20} />}
                            color="outline-dark">DRE</NavItem> :
                        null}
                </ul>
                <div className="page-title">
                    <h3>Financeiro</h3>
                </div>
            </div>
        </ErpTemplate>
    );
}