import React, { useState } from 'react';
import IconButton from './IconButton';

export default function DropDown({ icon, children, opcoes = [], style = null }) {
    var timer = false;
    const [show, setShow] = useState("");
    return (
        <div
            className="dropdown"
            onClick={() => {
                if (show === "") {
                    setShow("show");
                } else {
                    setShow("");
                }
            }}
            onMouseLeave={
                () => {
                    //timer = setTimeout(() => {
                    //    setShow("")
                    //}, 2000);
                }
            }
            onMouseEnter={() => {
                //if (timer)
                //    clearTimeout(timer);
            }}>
            <IconButton
                icon={icon}
            >{children}</IconButton>
            <div style={{ padding: "4px", minWidth: "12rem" }}
                className={"dropdown-menu " + show}>
                {opcoes ? opcoes.map((opcao) => {
                    return opcao;
                }) : null}
            </div>
        </div>
    );
}