import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function AlterarContaFornecedor({ match }) {
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const history = useHistory();
    let { state } = useLocation();
    const idParcelas = state?.idParcelas ?? null;

    const salvar = () => {
        api({
            method: 'put',
            action: '/financeiroParcela/alterarContaFornecedor',
            token: token,
            data: {
                idParcelas: idParcelas,
                ...dados,
            },
        })
            .then(function (response) {
                history.push("/financeiroParcelaPagar");
            })
            .catch(function (error) {
                //alert(error)
            });
    };

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Alterar Conta e Favorecido</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Favorecido</label>
                                                        <ReactSelect
                                                            value={{ value: dados.participante, label: dados.nomeParticipante }}
                                                            onChange={e => setDados({ ...dados, ...{ "participante": e.value, "nomeParticipante": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Plano de Contas</label>
                                                        <ReactSelect
                                                            value={{ value: dados.conta, label: dados.nomeConta }}
                                                            onChange={e => setDados({ ...dados, ...{ "conta": e.value, "nomeConta": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/financeiroPlanoConta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "1",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.caminho + ' - ' + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}