import React, { useState } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSearch } from 'react-icons/fa';
import Data from '../../../components/Data';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from '../../../components/ReactSelect';

export default function RelatorioFinanceiroParcela() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const [filtro, setFiltro] = useState({
        empresa: empresa,
    });
    let { state } = useLocation();
    const tipo = state ? (state.tipo ? state.tipo : 1) : 1; //1 - pagar

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Relatório de Parcelas</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" method="post" target="_blank" action={process.env.REACT_APP_API_URL + "/financeiroParcela/relatorio"}>
                                            <input type="hidden" name="token" value={token} />
                                            <input type="hidden" name="empresa" value={empresa} />
                                            <input type="hidden" name="tipo" value={tipo} />
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="vencimento1"
                                                        label="Data Vencimento"
                                                        value={filtro.vencimento1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "vencimento1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="vencimento2"
                                                        label="até a Data Vencimento"
                                                        value={filtro.vencimento2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "vencimento2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Situação</label>
                                                        <ReactSelect
                                                            name="situacao"
                                                            value={{ value: filtro.situacao, label: filtro.nomeSituacao }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "situacao": e.value, "nomeSituacao": e.label } })}
                                                            options={[
                                                                { value: '1', label: 'Aberto' },
                                                                { value: '2', label: 'Pago' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
