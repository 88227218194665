import axios from 'axios';
import JSONbig from 'json-bigint';

export default function api({ method = "get", action = "", token, data = {}, params = {} }) {
    var tokenObj = token ? { 'Authorization': `Bearer ${token}` } : null;
    var dataObj = Object.keys(data).length > 0 ? { data: JSON.stringify(data) } : null;
    var paramsObj = Object.keys(params).length > 0 ? { params: params } : null;
    axios.defaults.transformResponse = [function (data) {
        return JSONbig.parse(data);
    }];
    return axios({
        method: method,
        url: process.env.REACT_APP_API_URL + action,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...tokenObj
        },
        ...dataObj,
        ...paramsObj,
    });
}
