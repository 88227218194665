import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../../../components/ReactSelect';

export default function CadastroEventoParticipanteSituacao({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState([]);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/eventoParticipanteSituacao/' + id,
                token: token,
            }).then(response => {
                var dadosRecebidos = response.data.data;

                //transforma dados no formato aceito pelo reactselect
                if (dadosRecebidos.grupo?.constructor !== Array)
                    dadosRecebidos.grupo = [];
                dadosRecebidos.grupo = dadosRecebidos.grupo.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {

        if (dados.grupo && Array.isArray(dados.grupo)) {
            dados.grupo = dados.grupo.map(item => item.value).join(',');
        }

        if (id) {//update
            api({
                method: 'put',
                action: '/eventoParticipanteSituacao/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/eventoParticipanteSituacao");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/eventoParticipanteSituacao',
                token: token,
                data: { ...dados, ...{ empresa: empresa } }
            })
                .then(function (response) {
                    history.push("/eventoParticipanteSituacao");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Status</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Situação</label>
                                                        <ReactSelect
                                                            value={{ value: dados.situacaoRegistro, label: dados.nomeSituacaoRegistro }}
                                                            onChange={e => setDados({ ...dados, ...{ "situacaoRegistro": e.value, "nomeSituacaoRegistro": e.label } })}
                                                            options={[
                                                                { value: 'ativo', label: 'Ativo' },
                                                                { value: 'inativo', label: 'Inativo' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigo" placeholder="Código"
                                                            value={dados.codigo}
                                                            onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Código</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Pedir data e hora?</label>
                                                        <ReactSelect
                                                            value={{ value: dados.pedirdata, label: dados.nomePedirdata }}
                                                            onChange={e => setDados({ ...dados, ...{ "pedirdata": e.value, "nomepedirdata": e.label } })}
                                                            options={[
                                                                { value: 'nao', label: 'Não' },
                                                                { value: 'sim', label: 'Sim' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Permitir enviar Carta?</label>
                                                        <ReactSelect
                                                            value={{ value: dados.enviarcarta, label: dados.nomeEnviarcarta }}
                                                            onChange={e => setDados({ ...dados, ...{ "enviarcarta": e.value, "nomeEnviarcarta": e.label } })}
                                                            options={[
                                                                { value: 'nao', label: 'Não' },
                                                                { value: 'sim', label: 'Sim' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Somente se tiver aéreo?</label>
                                                        <ReactSelect
                                                            value={{ value: dados.seaereo, label: dados.nomeSeaereo }}
                                                            onChange={e => setDados({ ...dados, ...{ "seaereo": e.value, "nomeSeaereo": e.label } })}
                                                            options={[
                                                                { value: 'nao', label: 'Não' },
                                                                { value: 'sim', label: 'Sim' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Somente se NÃO tiver aéreo?</label>
                                                        <ReactSelect
                                                            value={{ value: dados.senaoaereo, label: dados.nomeSenaoaereo }}
                                                            onChange={e => setDados({ ...dados, ...{ "senaoaereo": e.value, "nomeSenaoaereo": e.label } })}
                                                            options={[
                                                                { value: 'nao', label: 'Não' },
                                                                { value: 'sim', label: 'Sim' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Grupo</label>
                                                        <ReactSelect
                                                            isMulti={true}
                                                            value={dados.grupo}
                                                            onChange={(e) => {
                                                                dados.grupo = dados.grupo || [];
                                                                dados.grupo = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            options={[
                                                                { value: 'confirmados', label: 'Confirmados' },
                                                                { value: 'pendentes', label: 'Pendentes' },
                                                                { value: 'declinaram', label: 'Declinaram' },
                                                                { value: 'semAereo', label: 'Confirmados Sem Aéreo' },
                                                                { value: 'aereoEmissao', label: 'Confirmados Aéreo em Emissão' },
                                                                { value: 'aereoEmitido', label: 'Confirmados Aéreo Emitido' },
                                                                { value: 'standby', label: 'Standby' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="cor" placeholder="#345456"
                                                            value={dados.cor}
                                                            onChange={e => setDados({ ...dados, ...{ "cor": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Cor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}