import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import ErpTemplate from '../../components/ErpTemplate';
import Chart from "react-apexcharts";

var lineChart = {
  series: [
    {
      name: 'Receitas',
      color: '#00e396',
      data: [],
    },
    {
      name: 'Despesas',
      color: '#FFaa00',
      data: [],
    },
  ],
  xaxis: {
    type: 'category'
  },
  options: {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    title: {
      text: "Receitas e Despesas"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
  },
};

var pieChart = {
  series: [1, 2, 3],
  options: {
    labels: ['teste', 'teste1', 'etset2'],
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5
        }
      }
    },
    title: {
      text: "Despesas"
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]
        return [name, 'R$ ' + val.toFixed(2)]
      }
    },
    legend: {
      show: false
    }
  },
};

export default function Graficos() {
  const token = localStorage.getItem('token');
  const empresa = localStorage.getItem("empresa");
  const [filtro, setFiltro] = useState({
    empresa: empresa,
  }); //1 - pagar | 2 - receber
  const [dadosLineChart, setDadosLineChart] = useState(lineChart);
  const [dadosPieChart, setDadosPieChart] = useState(pieChart);
  const [carregadoLineChart, setCarregadoLineChart] = useState(false);
  const [carregadoPieChart, setCarregadoPieChart] = useState(false);

  const buscar = () => {

    //pieChart
    api({
      method: "get",
      action: '/financeiroParcela/graficoDespesa',
      token: token,
      params: {
        ...filtro,
      }
    }).then(response => {
      dadosPieChart.series = response.data.data.map((registro) => {
        return registro.total;
      });
      dadosPieChart.options.labels = response.data.data.map((registro) => {
        return registro.nome;
      });
      //setDadosPieChart(dadosPieChart);
      setCarregadoPieChart(true);
    }).catch(err => {
      //console.log(err)
    });

    //lineChart
    api({
      method: "get",
      action: '/financeiroParcela/graficoReceitaDespesa',
      token: token,
      params: {
        ...filtro,
      }
    }).then(response => {
      dadosLineChart.series[0].data = response.data.data.map((registro) => {
        return {
          x: registro.nome,
          y: registro.receitas
        };
      });
      dadosLineChart.series[1].data = response.data.data.map((registro) => {
        return {
          x: registro.nome,
          y: registro.despesas
        };
      });
      //setDadosLineChart(dadosLineChart);
      setCarregadoLineChart(true);
    }).catch(err => {
      //console.log(err)
    });
  };

  useEffect(() => { //onload
    buscar();
  }, 
  // eslint-disable-next-line
  []);

  return (
    <ErpTemplate>
      <div className="main-content container-fluid">
        <div className="page-title">
          <h3>Gráficos</h3>
        </div>
        <section id="multiple-column-form">
          <div className="row match-height">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        {carregadoLineChart ?
                          <Chart
                            options={dadosLineChart.options}
                            series={dadosLineChart.series}
                            type="area"
                            height="400"
                          /> : <div>Carregando...</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        {carregadoPieChart ?
                          <Chart
                            options={dadosPieChart.options}
                            series={dadosPieChart.series}
                            type="pie"
                            height="400"
                          /> : <div>Carregando...</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ErpTemplate>
  );
}
