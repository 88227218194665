import React, { useEffect, useState } from 'react';
import IconButton from './IconButton';
import DropDown from './DropDown';
import { FaBan, FaBell, FaCheck, FaExclamationTriangle, FaInfo } from 'react-icons/fa';
import api from '../services/api';
import { limitaTexto } from '../util/util';
import Alert from './Alert';

export default function Notificacoes() {
    const token = localStorage.getItem('token');
    const [notificacoes, setNotificacoes] = useState([]);
    const [dados, setDados] = useState({ temNovas: false });

    const mostraAlerta = (notificacao) => {
        var iconeAlerta = "info";
        if (notificacao.tipo === 'informacao') {
            iconeAlerta = "info";
        };
        if (notificacao.tipo === 'atencao') {
            iconeAlerta = "warning";
        };
        if (notificacao.tipo === 'erro') {
            iconeAlerta = "error";
        };
        if (notificacao.tipo === 'sucesso') {
            iconeAlerta = "success";
        };

        Alert({
            callBack: (result) => {
                //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                if (result.isConfirmed) {
                    api({
                        method: 'put',
                        action: '/notificacao/' + notificacao.id,
                        token: token,
                        data: {
                            situacao: "lido"
                        }
                    })
                        .then(function (response) {
                            //alert("Evento cancelado com sucesso!");
                        })
                        .catch(function (error) {
                            //alert(error)
                        });
                }
            },
            icon: iconeAlerta,
            html: notificacao.texto,
        });

    };

    const buscar = () => {
        api({
            method: 'get',
            action: '/notificacao',
            token: token,
        })
            .then(response => {
                var notificacoes = response.data.data;
                var agora = new Date();
                for (const notificacao of notificacoes) {
                    var dataNotificacao = new Date(notificacao.data);
                    if (notificacao.situacao === "nova") {
                        if (dataNotificacao - agora < 600000) { //10 minutos
                            mostraAlerta(notificacao);
                        }
                        setDados({ ...dados, ...{ temNovas: true } });
                    }
                }
                setNotificacoes(notificacoes);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <li key={"notificacoes"}>
            <DropDown
                icon={<FaBell color={dados.temNovas ? '#FF0000' : "#727e8c"} />}
                opcoes={
                    notificacoes ? notificacoes.map((notificacao, index) => {
                        var icone = <FaBell size={20} />;
                        var cor = "default";
                        if (notificacao.tipo === 'informacao') {
                            icone = <FaInfo size={20} />
                            cor = "info";
                        };
                        if (notificacao.tipo === 'atencao') {
                            icone = <FaExclamationTriangle size={20} />
                            cor = 'warning';
                        };
                        if (notificacao.tipo === 'erro') {
                            icone = <FaBan size={20} />
                            cor = 'danger';
                        };
                        if (notificacao.tipo === 'sucesso') {
                            icone = <FaCheck size={20} />
                            cor = 'success';
                        };
                        return <IconButton
                            style={{ width: "100%", marginTop: "4px", textAlign: "left" }}
                            color={notificacao.situacao === "nova" ? cor : "default"}
                            key={index}
                            className="dropdown-item"
                            small={true}
                            onClick={() => {
                                mostraAlerta(notificacao);
                            }}
                            icon={icone}>{limitaTexto(notificacao.texto)}</IconButton>
                    }) : null}
            ></DropDown>
        </li>
    );
}