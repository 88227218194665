import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import { FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import { dataMysql, extract } from '../../util/util';
import api from '../../services/api';
import logo from '../../images/logo.svg';
import './styles.css';
import { toast } from 'react-toastify';

export default function Login() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const history = useHistory();

  async function handleLogin(e) {
    e.preventDefault();

    try {
      const response = await api({
        method: 'post',
        action: '/login',
        data: { email, senha }
      });
      var dadosRecebidos = extract({
        jsonLaravel: response.data,
        chaves: ['tiposla']
      });

      //if (dadosRecebidos.empresa) {
      //  localStorage.setItem('empresa', dadosRecebidos.tiposla);
      //  localStorage.setItem('nomeTiposla', dadosRecebidos.nomeTiposla);
      //}

      if (dadosRecebidos.cargo && dadosRecebidos.cargo.perfil) {
        localStorage.setItem('perfil', dadosRecebidos.cargo.perfil.codigo);
      } else {
        localStorage.removeItem('perfil');
      }

      if (dadosRecebidos.tiposla) {
        localStorage.setItem('tiposla', dadosRecebidos.tiposla);
        localStorage.setItem('nomeTiposla', dadosRecebidos.nomeTiposla);
      } else {
        localStorage.removeItem('tiposla');
        localStorage.removeItem('nomeTiposla');
      }
      localStorage.setItem('idUsuario', dadosRecebidos.id);
      localStorage.setItem('nomeUsuario', dadosRecebidos.nome);
      localStorage.setItem('token', dadosRecebidos.api_token);
      if (localStorage.getItem('perfil') === 'operadorrsvp') { //tela de operador
        history.push('/cadastroEventoParticipanteOperador');
      } else if (localStorage.getItem('perfil') === 'listarsvp') { //tela de lista digital
        history.push('/evento');
      } else if (['briefing', 'cliente'].includes(localStorage.getItem('perfil'))) { //tela de briefing para clientes
        history.push('/briefingCliente');
      } else { //todos os outros usuarios normais
        history.push('/principal');
      }
    } catch (err) {
      toast.error('Falha no login, tente novamente');
    }
  }

  return (
    <div id="auth">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card pt-4">
              <div className="card-body">
                <div className="text-center mb-5">
                  <img alt="" src={logo} height="48" className='mb-4' />
                  <p>Por favor informe seu email e senha</p>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="form-group position-relative has-icon-left">
                    <label htmlFor="nome">E-mail</label>
                    <div className="position-relative">
                      <input type="text" className="form-control" id="nome"
                        value={email}
                        onChange={e => setEmail(e.target.value)} />
                      <div className="form-control-icon">
                        <FaEnvelope />
                      </div>
                    </div>
                  </div>
                  <div className="form-group position-relative has-icon-left">
                    <div className="clearfix">
                      <label htmlFor="senha">Senha</label>
                    </div>
                    <div className="position-relative">
                      <input type="password" className="form-control" id="senha"
                        value={senha}
                        onChange={e => setSenha(e.target.value)} />
                      <div className="form-control-icon">
                        <FaLock />
                      </div>
                    </div>
                  </div>
                  <div className='form-check clearfix my-4'>
                    <div className="float-end">
                      <Link to="/registro">
                        <FiLogIn />Não tenho cadastro
                      </Link>
                    </div>
                  </div>
                  <div className="clearfix">
                    <button className="btn btn-primary float-end" type="submit">Entrar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
