import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadButton from '../../components/UploadButton';
import { FaFileDownload, FaUpload } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import api from '../../services/api';
import FormButton from '../../components/FormButton';

export default function ImportarOrcamento({ match }) {
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const [arquivos, setArquivos] = useState([]);
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    let { state } = useLocation();
    var nomeEvento = state?.nomeEvento ?? null;
    const [dados, setDados] = useState({
        empresa: empresa,
        nomeEmpresa: nomeEmpresa
    });
    const history = useHistory();

    /*
    const buscar = (idParticipante) => {
        api({
            method: "get",
            action: '/eventoArquivo',
            token: token,
            params: {
                tipo: "aereo",
                evento: id,
            }
        }).then(response => {
            var dadosRecebidos = response.data.data;
            setArquivos(dadosRecebidos);
        }).catch(err => {
            //console.log(err)
        });
    };
    */

    useEffect(() => { //onload
        //buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Importar Orçamento</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <UploadButton
                                                        icon={<FaUpload size={20} />}
                                                        color="primary"
                                                        acaoApi={"orcamentoImportar"}
                                                        title="Selecionar Arquivo"
                                                        beforeSend={() => {
                                                            toast.info('Aguarde, isso pode levar alguns minutos');
                                                        }}
                                                        callBack={(data) => {
                                                            if (data.result === "sucesso") {
                                                                toast.success('Importação finalizada');
                                                                history.push("/orcamento/");
                                                            } else {//error
                                                                toast.error(data.message);
                                                            }
                                                        }}
                                                        dados={{
                                                            empresa: empresa,
                                                        }}
                                                    >Selecionar Arquivo</UploadButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}