import React, { useState, useEffect } from 'react';
import { FaBuilding, FaUser, FaPen, FaGripVertical, FaBell } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import logo from '../images/logo.svg';
import api from '../services/api';
import IconButton from '../components/IconButton';
import DropDown from '../components/DropDown';
import Notificacoes from './Notificacoes';

export default function NavBar() {
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const logomarcaEvento = localStorage.getItem("logomarcaEvento");
    const [userMenu, setUserMenu] = useState(false);
    const [empresas, setEmpresas] = useState(JSON.parse(localStorage.getItem("empresas") ?? "[]"));
    const [empresaAtual, setEmpresaAtual] = useState({ id: localStorage.getItem("empresa"), nome: localStorage.getItem("nomeEmpresa") });
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("empresa");
        localStorage.removeItem("empresas");
        history.push('/login');
    }
    const buscar = () => {
        api({
            method: 'get',
            action: '/participante',
            token: token,
            params: {
                ...{
                    orderBy: {
                        nome: "desc"
                    }
                },
                ...{
                    tipoCadastro: "empresa",
                    situacaoRegistro: "ativo"
                }
            }
        })
            .then(response => {
                if (localStorage.getItem("empresa") === null) {
                    localStorage.setItem("empresa", response.data.data[0].id);
                    localStorage.setItem("nomeEmpresa", response.data.data[0].nomefantasia || response.data.data[0].nome);
                    empresaAtual.id = response.data.data[0].id;
                    empresaAtual.nome = response.data.data[0].nomefantasia || response.data.data[0].nome;
                    localStorage.setItem("empresas", JSON.stringify(response.data.data));
                }
                setEmpresas(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => { //onload
        var empresasArray = JSON.parse(localStorage.getItem("empresas") ?? "{}");
        if ((localStorage.getItem("empresa") === null)
            || (empresasArray.length === 0)) {
            buscar();
        }
    },
        // eslint-disable-next-line
        []);

    return (
        <nav className="navbar navbar-header navbar-expand navbar-light no-print" onMouseLeave={() => setUserMenu(false)}>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="sidebar-header">
                    <img alt="" src={(['cliente'].includes(perfil) && logomarcaEvento) ? logomarcaEvento : logo} height="50px" />
                </div>
                <ul className="navbar-nav d-flex align-items-center navbar-light ms-auto">
                    {!['cliente'].includes(perfil) ? <Notificacoes key={"notificacoes"} /> : null}
                    {!['cliente'].includes(perfil) ?
                        <li key={"empresas"}>
                            <DropDown
                                icon={<FaBuilding />}
                                opcoes={
                                    empresas ? empresas.map((opcao, index) => {
                                        return <IconButton
                                            key={index}
                                            className="dropdown-item"
                                            small={true}
                                            onClick={() => {
                                                localStorage.setItem("nomeEmpresa", opcao.nomefantasia || opcao.nome);
                                                localStorage.setItem("empresa", opcao.id);
                                                setEmpresaAtual({ id: opcao.id, nome: opcao.nomefantasia || opcao.nome });
                                            }}
                                            icon={<FaBuilding size={20} />}
                                            color="default">{opcao.nomefantasia || opcao.nome}</IconButton>
                                    }) : null}
                            >{empresaAtual.nome}</DropDown></li> : null}

                    {!['cliente'].includes(perfil) ?
                        <li key={"user"}
                            className="dropdown" onClick={() => setUserMenu(userMenu ? false : true)}>
                            <button data-bs-toggle="dropdown" className="botaoNavLink nav-link dropdown-toggle nav-link-lg nav-link-user">
                                <div className="avatar me-1">
                                    <FaUser />
                                </div>
                                <div className="d-none d-md-block d-lg-inline-block">{localStorage.getItem("nomeUsuario")}</div>
                            </button>
                            {userMenu ?
                                <div className="dropdown-menu dropdown-menu-end show">
                                    <button className="botaoDropdownItem dropdown-item"
                                        onClick={() => logout()}>
                                        <i data-feather="log-out"></i> Sair</button>
                                </div> : false}
                        </li> :
                        <li key={"logout"}>
                            <div
                                className="dropdown-menu dropdown-menu-end show">
                                <button className="botaoDropdownItem dropdown-item"
                                    onClick={() => logout()}>
                                    <i data-feather="log-out"></i> Sair</button>
                            </div>
                        </li>
                    }
                </ul>
            </div>
        </nav>
    );
}