import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaFileInvoice, FaUsers, FaPen, FaPlus, FaSearch, FaThumbsUp, FaThumbsDown, FaUpload, FaList, FaTable, FaBackward, FaEye } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import { ImCancelCircle } from 'react-icons/im';
import NavItem from '../../../components/NavItem';
import IconButton from '../../../components/IconButton';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import Alert from '../../../components/Alert';
import FormButton from '../../../components/FormButton';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import ParticipantesConfirmados from '../../../components/ParticipantesConfirmados';
import { data, dataHora } from '../../../util/util';

export default function PainelEventoPalestrante({ match }) {
    let { search, state } = useLocation();
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [eventoParticipantes, setEventoParticipantes] = useState([]);
    const [evento, setEvento] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const operador = state?.operador ?? null;

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });

        //busca dados do evento
        api({
            method: 'get',
            action: '/evento',
            token: token,
            params: {
                ...{ id: id }
            }
        })
            .then(response => {
                var evento = response.data.data[0];
                setEvento(evento);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            });

        //busca participantes do evento
        api({
            method: 'get',
            action: '/participante',
            token: token,
            params: {
                ...filtro,
                ...{
                    evento: id,
                    tipoCadastro: ["participante", "palestrante"],
                    tipoparticipante: "3", //3 - palestrante
                }
            }
        })
            .then(response => {
                setEventoParticipantes(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
                (['diretor', 'rsvp'].includes(perfil)) ? //apenas gerente financeiro 
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={
                            () => {
                                Alert({
                                    icon: "error",
                                    confirmButton: "Sair",
                                    denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                    title: "Excluir",
                                    callBack: (result) => {
                                        //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                        if (result.isDenied) {
                                            api({
                                                method: 'delete',
                                                action: '/participantes',
                                                token: token,
                                                data: {
                                                    idParticipantes: ids
                                                }
                                            })
                                                .then(function (response) {
                                                    buscar();
                                                    //alert("Evento cancelado com sucesso!");
                                                })
                                                .catch(function (error) {
                                                    //alert(error)
                                                });
                                        }
                                    }
                                });
                            }
                        }
                        color="primary"> Excluir</IconButton > : null,
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate sidebar={['listarsvp'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem
                        to={{
                            pathname: "/cadastroEventoPalestrante",
                            state: {
                                evento: id
                            }
                        }}
                        icon={<FaPlus size={20} />}
                        color="primary">Novo Palestrante</NavItem>
                </ul>
                <div className="page-title">
                    <h3>{evento.nome}</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input autoFocus type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome do evento"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome do Palestrante</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            title={"Palestrantes"}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows={!['listarsvp'].includes(perfil)}
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent={['listarsvp'].includes(perfil) ? "Busque para listar" : "Nenhum registro"}
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        return <IconButton
                                                            small={true}
                                                            to={{
                                                                pathname: "/cadastroEventoPalestrante/" + row.id,
                                                                state: {
                                                                    evento: id
                                                                }
                                                            }}
                                                            icon={<FaPen size={20} />}
                                                            color="primary">Alterar</IconButton>;
                                                    },
                                                    width: "120px",
                                                },
                                                {
                                                    name: 'Nome',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        if (['listarsvp'].includes(perfil)) {
                                                            return <div>{row.nome}</div>
                                                        } else {
                                                            return <div>{row.codigo}-{row.nome} - {row.cnpj}{row.cpf}</div>
                                                        }
                                                    },
                                                },
                                            ]}
                                            data={eventoParticipantes}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate >
    );
}
