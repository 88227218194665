import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { virgulaPonto, pontoVirgula, extract } from '../../util/util';

export default function CadastroProduto({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState([]);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/produto/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'superior',
                        'grupo',
                        'tipoatributo',
                        'atributo'
                    ]
                });

                //transforma dados no formato aceito pelo reactselect
                if (dadosRecebidos.cliente?.constructor !== Array)
                    dadosRecebidos.cliente = [];
                dadosRecebidos.cliente = dadosRecebidos.cliente.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                if (dadosRecebidos.somarExceto?.constructor !== Array)
                    dadosRecebidos.somarExceto = [];
                dadosRecebidos.somarExceto = dadosRecebidos.somarExceto.map(function (objeto) {
                    return {
                        value: objeto.id,
                        label: objeto.nome
                    };
                });

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {

        if (dados.cliente && Array.isArray(dados.cliente)) {
            dados.cliente = dados.cliente.map(item => item.value).join(',');
        }

        if (dados.somarExceto && Array.isArray(dados.somarExceto)) {
            dados.somarExceto = dados.somarExceto.map(item => item.value).join(',');
        }

        if (id) {//update
            api({
                method: 'put',
                action: '/produto/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/servicoOrcamento");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/produto',
                token: token,
                data: {
                    ...dados,
                    ...{ empresa: empresa },
                    ...{ modulo: 'servicoOrcamento' },
                }
            })
                .then(function (response) {
                    history.push("/servicoOrcamento");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Item Orçamentário</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Situação</label>
                                                        <ReactSelect
                                                            value={{ value: dados.situacaoRegistro, label: dados.nomeSituacaoRegistro }}
                                                            onChange={e => setDados({ ...dados, ...{ "situacaoRegistro": e.value, "nomeSituacaoRegistro": e.label } })}
                                                            options={[
                                                                { value: 'ativo', label: 'Ativo' },
                                                                { value: 'inativo', label: 'Inativo' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigo" placeholder="Código"
                                                            value={dados.codigo}
                                                            onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Código</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigoFornecedor" placeholder="Código do Fornecedor"
                                                            value={dados.codigoFornecedor}
                                                            onChange={e => setDados({ ...dados, ...{ "codigoFornecedor": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Código do Fornecedor</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Grupo Orçamentário</label>
                                                        <ReactSelect
                                                            value={{ value: dados.superior, label: dados.nomeSuperior }}
                                                            onChange={e => setDados({ ...dados, ...{ "superior": e.value, "nomeSuperior": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produto',
                                                                    token: token,
                                                                    params: {
                                                                        situacaoRegistro: 'ativo',
                                                                        nome: input,
                                                                        modulo: "servicoOrcamento",
                                                                        superiorIsNull: true,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="ordem" placeholder="Ordem"
                                                            value={dados.ordem}
                                                            onChange={e => setDados({ ...dados, ...{ "ordem": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Ordem</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Grupo Atributo</label>
                                                        <ReactSelect
                                                            value={{ value: dados.grupo, label: dados.nomeGrupo }}
                                                            onChange={e => setDados({ ...dados, ...{ "grupo": e.value, "nomeGrupo": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produtoGrupo',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Tipo Atributo</label>
                                                        <ReactSelect
                                                            value={{ value: dados.tipoatributo, label: dados.nomeTipoatributo }}
                                                            onChange={e => setDados({ ...dados, ...{ "tipoatributo": e.value, "nomeTipoatributo": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produtoTipoAtributo',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Atributo</label>
                                                        <ReactSelect
                                                            value={{ value: dados.atributo, label: dados.nomeAtributo }}
                                                            onChange={e => setDados({ ...dados, ...{ "atributo": e.value, "nomeAtributo": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produtoAtributo',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="preco" placeholder="Preço"
                                                            value={pontoVirgula(dados.preco)}
                                                            onChange={e => setDados({ ...dados, ...{ "preco": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Preço</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="quantidade" placeholder="Quantidade ou %"
                                                            value={pontoVirgula(dados.quantidade)}
                                                            onChange={e => setDados({ ...dados, ...{ "quantidade": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Quantidade ou %</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Fixo nos Orçamentos</label>
                                                        <ReactSelect
                                                            id="mostrarOrcamento"
                                                            value={{ value: dados.mostrarOrcamento, label: dados.nomeMostrarOrcamento }}
                                                            onChange={e => setDados({ ...dados, ...{ "mostrarOrcamento": e.value, "nomeMostrarOrcamento": e.label } })}
                                                            options={[
                                                                { value: 'nao', label: 'Não' },
                                                                { value: 'sim', label: 'Sim' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Categoria</label>
                                                        <ReactSelect
                                                            id="categoria"
                                                            value={{ value: dados.categoria, label: dados.nomeCategoria }}
                                                            onChange={e => setDados({ ...dados, ...{ "categoria": e.value, "nomeCategoria": e.label } })}
                                                            options={[
                                                                { value: 'criacao', label: 'Criação' },
                                                                { value: 'digital', label: 'Digital' },
                                                                { value: 'presencial', label: 'Presencial' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            isMulti={true}
                                                            value={dados.cliente}
                                                            onChange={(e) => {
                                                                dados.cliente = dados.cliente || [];
                                                                dados.cliente = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "cliente",
                                                                        situacaoRegistro: "ativo"
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Somar todos os itens exceto</label>
                                                        <ReactSelect
                                                            isMulti={true}
                                                            value={dados.somarExceto}
                                                            onChange={(e) => {
                                                                dados.somarExceto = dados.somarExceto || [];
                                                                dados.somarExceto = e;
                                                                setDados({ ...dados });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produto',
                                                                    token: token,
                                                                    params: {
                                                                        situacaoRegistro: 'ativo',
                                                                        nome: input,
                                                                        modulo: 'servicoOrcamento',
                                                                        superiorIsNull: true
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            return { value: registro.id, label: registro.codigo + ' - ' + registro.nome };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="descritivo1" placeholder="descritivo1"
                                                            value={dados.descritivo1}
                                                            onChange={e => setDados({ ...dados, ...{ "descritivo1": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">descritivo1</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="descritivo2" placeholder="descritivo2"
                                                            value={dados.descritivo2}
                                                            onChange={e => setDados({ ...dados, ...{ "descritivo2": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">descritivo2</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="descritivo3" placeholder="descritivo3"
                                                            value={dados.descritivo3}
                                                            onChange={e => setDados({ ...dados, ...{ "descritivo3": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">descritivo3</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}