import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaChartPie, FaCodeBranch, FaPen, FaSearch } from 'react-icons/fa';
import { GiStairsGoal } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactSelect from '../../components/ReactSelect';
import FormButton from '../../components/FormButton';
import Data from '../../components/Data';

export default function PainelSla() {
    const token = localStorage.getItem('token');
    const idTiposla = localStorage.getItem('tiposla');
    const nomeTiposla = localStorage.getItem('nomeTiposla');
    const empresa = localStorage.getItem("empresa");
    const [filtro, setFiltro] = useState({
        empresa: empresa,
        modelo: "nao",
        "tipo": idTiposla,
        "nomeTipo": nomeTiposla
    });
    const [slas, setSlas] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);

    const buscar = () => {
        setFiltro({...filtro,...{buscando: true}});
        api({
            method: 'get',
            action: '/sla',
            token: token,
            params: filtro
        })
            .then(response => {
                setSlas(response.data.data);
                setFiltro({...filtro,...{buscando: false}});
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    useEffect(() => { //onload
        buscar();
    }, 
    // eslint-disable-next-line
    [filtro.tipo, filtro.evento]);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";

        if (tableSelect.selectedCount > 0) {
            setTableActions([
                <FormButton
                    method="post"
                    action={process.env.REACT_APP_API_URL + "/slaCompromissoData/resultadosExcelHtml"}
                    icon={<GiStairsGoal size={20} />}
                    color="primary"
                    data={{
                        token: localStorage.getItem('token'),
                        rows: tableSelect.selectedRows.map((row) => {
                            return row.id;
                        })
                    }}
                >Resultados</FormButton>,
                <FormButton
                    method="post"
                    action={process.env.REACT_APP_API_URL + "/slaCompromissoData/resultadosExcel"}
                    icon={<GiStairsGoal size={20} />}
                    color="primary"
                    data={{
                        token: localStorage.getItem('token'),
                        rows: tableSelect.selectedRows.map((row) => {
                            return row.id;
                        })
                    }}
                >Resultados Excel</FormButton>,
                <IconButton to={"/slaGrafico/" + ids} icon={<FaChartPie size={20} />} color="primary">Gráficos</IconButton>
            ]);
        } else {
            setTableActions([]);
        }
    }, 
    // eslint-disable-next-line
    [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/slaModelo" icon={<FaCodeBranch size={20} />} color="outline-dark">Modelos de SLA</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Controle de Sla</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.evento, label: filtro.nomeEvento }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado", "cancelado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.tipo, label: filtro.nomeTipo }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "tipo": e.value, "nomeTipo": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/slaTipo',
                                                                    token: token
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data Início"
                                                        value={filtro.dataInicio1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataInicio1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="até a Data"
                                                        value={filtro.dataInicio2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataInicio2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data Término"
                                                        value={filtro.dataFim1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataFim1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="até a Data"
                                                        value={filtro.dataFim2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataFim2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data SLA Eventos"
                                                        value={filtro.dataCompetencia1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataCompetencia1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="até a Data"
                                                        value={filtro.dataCompetencia2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataCompetencia2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data Fechamento"
                                                        value={filtro.dataFechamento1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataFechamento1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="até a Data"
                                                        value={filtro.dataFechamento2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataFechamento2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows="true"
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                /*
                                                {
                                                    name: '',
                                                    cell: row => <DropDown
                                                        icon={<FaGripVertical />}
                                                        opcoes={[
                                                            <IconButton
                                                                className="dropdown-item"
                                                                small={true}
                                                                to={"/cadastroSla/" + row.id}
                                                                icon={<FaPen size={20} />}
                                                                color="default">Alterar</IconButton>,
                                                            <IconButton
                                                                className="dropdown-item"
                                                                small={true}
                                                                to={"/cadastroSla/" + row.id}
                                                                icon={<FaPen size={20} />}
                                                                color="default">Copiar</IconButton>,
                                                        ]} />,
                                                    allowOverflow: false,
                                                    width: "120px",
                                                },
                                                */
                                                {
                                                    name: '',
                                                    selector: row => row.codigo,
                                                    cell: row => <IconButton
                                                        small={true}
                                                        to={"/cadastroSla/" + row.id}
                                                        icon={<FaPen size={20} />}
                                                        color="primary">Preencher</IconButton>,
                                                    width: "110px",
                                                },
                                                {
                                                    name: 'Tipo',
                                                    selector: row => row.tipo?.nome ?? "",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Data SLA Eventos',
                                                    selector: row => row.evento?.dataCompetencia ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.evento ? moment(row.evento.dataCompetencia).format("MM/YYYY") : ""
                                                    },
                                                },
                                                {
                                                    name: 'Data Início',
                                                    selector: row => row.evento?.replanejadoInicio ?? row.evento?.data ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        var dataInicio = row.evento.data ? " " + moment(row.evento.data).format("DD/MM/YYYY") : "";
                                                        dataInicio = row.evento.replanejadoInicio ? " " + moment(row.evento.replanejadoInicio).format("DD/MM/YYYY") : dataInicio;
                                                        return dataInicio;
                                                    },
                                                },
                                                {
                                                    name: 'Data Fim',
                                                    selector: row => row.evento?.replanejadoFim ?? row.evento?.dataFim ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        var dataFim = row.evento.dataFim ? " " + moment(row.evento.dataFim).format("DD/MM/YYYY") : "";
                                                        dataFim = row.evento.replanejadoFim ? " " + moment(row.evento.replanejadoFim).format("DD/MM/YYYY") : dataFim;
                                                        return dataFim;
                                                    },
                                                },
                                                {
                                                    name: 'Data Fechamento',
                                                    selector: row => row.evento?.dataFechamento ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        var dataFechamento = row.evento.dataFechamento ? " " + moment(row.evento.dataFechamento).format("DD/MM/YYYY") : "";
                                                        return dataFechamento;
                                                    },
                                                },
                                                {
                                                    name: 'Evento',
                                                    selector: row => row.evento?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        if (row.evento.identificacao) {
                                                            return row.evento ? row.evento.codigo + ' - ' + row.evento.identificacao + " - " + row.evento.nome : ""
                                                        } else {
                                                            return row.evento ? row.evento.codigo + ' - ' + row.evento.nome : ""
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Cliente',
                                                    selector: row => row.evento?.cliente?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.evento && row.evento.cliente ? row.evento.cliente.nome : ""
                                                    },
                                                },
                                                {
                                                    name: 'Diretriz',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                },
                                            ]}
                                            data={slas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
