import React, { useRef } from 'react';
import axios, { post } from 'axios';
import IconButton from '../components/IconButton';

const UploadButton = React.forwardRef(({ beforeSend = null, callBack = null, small = false, icon, color, children = null, title = "", acaoApi, dados = {} }, ref) => {
    const token = localStorage.getItem('token');
    const internalRef = useRef(null);
    const fileRef = ref || internalRef;

    const fileUpload = file => {
        const tokenObj = token ? { 'Authorization': `Bearer ${token}` } : {};
        const url = process.env.REACT_APP_API_URL + '/' + acaoApi;
        const formData = new FormData();
        formData.append('file', file);
        for (const [key, value] of Object.entries(dados)) {
            formData.append(key, value);
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                ...tokenObj
            }
        };
        if (beforeSend) beforeSend();
        return post(url, formData, config);
    };

    const handleFileUpload = event => {
        fileUpload(event.target.files[0]).then((response) => {
            if (callBack) callBack(response.data.data);
        }).catch((error) => {
            if (callBack) callBack(error.response.data);
        });
    };

    return (
        <>
            <input
                ref={fileRef}
                onChange={handleFileUpload}
                type="file"
                style={{ display: "none" }}
                multiple={false}
            />
            <IconButton
                small={small}
                icon={icon}
                color={color}
                title={title}
                onClick={() => fileRef.current.click()}
            >
                {children}
            </IconButton>
        </>
    );
});

export default UploadButton;
