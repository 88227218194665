import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import logo from '../../../images/logo.svg';
import '../../Login/styles.css';
import IconButton from '../../../components/IconButton';
import { FaExternalLinkAlt, FaLink } from 'react-icons/fa';

export default function CadastroEventoParticipanteConviteConcluido({ match }) {
  const id = match.params.id;
  const token = localStorage.getItem('token');
  const [evento, setEvento] = useState({});
  const history = useHistory();

  const buscar = () => {
    api({
      method: "get",
      action: '/eventoSemLogin/' + id,
    }).then(response => {
      var dadosEvento = response.data.data;
      setEvento(dadosEvento);
    }).catch(err => {
      //console.log(err)
    });
  };//buscar

  /*
  const salvar = () => {
    api({
      method: 'put',
      action: '/participante/concluirConfirmacaoDados/' + id,
      token: token,
    })
      .then(function (response) {
      })
      .catch(function (error) {
        //alert(error)
      });
  }; //salvar
  */

  useEffect(() => { //onload
    buscar();
    //salvar();
  },
    // eslint-disable-next-line
    []);

  return (
    <div id="auth" style={{
      backgroundImage: "none",
      backgroundColor: evento?.cor ?? "#AAAAAA",
    }}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card pt-4">
              <div className="card-body">
                <div className="text-center mb-5">
                  {localStorage.getItem('logomarcaEvento') ?
                    <img alt="" src={localStorage.getItem('logomarcaEvento')} height="48" className='mb-4' /> :
                    <img alt="" src={logo} height="48" className='mb-4' />
                  }
                  <p>
                    Sua presença está confirmada para o evento <strong>{evento?.nome ?? ""}</strong>, em breve enviaremos mais detalhes sobre sua participação.<br />
                    Caso haja qualquer alteração nas informações ou dúvidas para o andamento do processo, estamos a disposição nos contatos abaixo:</p>
                  <p>Grupo Access-Mkt - Horário de atendimento de Segunda a Sexta das 09:00 às 18:00<br />
                    Telefone: (11) 3777-8414</p>
                  {((evento.linkfinalcadastro ?? "") !== '') ?
                    <>
                      <p>clique abaixo e acesse o material de apoio</p>
                      <IconButton
                        target="_blank"
                        title="Acessar Conteúdo"
                        color="primary"
                        icon={<FaExternalLinkAlt size={20} />}
                        to={{
                          pathname: evento.linkfinalcadastro,
                        }}>Acessar Conteúdo</IconButton>
                    </>
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
