import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaUpload } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadButton from '../../components/UploadButton';

export default function EventoImportarSanofi({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    //dataCompetencia: new Date(),
    const [dados, setDados] = useState({
        empresa: empresa,
        nomeEmpresa: nomeEmpresa
    });
    const history = useHistory();

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Importar Planilha Sanofi</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <UploadButton
                                                        icon={<FaUpload size={20} />}
                                                        color="primary"
                                                        acaoApi={"eventoImportarSanofi"}
                                                        title="Selecionar Arquivo"
                                                        beforeSend={() => {
                                                            toast.info('Aguarde, isso pode levar alguns minutos');
                                                        }}
                                                        callBack={(data) => {
                                                            if (data.result === "sucesso") {
                                                                toast.success('Importação finalizada');
                                                                history.push("/evento");
                                                            } else {//error
                                                                toast.error(data.message);
                                                            }
                                                        }}
                                                        dados={{
                                                            empresa: empresa
                                                        }}
                                                    >Selecionar Arquivo</UploadButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}