import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import QuantidadeEventos from '../../components/QuantidadeEventos';
import MediaExecucao from '../../components/MediaExecucao';
import MediaExecucaoRadial from '../../components/MediaExecucaoRadial';
import CompromissosBarras from '../../components/CompromissosBarras';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

export default function PainelSlaGrafico() {
    const { ids } = useParams();
    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="col-sm-12 d-flex justify-content-end">
                    <button onClick={() => {
                        var node_graficos = document.getElementById('node-graficos');
                        node_graficos.style.backgroundColor = 'white';
                        node_graficos.style.padding = '20px';
                        htmlToImage.toPng(node_graficos)
                            .then(function (dataUrl) {
                                download(dataUrl, 'Graficos.png');
                                node_graficos.style.backgroundColor = 'transparent';
                                node_graficos.style.padding = '0';
                            });
                    }} className="btn btn-default me-1 mb-1">
                        <FaDownload size={20} /> Baixar</button>
                </div>
                <div id='node-graficos'>
                    <div className="page-title">
                        <h3>Gráficos</h3>
                    </div>
                    <section className="section">
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-3">
                                <QuantidadeEventos ids={ids} />
                                <QuantidadeEventos ids={ids} chaveArray="cancelados" label="Cancelados" />
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <QuantidadeEventos ids={ids} chaveArray="replanejados" label="Replanejados" />
                                <MediaExecucao ids={ids} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <MediaExecucaoRadial ids={ids} />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12 col-md-6">
                                <CompromissosBarras ids={ids} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </ErpTemplate>
    );
}
