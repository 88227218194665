import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { extract } from '../../util/util';
import api from '../../services/api';
import logo from '../../images/logo.svg';
import '../Login/styles.css';
import { toast } from 'react-toastify';

export default function LoginEvento({ match }) {
  const id = match.params.id;
  const [evento, setEvento] = useState({});
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const buscar = () => {
    api({
      method: "get",
      action: '/eventoSemLogin/' + id,
    }).then(response => {
      var dadosEvento = response.data.data;
      if (dadosEvento.logomarca?.link) {//existe logomarca no evento
        localStorage.setItem('logomarcaEvento', dadosEvento.logomarca.link);
      } else if (dadosEvento.cliente?.logomarca?.link) { //existe logomarca no cliente do evento
        localStorage.setItem('logomarcaEvento', dadosEvento.cliente.logomarca.link);
      }
      setEvento(dadosEvento);
    }).catch(err => {
      //console.log(err)
    });
  };//buscar

  useEffect(() => { //onload
    buscar();
  },
    // eslint-disable-next-line
    []);

  async function salvar(e) {
    e.preventDefault();
    try {
      const response = await api({
        method: 'post',
        action: '/loginListaDigital/' + id,
      });

      var dadosRecebidos = response.data;

      localStorage.setItem('perfil', "cliente");
      localStorage.setItem('idUsuario', dadosRecebidos.id);
      localStorage.setItem('nomeUsuario', dadosRecebidos.nome);
      localStorage.setItem('token', dadosRecebidos.api_token);

      history.push({ //se nao passar o id do cliente é pra ele se cadastrar na lista digital
        pathname: "/cadastroEventoParticipanteLista",
        state: {
          evento: id
        }
      });

    } catch (error) {
      toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
    }
  } //salvar

  return (
    <div id="auth" style={
      (evento.cor !== undefined) ? {
        backgroundImage: "none",
        backgroundColor: evento.cor,
      } : {
        backgroundImage: "none",
        backgroundColor: "#AAAAAA",
      }
    }>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card pt-4">
              <div className="card-body">
                <div className="text-center mb-5">
                  {localStorage.getItem('logomarcaEvento') ?
                    <img alt="" src={localStorage.getItem('logomarcaEvento')} height="48" className='mb-4' /> :
                    <img alt="" src={logo} height="48" className='mb-4' />
                  }
                  <p>Bem vindo ao evento <strong>{evento.nome}</strong>!</p>
                </div>
                <form onSubmit={salvar}>
                  <div className="clearfix">
                    <center>
                      <button className="btn btn-primary" type="submit">Iniciar Cadastro</button>
                    </center>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
