import React from 'react';
import { Link } from 'react-router-dom';

export default function IconButton({
    className = "",
    icon,
    to = null,
    onClick = null,
    children,
    color,
    small = false,
    type = "button",
    dropdown = false,
    title = "",
    target = null,
    style = null,
}) {
    color = color ?? "default";
    var classSmall = small ? " btn-sm" : "";
    var classDropDown = dropdown ? "dropdown-toggle" : "";
    if (to) {
        return (
            <Link style={style} target={target} title={title} to={to} className={"btn icon icon-left btn-" + color + classSmall + " " + className}>
                {icon}{children}
            </Link>
        );
    } else {
        return (
            <button style={style} title={title} type={type} onClick={onClick} className={"btn icon icon-left btn-" + color + classSmall + " " + classDropDown}>
                {icon}{children}
            </button>
        );
    }
}