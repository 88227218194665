import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import ReactSelect from '../../components/ReactSelect';
import { useHistory } from 'react-router-dom';
import { pontoVirgula } from '../../util/util';

export default function CadastroEmpresa({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/participante/' + id,
                token: token,
            }).then(response => {
                //console.log(response.data.data[0]);
                setDados(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {
        if (id) {//update

            delete dados.tipoparticipante;

            api({
                method: 'put',
                action: '/participante/' + id,
                token: token,
                data: dados,
            })
                .then(function (response) {
                    history.push("/empresa");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/participante',
                token: token,
                data: { ...dados, ...{ tipoCadastro: "empresa" } }
            })
                .then(function (response) {
                    history.push("/empresa");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Empresa</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label>Situação</label>
                                                        <ReactSelect
                                                            value={{ value: dados.situacaoRegistro, label: dados.nomeSituacaoRegistro }}
                                                            onChange={e => setDados({ ...dados, ...{ "situacaoRegistro": e.value, "nomeSituacaoRegistro": e.label } })}
                                                            options={[
                                                                { value: 'ativo', label: 'Ativo' },
                                                                { value: 'inativo', label: 'Inativo' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nomefantasia" placeholder="Nome Fantasia"
                                                            value={dados.nomefantasia}
                                                            onChange={e => setDados({ ...dados, ...{ "nomefantasia": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome Fantasia</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="cnpj" placeholder="CNPJ"
                                                            value={dados.cnpj}
                                                            onChange={e => setDados({ ...dados, ...{ "cnpj": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">CNPJ</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg right" id="issaliquota" placeholder="Alíquota Impostos"
                                                            value={pontoVirgula(dados.issaliquota)}
                                                            onChange={e => setDados({ ...dados, ...{ "issaliquota": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Alíquota Impostos</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}