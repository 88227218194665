import React, { useState, useEffect, useMemo } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaList, FaUpload, FaCalendarAlt, FaPen, FaPlus, FaSearch, FaThumbsUp, FaUsers, FaTable } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../../components/NavItem';
import IconButton from '../../../components/IconButton';
import Alert from '../../../components/Alert';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Data from '../../../components/Data';
import { dataMysql } from '../../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { toast } from 'react-toastify';

export default function PainelChecklist() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({ prazo: "2" });
    const [dados, setDados] = useState({});
    const [checklistItems, setChecklistItems] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const params = new URLSearchParams(window.location.search);

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });

        api({
            method: 'get',
            action: '/eventoChecklist',
            token: token,
            params: {
                ...filtro,
                ...{
                    "naoMostrarModelos": true,
                    "situacao": 1
                },
            }
        })
            .then(response => {
                setChecklistItems(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    /*
useEffect(() => { //onload
    var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
        return row.id;
    }).join(",") : "";
    if (tableSelect.selectedCount > 0) {
        setTableActions([
            (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil)) ?
                <IconButton
                    small={true}
                    to={"/cadastroEvento/" + tableSelect.selectedRows[0].id}
                    icon={<FaPen size={20} />}
                    color="primary">Alterar</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'rsvp'].includes(perfil)) ?
                <IconButton
                    small={true}
                    to={"/cadastroEventoRsvp/" + tableSelect.selectedRows[0].id}
                    icon={<FaPen size={20} />}
                    color="primary">Dados RSVP</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'rsvp', 'listarsvp'].includes(perfil)) ?
                <IconButton
                    small={true}
                    to={"/eventoParticipante/" + tableSelect.selectedRows[0].id}
                    icon={<FaUsers size={20} />}
                    color="primary">Participantes</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentebackoffice', 'backoffice'].includes(perfil)) ?
                <IconButton
                    small={true}
                    icon={<BsCardChecklist size={20} />}
                    onClick={() => {
                        api({
                            method: 'put',
                            action: '/evento/gerarSla/' + tableSelect.selectedRows[0].id,
                            token: token,
                        })
                            .then(function (response) {
                                toast.success('SLA gerado com sucesso');
                                buscar();
                            })
                            .catch(function (error) {
                                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                            });
                    }
                    }
                    color="primary"> Gerar SLA</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil)) ?
                <IconButton
                    small={true}
                    icon={<ImCancelCircle size={20} />}
                    onClick={() => {
                        Alert({
                            icon: "error",
                            confirmButton: "Sair",
                            denyButton: <><FaThumbsUp size={20} /> Fechar</>,
                            title: "Fechar Evento",
                            html: <>
                                <p>Você tem certeza de deseja fechar este evento?</p>
                                <div>Data do fechamento</div>
                                <Data
                                    label="Data"
                                    value={dados.dataFechamento}
                                    onChange={(date) => {
                                        dados.dataFechamento = date;
                                    }}
                                />
                            </>,
                            callBack: (result) => {
                                //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                if (result.isDenied) {
                                    api({
                                        method: 'put',
                                        action: '/evento/' + tableSelect.selectedRows[0].id,
                                        token: token,
                                        data: {
                                            ...{ situacao: "fechado" },
                                            ...{ dataFechamento: dataMysql(dados.dataFechamento) }
                                        }
                                    })
                                        .then(function (response) {
                                            buscar();
                                        })
                                        .catch(function (error) {
                                            //alert(error)
                                        });
                                }
                            }
                        });
                    }
                    }
                    color="primary"> Fechar</IconButton> : null,
            ((tableSelect.selectedRows.length === 1) && //apenas 1 selecionado
                ((tableSelect.selectedRows[0].situacao === 1) || (['diretor', 'gerentefinanceiro'].includes(perfil)))) ? //apenas gerente financeiro 
                <IconButton
                    small={true}
                    icon={<GiOpenChest size={20} />}
                    onClick={() => {
                        Alert({
                            icon: "info",
                            denyButton: "Sair",
                            confirmButton: <><FaThumbsUp size={20} /> Reabrir</>,
                            title: "Reabrir Evento",
                            callBack: (result) => {
                                //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                if (result.isConfirmed) {
                                    api({
                                        method: 'put',
                                        action: '/evento/' + tableSelect.selectedRows[0].id,
                                        token: token,
                                        data: {
                                            ...{ situacao: "ativo" }
                                        }
                                    })
                                        .then(function (response) {
                                            buscar();
                                        })
                                        .catch(function (error) {
                                            //alert(error)
                                        });
                                }
                            }
                        });
                    }
                    }
                    color="primary"> Reabrir</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && (!['listarsvp'].includes(perfil))) ?
                <IconButton
                    small={true}
                    to={"/eventoHistorico/" + tableSelect.selectedRows[0].id}
                    icon={<FaList size={20} />}
                    color="primary">Histórico</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil)) ?
                <IconButton
                    small={true}
                    to={"/cadastroEventoDiaQuantidade/" + tableSelect.selectedRows[0].id}
                    icon={<FaCalendarAlt size={20} />}
                    color="primary">PAX Dia</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil)) ?
                <IconButton
                    small={true}
                    to={"/cadastroEventoAgenda/" + tableSelect.selectedRows[0].id}
                    icon={<FaCalendarAlt size={20} />}
                    color="primary">Agenda</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'gerenteevento', 'evento'].includes(perfil)) ?
                <IconButton
                    small={true}
                    to={"/cadastroEventoChecklist/" + tableSelect.selectedRows[0].id}
                    icon={<BsCardChecklist size={20} />}
                    color="primary">Checklist</IconButton> : null,
            (tableSelect.selectedRows.length === 1 && ['diretor', 'gerentefinanceiro', 'gerentebackoffice', 'backoffice', 'rsvp'].includes(perfil)) ?
                <IconButton
                    small={true}
                    icon={<ImCancelCircle size={20} />}
                    onClick={() => {
                        Alert({
                            icon: "error",
                            confirmButton: "Sair",
                            denyButton: <><FaThumbsUp size={20} /> Cancelar</>,
                            html: <><div>Data de cancelamento</div>
                                <Data
                                    label="Data"
                                    value={dados.dataCancelamento}
                                    onChange={(date) => {
                                        dados.dataCancelamento = date;
                                    }}
                                />
                            </>,
                            title: "Cancelar Evento",
                            callBack: (result) => {
                                //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                if (result.isDenied) {
                                    api({
                                        method: 'put',
                                        action: '/evento/' + tableSelect.selectedRows[0].id,
                                        token: token,
                                        data: {
                                            ...{ situacao: "cancelado" },
                                            ...{ dataCancelamento: dataMysql(dados.dataCancelamento) }
                                        }
                                    })
                                        .then(function (response) {
                                            buscar();
                                        })
                                        .catch(function (error) {
                                            //alert(error)
                                        });
                                }
                            }
                        });
                    }
                    }
                    color="primary"> Cancelar</IconButton> : null
        ]);
    } else {
        setTableActions([]);
    }
},
    // eslint-disable-next-line
    [tableSelect]);
*/

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['diretor'].includes(perfil) ?
                        <NavItem to="/eventoChecklistModelo" icon={<FaList size={20} />} color="outline-dark">Modelos de Checklist</NavItem> :
                        null}
                </ul>
                <div className="page-title">
                    <h3>Checklist</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.evento, label: filtro.nomeEvento }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado", "cancelado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Responsável</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.responsavel, label: filtro.nomeResponsavel }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "responsavel": e.value, "nomeResponsavel": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: ["pessoa"],
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg"
                                                            id="prazo"
                                                            placeholder="2 dias"
                                                            value={filtro.prazo ?? null}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "prazo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Prazo (dias)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows={true}
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            loadOptions
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: 'Evento',
                                                    selector: row => row.nomeEvento ?? "",
                                                    sortable: true,
                                                    width: "250px",
                                                    cell: (row) => {
                                                        if (row.nomeEvento) {
                                                            if (row.identificacaoEvento) {
                                                                return row.codigoEvento + ' - ' + row.identificacaoEvento + ' - ' + row.nomeEvento ?? "";
                                                            } else {
                                                                return row.codigoEvento + ' - ' + row.nomeEvento ?? "";
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Cliente',
                                                    selector: row => row.nomeCliente ?? "",
                                                    sortable: true,
                                                    width: "200px",
                                                    cell: (row) => {
                                                        if (row.nomeCliente) {
                                                            if (row.nomeMunicipioCliente) { //tem municipio
                                                                return row.codigoCliente + ' - ' + row.nomeCliente + ' - ' + row.nomeMunicipioCliente + ' - ' + row.ufMunicipioCliente;
                                                            } else { //nao tem municipio
                                                                return row.codigoCliente + ' - ' + row.nomeCliente;
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Responsável',
                                                    selector: row => row.nomeResponsavel ?? "",
                                                    sortable: true,
                                                    width: "150px",
                                                    cell: (row) => {
                                                        if (row.nomeResponsavel) {
                                                            return row.codigoResponsavel + ' - ' + row.nomeResponsavel;
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Atividade',
                                                    selector: row => row.nomeAtividade ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.nomeAtividade ?? "";
                                                    },
                                                },
                                                {
                                                    name: 'Prazo',
                                                    selector: row => row.distanciaEvento ?? "",
                                                    sortable: true,
                                                    width: "150px",
                                                    cell: (row) => {
                                                        if (row.distanciaEvento) {
                                                            if (row.distanciaEvento < 0) {
                                                                return <div style={{ color: "#e80d0d" }}>atraso de {row.distanciaEvento * -1} dias</div>;
                                                            } else {
                                                                return <div style={{ color: "#00AA00" }}>restam {row.distanciaEvento} dias</div>;
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                            ]}
                                            data={checklistItems}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
