import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { buscarCep, extract } from '../../util/util';
import ReactSelect from '../../components/ReactSelect';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

export default function CadastroFornecedor({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [tabState, setTabState] = useState("geral");
    const [dados, setDados] = useState({});
    const history = useHistory();
    let { state } = useLocation();
    const tipoCadastro = state?.tipoCadastro ?? 'fornecedor';

    const preencherCep = (cep, dadosAtuais) => {
        buscarCep(cep).then((resposta) => {
            if (!resposta.erro) {
                setDados({
                    ...dadosAtuais,
                    ...{
                        enderecobairro: resposta.bairro,
                        enderecocomplemento: resposta.complemento,
                        enderecologradouro: resposta.logradouro,
                        enderecopais: "30",
                        nomeEnderecopais: "Brasil",
                        enderecomunicipio: resposta.ibge,
                        nomeEnderecomunicipio: resposta.localidade
                    }
                });
            }
        });
    }

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/participante/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'enderecopais',
                        'aereomunicipioembarque',
                        'enderecomunicipio',
                        'banco',
                        'categoria'
                    ]
                });

                delete dadosRecebidos.tipoparticipante;

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if id
        else {
            setDados({ ...dados, ...{ tipoCadastro: tipoCadastro } });
        }
    };

    const validar = () => {
        var nome = document.querySelector("#nome");
        if (nome.value === '') {
            toast.error('É necessário preencher o nome');
            setTimeout(() => {
                nome.focus();
            }, 500);
            setTabState("geral");
            return false;
        }

        /*
        var preferencial = document.querySelector("#preferencial");
        if (dados.preferencial === undefined || dados.preferencial === '') {
            toast.error('É necessário preencher se é PREFERENCIAL');
            setTimeout(() => {
                preferencial.focus();
            }, 500);
            return false;
        }
        */

        /*
        var telefone = document.querySelector("#telefone");
        if (telefone.value === '') {
            toast.error('É necessário preencher o telefone');
            setTimeout(() => {
                telefone.focus();
            }, 500);
            setTabState("geral");
            return false;
        }
        */

        var cnpj = document.querySelector("#cnpj");
        var cpf = document.querySelector("#cpf");
        if (cnpj.value === '' && cpf.value === '') {
            toast.error('É necessário preencher o cnpj ou o cpf');
            setTimeout(() => {
                cnpj.focus();
            }, 500);
            setTabState("fiscal");
            return false;
        }
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }
        if (id) {//update
            api({
                method: 'put',
                action: '/participante/' + id,
                token: token,
                data: dados,
            })
                .then(function (response) {
                    history.push("/fornecedor", { tipoCadastro: tipoCadastro });
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/participante',
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/fornecedor", { tipoCadastro: tipoCadastro });
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Fornecedor</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <Tabs className="justify-content-end" activeKey={tabState} onSelect={(key) => { setTabState(key); }} defaultActiveKey="geral">
                                                <Tab eventKey="geral" title="Geral">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Situação</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.situacaoRegistro, label: dados.nomeSituacaoRegistro }}
                                                                    onChange={e => setDados({ ...dados, ...{ "situacaoRegistro": e.value, "nomeSituacaoRegistro": e.label } })}
                                                                    options={[
                                                                        { value: 'ativo', label: 'Ativo' },
                                                                        { value: 'inativo', label: 'Inativo' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="codigo" placeholder="Código"
                                                                    value={dados.codigo}
                                                                    onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Código</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Categoria</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.categoria, label: dados.nomeCategoria }}
                                                                    onChange={e => setDados({ ...dados, ...{ "categoria": e.value, "nomeCategoria": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participanteCategoria',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Preferencial</label>
                                                                <ReactSelect
                                                                    id="preferencial"
                                                                    value={{ value: dados.preferencial, label: dados.nomePreferencial }}
                                                                    onChange={e => setDados({ ...dados, ...{ "preferencial": e.value, "nomePreferencial": e.label } })}
                                                                    options={[
                                                                        { value: 'nao', label: 'Não' },
                                                                        { value: 'sim', label: 'Sim' }
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Tipo Cadastro</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.tipoCadastro, label: dados.nomeTipoCadastro }}
                                                                    onChange={e => setDados({ ...dados, ...{ "tipoCadastro": e.value, "nomeTipoCadastro": e.label } })}
                                                                    options={[
                                                                        { value: 'cliente', label: 'Cliente' },
                                                                        { value: 'fornecedor', label: 'Fornecedor' },
                                                                        { value: 'orcamento', label: 'Orçamento' },
                                                                        { value: 'cliente,fornecedor', label: 'Cliente e Fornecedor' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                    value={dados.nome}
                                                                    onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="contato" placeholder="Contato"
                                                                    value={dados.contato}
                                                                    onChange={e => setDados({ ...dados, ...{ "contato": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Contato</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="telefone" placeholder="Telefones"
                                                                    value={dados.telefone}
                                                                    onChange={e => setDados({ ...dados, ...{ "telefone": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Telefone</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <InputMask mask="(99) 99999-9999" className="form-control form-control-lg" id="celular" placeholder="Celular"
                                                                    value={dados.celular}
                                                                    onChange={e => setDados({ ...dados, ...{ "celular": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Celular</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="email" placeholder="E-mail"
                                                                    value={dados.email}
                                                                    onChange={e => setDados({ ...dados, ...{ "email": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">E-mail</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="observacao"
                                                                    placeholder="Observações"
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.observacao}
                                                                    onChange={e => setDados({ ...dados, ...{ "observacao": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Observações</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="endereco" title="Endereço">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecologradouro" placeholder="Rua / Logradouro"
                                                                    value={dados.enderecologradouro}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecologradouro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Rua / Logradouro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="endereconumero" placeholder="Número"
                                                                    value={dados.endereconumero}
                                                                    onChange={e => setDados({ ...dados, ...{ "endereconumero": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Número</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecocomplemento" placeholder="Complemento"
                                                                    value={dados.enderecocomplemento}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecocomplemento": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Complemento</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecocep" placeholder="CEP"
                                                                    value={dados.enderecocep}
                                                                    onChange={(e) => {
                                                                        dados.enderecocep = e.target.value;
                                                                        preencherCep(e.target.value, dados);
                                                                        setDados({ ...dados });
                                                                    }}
                                                                />
                                                                <label htmlFor="floatingInput">CEP</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="enderecobairro" placeholder="Bairro"
                                                                    value={dados.enderecobairro}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecobairro": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Bairro</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Cidade</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.enderecomunicipio, label: dados.nomeEnderecomunicipio }}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecomunicipio": e.value, "nomeEnderecomunicipio": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/municipio',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>País</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.enderecopais, label: dados.nomeEnderecopais }}
                                                                    onChange={e => setDados({ ...dados, ...{ "enderecopais": e.value, "nomeEnderecopais": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/pais',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="fiscal" title="Fiscal">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="cnpj" placeholder="CNPJ"
                                                                    value={dados.cnpj}
                                                                    onChange={e => setDados({ ...dados, ...{ "cnpj": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">CNPJ</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="cpf" placeholder="CPF"
                                                                    value={dados.cpf}
                                                                    onChange={e => setDados({ ...dados, ...{ "cpf": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">CPF</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="inscricaoestadual" placeholder="Inscrição Estadual"
                                                                    value={dados.inscricaoestadual}
                                                                    onChange={e => setDados({ ...dados, ...{ "inscricaoestadual": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Inscrição Estadual</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Indicador de Inscrição</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.indicadorinscricao, label: dados.nomeIndicadorinscricao }}
                                                                    onChange={e => setDados({ ...dados, ...{ "indicadorinscricao": e.value, "nomeIndicadorinscricao": e.label } })}
                                                                    options={[
                                                                        { value: '1', label: 'Contribuinte' },
                                                                        { value: '2', label: 'Isento' },
                                                                        { value: '9', label: 'Não contribuinte' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="inscricaomunicipal" placeholder="Inscrição Municipal"
                                                                    value={dados.inscricaomunicipal}
                                                                    onChange={e => setDados({ ...dados, ...{ "inscricaomunicipal": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Inscrição Municipal</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label>Regime Tributário</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.regimetributario, label: dados.nomeRegimetributario }}
                                                                    onChange={e => setDados({ ...dados, ...{ "regimetributario": e.value, "nomeRegimetributario": e.label } })}
                                                                    options={[
                                                                        { value: '1', label: 'MEI' },
                                                                        { value: '2', label: 'Simples Nacional' },
                                                                        { value: '3', label: 'Simples Nacional (Sublimite)' },
                                                                        { value: '4', label: 'Lucro Presumido' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="rg" placeholder="RG"
                                                                    value={dados.rg}
                                                                    onChange={e => setDados({ ...dados, ...{ "rg": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">RG</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="nomefantasia" placeholder="Nome Fantasia"
                                                                    value={dados.nomefantasia}
                                                                    onChange={e => setDados({ ...dados, ...{ "nomefantasia": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Nome Fantasia</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="banco" title="Banco">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Banco</label>
                                                                <ReactSelect
                                                                    value={{ value: dados.banco, label: dados.nomeBanco }}
                                                                    onChange={e => setDados({ ...dados, ...{ "banco": e.value, "nomeBanco": e.label } })}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/financeiroBanco',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="bancoagencia" placeholder="Agência"
                                                                    value={dados.bancoagencia}
                                                                    onChange={e => setDados({ ...dados, ...{ "bancoagencia": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Agência</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="form-floating form-group">
                                                                <input type="text" className="form-control form-control-lg" id="bancoconta" placeholder="Conta"
                                                                    value={dados.bancoconta}
                                                                    onChange={e => setDados({ ...dados, ...{ "bancoconta": e.target.value } })}
                                                                />
                                                                <label htmlFor="floatingInput">Conta</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}