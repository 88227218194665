import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { extract } from '../../../util/util';

export default function CadastroPlanoConta({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/financeiroPlanoConta/' + id,
                token: token,
            }).then(response => { //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'superior',
                        'grupodre'
                    ]
                });
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {
        if (id) {//update
            api({
                method: 'put',
                action: '/financeiroPlanoConta/' + id,
                token: token,
                data: dados,
            })
                .then(function (response) {
                    history.push("/financeiroPlanoConta");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/financeiroPlanoConta',
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/financeiroPlanoConta");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Conta</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Situação</label>
                                                        <ReactSelect
                                                            value={{ value: dados.situacaoRegistro, label: dados.nomeSituacaoRegistro }}
                                                            onChange={e => setDados({ ...dados, ...{ "situacaoRegistro": e.value, "nomeSituacaoRegistro": e.label } })}
                                                            options={[
                                                                { value: 'ativo', label: 'Ativo' },
                                                                { value: 'inativo', label: 'Inativo' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigo" placeholder="codigo"
                                                            value={dados.codigo}
                                                            onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Código</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Superior</label>
                                                        <ReactSelect
                                                            value={{ value: dados.superior, label: dados.nomeSuperior }}
                                                            onChange={e => setDados({ ...dados, ...{ "superior": e.value, "nomeSuperior": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/financeiroPlanoConta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "2",
                                                                        situacaoRegistro: "ativo",
                                                                    } //2 - sintética
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({
                                                                            value: registro.id,
                                                                            label: registro.caminho + '-' + registro.nome
                                                                        }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Grupo DRE</label>
                                                        <ReactSelect
                                                            value={{ value: dados.grupodre, label: dados.nomeGrupodre }}
                                                            onChange={e => setDados({ ...dados, ...{ "grupodre": e.value, "nomeGrupodre": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/financeiroGrupoDre',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                    } //2 - sintética
                                                                })
                                                                    .then(response => {
                                                                        return response.data.data.map(function (registro) {
                                                                            var textoSoma = registro.operacao === 1 ? "(+)" : "(-)";
                                                                            return { label: textoSoma + ' ' + registro.nome, value: registro.id };
                                                                        });
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <ReactSelect
                                                            value={{ value: dados.tipo, label: dados.nomeTipo }}
                                                            onChange={e => setDados({ ...dados, ...{ "tipo": e.value, "nomeTipo": e.label } })}
                                                            options={[
                                                                { value: 1, label: 'Analítica' },
                                                                { value: 2, label: 'Sintética' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}