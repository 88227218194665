import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaUpload, FaThumbsUp, FaFileAlt, FaPen, FaPlus, FaSearch, FaMoneyBill, FaThumbsDown, FaCheck } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import { IoLogoUsd } from 'react-icons/io';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import Alert from '../../components/Alert';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactSelect from '../../components/ReactSelect';
import Data from '../../components/Data';
import UploadButton from '../../components/UploadButton';
import FormButton from '../../components/FormButton';
import { Link } from 'react-router-dom';
import { moeda } from '../../util/util';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

export default function PainelCompra() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({
        modulo: 2,
        empresa: empresa
    }); //2 - compra
    const [dados, setDados] = useState({});
    const [compras, setCompras] = useState([]);
    const [tableSelect, setTableSelect] = useState([]);
    const [tableActions, setTableActions] = useState([]);
    let { state } = useLocation();
    const grupoTitulo = state ? (state.grupoTitulo ? state.grupoTitulo : null) : null;
    const params = new URLSearchParams(window.location.search);
    const idCompra = params.get('idCompra');

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        //só mostra compras com evento em branco (despesas internas) se for diretor
        var despesasInternas = ['diretor', 'gerentefinanceiro', 'financeiro'].includes(perfil) ? { despesasInternas: "sim" } : null;
        api({
            method: 'get',
            action: '/venda',
            token: token,
            params: {
                ...filtro,
                ...{ grupoTitulo: grupoTitulo },
                ...despesasInternas,
                ...{ id: idCompra },
            }
        })
            .then(response => {
                setCompras(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        var arrayids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }) : [];

        if (tableSelect.selectedCount > 0) {
            setTableActions([
                (['diretor', 'gerentefinanceiro'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<FaThumbsUp size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "question",
                                confirmButton: <><FaThumbsUp size={20} /> Aprovar</>,
                                denyButton: "Sair",
                                title: "Aprovar",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isConfirmed) {
                                        api({
                                            method: 'put',
                                            action: '/venda/aprovacao',
                                            token: token,
                                            data: {
                                                vendas: arrayids,
                                                aprovacao: "aprovado",
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Aprovar</IconButton> : null,
                (['diretor', 'gerentefinanceiro'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        icon={<FaThumbsDown size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "question",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsDown size={20} /> Reprovar</>,
                                title: "Reprovar",
                                html:
                                    <input type="text"
                                        className="form-control form-control-lg"
                                        id="motivoReprovado"
                                        placeholder="Motivo"
                                        value={dados.motivoReprovado}
                                        onChange={(e) => {
                                            dados.motivoReprovado = e.target.value;
                                            setDados({ ...dados });
                                        }}
                                    />,
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'put',
                                            action: '/venda/aprovacao',
                                            token: token,
                                            data: {
                                                vendas: arrayids,
                                                aprovacao: "reprovado",
                                                motivoReprovado: dados.motivoReprovado,
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                            })
                                            .catch(function (error) {
                                                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="warning"> Reprovar</IconButton> : null,
                (
                    (tableSelect.selectedRows.length === 1)
                    && !((tableSelect.selectedRows[0].aprovacao === 'aprovado')
                        && (!['diretor', 'gerentefinanceiro'].includes(perfil)
                        )
                    )
                ) ? //apenas 1 selecionado
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "error",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                title: "Excluir",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'delete',
                                            action: '/venda/' + tableSelect.selectedRows[0].id,
                                            token: token
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Excluir</IconButton> : null,
                tableSelect.selectedRows.length === 1 ? //apenas 1 selecionado
                    <UploadButton
                        small={true}
                        icon={<FaUpload size={20} />}
                        color="primary"
                        acaoApi={"vendaArquivo/" + tableSelect.selectedRows[0].id}
                        title="Nota Fiscal"
                        callBack={() => { buscar(); }}
                        dados={{
                            tipoArquivo: "notafiscal",
                            modulo: "compra",
                            pasta: "notafiscal"
                        }}
                    >Nota Fiscal</UploadButton> : null,
                tableSelect.selectedRows.length > 1 ? //apenas 1 selecionado
                    <IconButton
                        small={true}
                        title="Agrupar Pagamentos"
                        to={{
                            pathname: "/cadastroParcelaPagar",
                            state: {
                                vendas: arrayids,
                                acao: "agrupar"
                            }
                        }}
                        icon={<IoLogoUsd size={20} />}
                        color={"primary"} >Agrupar Pagamentos</IconButton> : null,
                (
                    (tableSelect.selectedRows.length === 1) && //apenas 1 selecionado
                    (['diretor', 'gerentefinanceiro', 'financeiro', 'gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil))
                ) //apenas 1 selecionado
                    ?
                    <IconButton
                        small={true}
                        icon={<FaCheck size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "question",
                                confirmButton: <><FaThumbsUp size={20} /> Faturar</>,
                                denyButton: "Sair",
                                title: "Faturar",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isConfirmed) {
                                        api({
                                            method: 'put',
                                            action: '/venda/faturamento/' + tableSelect.selectedRows[0].id,
                                            token: token,
                                            data: {
                                                tipo: "1",
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                            })
                                            .catch(function (error) {
                                                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Faturar</IconButton> : null,
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/cadastroCompra" icon={<FaPlus size={20} />} color="primary">Nova Prestação de Serviço</NavItem>
                    {['diretor', 'gerentefinanceiro', 'financeiro'].includes(perfil) ?
                        <NavItem to="/relatorioCompra" icon={<FaFileAlt size={20} />} color="outline-dark">Relatório de Faturas</NavItem> :
                        null}
                </ul>
                <div className="page-title">
                    <h3>Fornecedores</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.evento, label: filtro.nomeEvento }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigo" placeholder="Buscar pelo número do serviço"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "codigo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Número</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Cidade</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.enderecomunicipio, label: filtro.nomeEnderecomunicipio }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "enderecomunicipio": e.value, "nomeEnderecomunicipio": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/municipio',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Serviço</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.produto, label: filtro.nomeProduto }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "produto": e.value, "nomeProduto": e.label } });
                                                            }}
                                                            defaultOptions
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/produto',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Fornecedor</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: ["fornecedor", "empresa"],
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.tipo, label: filtro.nomeTipo }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "tipo": e.value, "nomeTipo": e.label } });
                                                            }}
                                                            options={[
                                                                { value: "1", label: 'Fatura' },
                                                                { value: "2", label: 'Requisição' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Responsável pelo RSVP</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.coordenadorrsvp, label: filtro.nomeCoordenadorrsvp }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "coordenadorrsvp": e.value, "nomeCoordenadorrsvp": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "pessoa",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Aprovação</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.aprovacao, label: filtro.nomeAprovacao }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "aprovacao": e.value, "nomeAprovacao": e.label } });
                                                            }}
                                                            options={[
                                                                { value: "aprovado", label: 'Aprovado' },
                                                                { value: "reprovado", label: 'Reprovado' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data emissão"
                                                        value={filtro.data1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="até a Data de emissão"
                                                        value={filtro.data2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows="true"
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        if (row.aprovacao === 'aprovado')
                                                            if (!['diretor', 'gerentefinanceiro'].includes(perfil)) {
                                                                return null;
                                                            }
                                                        return <IconButton
                                                            small={true}
                                                            to={"/cadastroCompra/" + row.id}
                                                            icon={<FaPen size={20} />}
                                                            color="primary">Alterar</IconButton>;
                                                    },
                                                    width: "110px",
                                                },
                                                {
                                                    name: '',
                                                    selector: 'arquivonotafiscal',
                                                    cell: row => row.arquivonotafiscal ? <FormButton
                                                        method="get"
                                                        title="Nota Fiscal"
                                                        action={process.env.REACT_APP_API_URL + "/vendaArquivo/" + row.id}
                                                        icon={<FaFileAlt size={20} />}
                                                        color="primary"
                                                        data={{
                                                            token: localStorage.getItem('token'),
                                                            tipoArquivo: "notafiscal"
                                                        }}
                                                    /> : null,
                                                    width: "60px",
                                                },
                                                {
                                                    name: 'Tipo',
                                                    selector: row => row.tipo ?? "",
                                                    sortable: true,
                                                    width: "95px",
                                                    cell: (row) => {
                                                        return <div>
                                                            <div>{row.tipo === "1" ? "Fatura" : "Requisição"}</div>
                                                            {(row.aprovacao !== undefined && row.aprovacao === 'aprovado') ? <span className="badge bg-success">Aprovada</span> : null}
                                                            {(row.aprovacao !== undefined && row.aprovacao === 'reprovado') ? <span className="badge bg-danger" title={row.motivoReprovado !== undefined ? "Motivo: " + row.motivoReprovado : null} >Reprovada</span> : null}
                                                        </div>;
                                                    }
                                                },
                                                {
                                                    name: 'Número',
                                                    selector: row => row.codigo ?? "",
                                                    sortable: true,
                                                    width: "95px",
                                                    right: true,
                                                    cell: (row) => {
                                                        return <div className="right">{row.codigo}</div>;
                                                    }
                                                },
                                                {
                                                    name: 'Evento',
                                                    selector: row => row.evento?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.evento ? row.evento.codigo + ' - ' + row.evento.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Fornecedor',
                                                    selector: row => row.cliente?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        var label = "";
                                                        if (row.cliente) {
                                                            label = row.cliente.codigo + ' - ' + row.cliente.nome;
                                                            if (row.cliente.enderecomunicipio) {
                                                                label += " - " + row.cliente.enderecomunicipio.nome + " - " + row.cliente.enderecomunicipio.uf;
                                                            }
                                                        }
                                                        return row.cliente ? label : "";
                                                    },
                                                },
                                                {
                                                    name: 'Data',
                                                    selector: row => row.data ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    cell: (row) => {
                                                        return row.data ? " " + moment(row.data).format("DD/MM/YYYY") : "";
                                                    },
                                                },
                                                {
                                                    name: 'Usuário',
                                                    selector: row => row.operador?.nome ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    cell: (row) => {
                                                        return row.operador ? row.operador.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Pagamento',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        var valorTotalTitulos = 0;
                                                        row.titulo.forEach((titulo) => {
                                                            valorTotalTitulos += parseFloat(titulo.valorTotal);
                                                        });
                                                        return (<>
                                                            {row.titulo.length > 0 ?
                                                                <IconButton
                                                                    small={true}
                                                                    title="Pagamentos registrados"
                                                                    to={"/financeiroParcelaPagar?grupoTitulo=" + row.grupoTitulo}
                                                                    icon={<FaMoneyBill size={20} />}
                                                                    color={valorTotalTitulos < row.valorTotal ? "warning" : "success"} /> : null
                                                            }
                                                            <IconButton
                                                                small={true}
                                                                title="Registrar pagamento"
                                                                to={{
                                                                    pathname: "/cadastroParcelaPagar",
                                                                    state: {
                                                                        vendas: [row.id],
                                                                        acao: "insert"
                                                                    }
                                                                }}
                                                                icon={<FaPlus size={20} />}
                                                                color={"warning"} />
                                                        </>);
                                                    },
                                                    width: "140px",
                                                },
                                                {
                                                    name: 'Valor',
                                                    selector: row => row.valorTotal ?? "",
                                                    sortable: true,
                                                    width: "140px",
                                                    right: true,
                                                    cell: (row) => {


                                                        var totalicms = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.icmsvalor) || 0;
                                                        }, 0);
                                                        var textoicms = totalicms > 0 ? <div>icms: {moeda(totalicms)}</div> : null;

                                                        var totalpis = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.pisvalor) || 0;
                                                        }, 0);
                                                        var textopis = totalpis > 0 ? <div>pis: {moeda(totalpis)}</div> : null;

                                                        var totalcofins = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.cofinsvalor) || 0;
                                                        }, 0);
                                                        var textocofins = totalcofins > 0 ? <div>cofins: {moeda(totalcofins)}</div> : null;

                                                        var totalipi = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.ipivalor) || 0;
                                                        }, 0);
                                                        var textoipi = totalipi > 0 ? <div>ipi: {moeda(totalipi)}</div> : null;

                                                        var totaliss = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.issvalor) || 0;
                                                        }, 0);
                                                        var textoiss = totaliss > 0 ? <div>iss: {moeda(totaliss)}</div> : null;

                                                        var totalirrf = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.irrfvalor) || 0;
                                                        }, 0);
                                                        var textoirrf = totalirrf > 0 ? <div>irrf: {moeda(totalirrf)}</div> : null;

                                                        var totalinss = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.inssvalor) || 0;
                                                        }, 0);
                                                        var textoinss = totalinss > 0 ? <div>inss: {moeda(totalinss)}</div> : null;

                                                        var totalcsll = row.vendaitem.reduce((total, item) => {
                                                            return total + parseFloat(item.csllvalor) || 0;
                                                        }, 0);
                                                        var textocsll = totalcsll > 0 ? <div>csll: {moeda(totalcsll)}</div> : null;
                                                        var totalImpostos = totalicms + totalpis + totalcofins + totalipi + totaliss + totalirrf + totalinss + totalcsll;
                                                        var valorTotal = row.valorTotal;
                                                        var totalLiquido = valorTotal - totalImpostos;
                                                        var textoValorLiquido = <div width="100%">líquido: {moeda(totalLiquido)}</div>;
                                                        var textoValorTotal = <div width="100%"><strong>total: {moeda(valorTotal)}</strong></div>;
                                                        var textoValorCobrado = row.vendacontrapartida ?
                                                            <div>
                                                                <Link
                                                                    title="Visualizar valor cobrado (Saving/Fee)"
                                                                    to={"/venda?idVenda=" + row.vendacontrapartida.id}>
                                                                    <span className="badge bg-success">Cobrado: {row.vendacontrapartida.valorTotal}</span>
                                                                </Link>
                                                            </div>
                                                            : row.totalcobrado > 0 ? <div>
                                                                <Link
                                                                    title="Registrar valor cobrado (Saving/Fee)"
                                                                    to={{
                                                                        pathname: "/cadastroVenda",
                                                                        state: {
                                                                            vendacontrapartida: row.id,
                                                                        }
                                                                    }}>
                                                                    <span className="badge bg-warning">Cobrado: {row.totalcobrado}</span>
                                                                </Link>
                                                            </div> : null;

                                                        return <div className="right">
                                                            {textoicms}
                                                            {textopis}
                                                            {textocofins}
                                                            {textoipi}
                                                            {textoiss}
                                                            {textoirrf}
                                                            {textoinss}
                                                            {textocsll}
                                                            {textoValorLiquido}
                                                            {textoValorTotal}
                                                            {textoValorCobrado}
                                                        </div>;
                                                    },
                                                },
                                            ]}
                                            data={compras}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
