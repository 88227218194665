import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../../components/ErpTemplate';
import { FaCalendarAlt, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import api from '../../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from '../../../../components/ReactSelect';
import { data, dataMysql, extract, rand6, pontoVirgula, virgulaPonto, moeda } from '../../../../util/util';
import Data from '../../../../components/Data';
import IconButton from '../../../../components/IconButton';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function CadastroParcelaReceber({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({ tituloparcela: [] });
    const history = useHistory();
    let { state } = useLocation();
    const vendas = state ? (state.vendas ? state.vendas : []) : [];
    const acao = state ? (state.acao ? state.acao : 'insert') : 'insert';
    const parcelaabater = state?.parcelaabater ?? null;

    const calculaTotalParcelaReceber = () => {
        dados.valorTotal = 0;
        dados.tituloparcela.forEach(function (valor, index) {
            valor = virgulaPonto(dados.tituloparcela[index].valor);
            dados.valorTotal += valor;
        });
    }//calculaTotalParcelaReceber

    const buscar = () => {
        if (id) {//id do titulo recebido por url
            api({
                method: "get",
                action: '/financeiroTitulo/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                //var dadosRecebidos = response.data.data;
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: ['conta', 'participante']
                });
                //transforma data para javascript
                dadosRecebidos.competencia = data(dadosRecebidos.competencia);

                dadosRecebidos.tituloparcela.forEach((item, index) => {
                    dadosRecebidos.tituloparcela[index].vencimento = data(dadosRecebidos.tituloparcela[index].vencimento);
                });

                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        } else { //nao tem id, pode ser inserir ou agrupar pagamentos
            //var dadosIniciais = { numero: rand6(), competencia: new Date(), tituloparcela: [] };
            var dadosIniciais = { competencia: new Date(), tituloparcela: [] };
            //se tiver vendas acha o cliente da primeira venda
            if (vendas.length > 0) {
                api({
                    token: token,
                    method: "get",
                    action: '/financeiroParcela/parcelasVendas',
                    params: { vendas: vendas }
                }).then(response => {
                    var dadosRecebidos = response.data.data;
                    dadosIniciais.acao = acao;
                    dadosIniciais.vendas = vendas;
                    dadosIniciais.participante = dadosRecebidos.vendas[0].cliente.id;
                    dadosIniciais.nomeParticipante = dadosRecebidos.vendas[0].cliente.nome;
                    var valorTotalVendas = dadosRecebidos.vendas.reduce((total, venda) => {
                        return total + parseFloat(venda.valorTotal);
                    }, 0);

                    //recupera parcelas
                    dadosIniciais.tituloparcela = [];
                    if (dadosRecebidos.parcelas.length > 0 && acao === "agrupar") {//ja tem parcelas cadastradas
                        dadosIniciais.tituloparcela = dadosRecebidos.parcelas.map((parcela) => {
                            return {
                                tipo: 2,
                                vencimento: data(parcela.vencimento),
                                valor: parcela.valor,
                                total: parcela.valor,
                                especie: {
                                    id: parcela.especie.id,
                                    nome: parcela.especie.nome
                                },
                                caixa: {
                                    id: parcela.caixa.id,
                                    nome: parcela.caixa.nome
                                }
                            };
                        });
                    } else {//nao tem parcelas cadastradas
                        //mostra uma parcela em branco com o valor total das vendas
                        dadosIniciais.tituloparcela.push({
                            tipo: 2,
                            vencimento: moment().format("DD/MM/YYYY"),
                            valor: valorTotalVendas,
                            total: 0,
                            especie: {
                                id: null,
                                nome: null
                            },
                            caixa: {
                                id: null,
                                nome: null
                            }
                        });
                    }
                    setDados(dadosIniciais);
                }).catch(err => {
                    //console.log(err)
                });
            } else {
                setDados(dadosIniciais);//seta dados padões
            }
        }
    }; //buscar

    const validar = () => {
        dados.tituloparcela.forEach((titulo) => {
            if (titulo.caixa === undefined || titulo.caixa.id === '') {
                toast.error('É necessário preencher o caixa');
                return false;
            }
        });
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }
        //if (id && !copiar) {//update
        if (dados.competencia) {
            dados.competencia = dataMysql(dados.competencia);
        }
        //converte todas as datas para mysql
        if (dados.tituloparcela)
            dados.tituloparcela.forEach((registro, index) => {
                dados.tituloparcela[index].vencimento = dataMysql(dados.tituloparcela[index].vencimento);
            });

        if (id) {//update título unico
            api({
                method: 'put',
                action: '/financeiroTitulo/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push({
                        pathname: "/financeiroParcelaReceber",
                        state: { idTitulo: id }
                    });
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert ou agrupar
            api({
                method: 'post',
                action: '/financeiroTitulo',
                token: token,
                data: {
                    ...dados,
                    ...{ acao: acao },
                    ...{ empresa: empresa },
                    ...{ parcelaabater: parcelaabater }
                }
            })
                .then(function (response) {
                    history.push({
                        pathname: "/financeiroParcelaReceber",
                        state: { idTitulo: id }
                    });
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Receita</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            value={{ value: dados.participante, label: dados.nomeParticipante }}
                                                            onChange={e => setDados({ ...dados, ...{ "participante": e.value, "nomeParticipante": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="numero" placeholder="numero"
                                                            value={dados.numero}
                                                            onChange={e => setDados({ ...dados, ...{ "numero": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Número</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Plano de Contas</label>
                                                        <ReactSelect
                                                            value={{ value: dados.conta, label: dados.nomeConta }}
                                                            onChange={e => setDados({ ...dados, ...{ "conta": e.value, "nomeConta": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/financeiroPlanoConta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "1",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.caminho + ' - ' + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Competência"
                                                        value={dados.competencia}
                                                        onChange={date => setDados({ ...dados, ...{ "competencia": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="Descrição" placeholder="Descrição"
                                                            value={dados.descricao}
                                                            onChange={e => setDados({ ...dados, ...{ "descricao": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Descrição</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <textarea
                                                            className="form-control form-control-lg"
                                                            id="observacao"
                                                            placeholder="Observações"
                                                            style={{ minHeight: "100px" }}
                                                            value={dados.observacao}
                                                            onChange={e => setDados({ ...dados, ...{ "observacao": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Observações</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <td width="50px"></td>
                                                                            <td>Espécie</td>
                                                                            <td>Caixa</td>
                                                                            <td>Valor</td>
                                                                            <td>Vencimento</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dados.tituloparcela ? dados.tituloparcela.map((row, rowIndex) => {
                                                                            return [
                                                                                <tr>
                                                                                    <td>
                                                                                        <IconButton
                                                                                            title="Remover"
                                                                                            color="warning"
                                                                                            icon={<FaTrash size={20} />}
                                                                                            onClick={() => {
                                                                                                dados.tituloparcela.splice(rowIndex, 1);
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <ReactSelect
                                                                                            value={{ value: row.especie.id, label: row.especie.nome }}
                                                                                            onChange={(e) => {
                                                                                                dados.tituloparcela[rowIndex].especie.id = e.value;
                                                                                                dados.tituloparcela[rowIndex].especie.nome = e.label;
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                            defaultOptions
                                                                                            loadOptions={(input) => {
                                                                                                return api({
                                                                                                    method: 'get',
                                                                                                    action: '/financeiroEspecie',
                                                                                                    token: token,
                                                                                                    params: {
                                                                                                        nome: input,
                                                                                                    }
                                                                                                })
                                                                                                    .then(response => {
                                                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                                        return options;
                                                                                                    })
                                                                                                    .catch(function (error) {
                                                                                                        //console.log(error);
                                                                                                    });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <ReactSelect
                                                                                            value={{ value: row.caixa?.id, label: row.caixa?.nome }}
                                                                                            onChange={(e) => {
                                                                                                dados.tituloparcela[rowIndex].caixa = {};
                                                                                                dados.tituloparcela[rowIndex].caixa.id = e.value;
                                                                                                dados.tituloparcela[rowIndex].caixa.nome = e.label;
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                            defaultOptions
                                                                                            loadOptions={(input) => {
                                                                                                return api({
                                                                                                    method: 'get',
                                                                                                    action: '/financeiroCaixa',
                                                                                                    token: token,
                                                                                                    params: {
                                                                                                        nome: input,
                                                                                                    }
                                                                                                })
                                                                                                    .then(response => {
                                                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                                        return options;
                                                                                                    })
                                                                                                    .catch(function (error) {
                                                                                                        //console.log(error);
                                                                                                    });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg right" id="valor" placeholder="Valor"
                                                                                                value={pontoVirgula(dados.tituloparcela[rowIndex].valor)}
                                                                                                onChange={(e) => {
                                                                                                    dados.tituloparcela[rowIndex].valor = e.target.value;
                                                                                                    calculaTotalParcelaReceber();
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Valor</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Data
                                                                                            label="Vencimento"
                                                                                            value={row.vencimento}
                                                                                            onChange={(date) => {
                                                                                                dados.tituloparcela[rowIndex].vencimento = date;
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ];
                                                                        }) : null}
                                                                        <tr>
                                                                            <td colSpan="3">
                                                                            </td>
                                                                            <td>
                                                                                <div className="form-floating form-group">
                                                                                    <input readOnly type="text" className="form-control form-control-lg right" id="total" placeholder="Total"
                                                                                        value={pontoVirgula(dados.valorTotal)}
                                                                                    />
                                                                                    <label htmlFor="floatingInput">Total</label>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            //tipo: 1 - pagar | 2 - receber
                                                            dados.tituloparcela.push({
                                                                empresa: empresa,
                                                                tipo: 2,
                                                                vencimento: null,
                                                                valor: 0,
                                                                total: 0,
                                                                especie: {
                                                                    id: null,
                                                                    nome: null
                                                                },
                                                                caixa: {
                                                                    id: null,
                                                                    nome: null
                                                                }
                                                            });
                                                            setDados({ ...dados });
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Item</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}