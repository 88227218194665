import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { dataHora } from '../util/util';

const CadastroHistoricoAtividadeChecklist = ({ isOpen, onClose, idItem }) => {
    const token = localStorage.getItem('token');
    const [texto, setTexto] = useState('');
    const [registros, setRegistros] = useState([]);

    useEffect(() => {
        if (isOpen) {
            // Carregar os registros da API com base no idItem
            api({
                method: 'get',
                action: '/eventoChecklistHistoricoAtividad',
                token: token,
                params: {
                    idItem: idItem
                }
            })
                .then(response => {
                    setRegistros(response.data.data); // Armazenar os registros no estado local
                }).catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });

        }
    }, [token, isOpen, idItem]);

    const handleSalvar = () => {
        // Enviar o texto e o idItem para a API aqui
        api({
            method: 'post',
            action: '/eventoChecklistHistoricoAtividad',
            token: token,
            data: {
                item: idItem,
                historico: texto,
            }
        })
            .then(function (response) {
                toast.success('Dados registrados com sucesso');
                setTexto('');
                onClose();
                //history.push("/eventoChecklistAtividade");
            })
            .catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            });
    };

    return (
        <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" style={{ display: isOpen ? 'block' : 'none' }}>
            <div className="modal-dialog modal-lg shadow" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Históricos da Atividade</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="texto" className="form-label">Texto:</label>
                            <textarea
                                id="texto"
                                className="form-control"
                                rows="2"
                                value={texto}
                                onChange={(e) => setTexto(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={handleSalvar}>
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="modal-content">
                    <div className="modal-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ width: "140px" }}>Data</th>
                                    <th style={{ width: "140px" }}>Usuário</th>
                                    <th>Histórico</th>
                                </tr>
                            </thead>
                            <tbody>
                                {registros.map((registro) => (
                                    <tr key={registro.id}>
                                        <td>{dataHora(registro.data)}</td>
                                        <td>{registro.usuario.nome}</td>
                                        <td>{registro.historico}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CadastroHistoricoAtividadeChecklist;
