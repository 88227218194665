import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaList, FaPen, FaPlus, FaSearch, FaThumbsUp } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import Alert from '../../components/Alert';

export default function PainelPessoa() {
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [pessoas, setPessoas] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);

    const buscar = () => {
        setFiltro({...filtro,...{buscando: true}});
        api({
            method: 'get',
            action: '/participante',
            token: token,
            params: {
                ...filtro,
                ...{ tipoCadastro: "pessoa" },
                ...{
                    orderBy: {
                        situacaoRegistro: "asc"
                    }
                }
            }
        })
            .then(response => {
                setPessoas(response.data.data);
                setFiltro({...filtro,...{buscando: false}});
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
                (['diretor', 'rsvp'].includes(perfil)) ? //apenas gerente financeiro 
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "error",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                title: "Excluir",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'delete',
                                            action: '/participantes',
                                            token: token,
                                            data: {
                                                idParticipantes: ids
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Excluir</IconButton > : null,
            ]);
        } else {
            setTableActions([]);
        }
    }, 
    // eslint-disable-next-line
    [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/cadastroPessoa" icon={<FaPlus size={20} />} color="primary">Nova Pessoa</NavItem>
                    <NavItem to="rhCargo" icon={<FaList size={20} />} color="outline-dark">Cargos</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Pessoas</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome da pessoa"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="email" placeholder="Buscar pelo usuário da pessoa"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "email": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Usuário</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows="true"
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: row => <IconButton
                                                        small={true}
                                                        to={"/cadastroPessoa/" + row.id}
                                                        icon={<FaPen size={20} />}
                                                        color="primary">Alterar</IconButton>,
                                                    width: "120px",
                                                },
                                                {
                                                    name: 'Situação',
                                                    selector: row => row.situacaoRegistro ?? "",
                                                    sortable: true,
                                                    width: "75px",
                                                },
                                                {
                                                    name: 'Código',
                                                    selector: row => row.codigo ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Nome',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.evento ? row.nome + ' - ' + row.evento.codigo + ' - ' + row.evento.nome : row.nome;
                                                    },
                                                },
                                                {
                                                    name: 'Cargo',
                                                    selector: row => row.cargo?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.cargo ? row.cargo.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Usuário',
                                                    selector: row => row.email ?? "",
                                                    sortable: true,
                                                },
                                            ]}
                                            data={pessoas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
