import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaPlus, FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { data, dataMysql, extract } from '../../util/util';
import Data from '../../components/Data';

export default function CadastroSla({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState([]);
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const copiar = params.get('copiar');

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/sla/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: ['evento', 'tipo']
                });
                //transforma data para javascript
                dadosRecebidos.data = data(dadosRecebidos.data);

                dadosRecebidos.slacompromissodata.forEach((compromissoData, index) => {
                    dadosRecebidos.slacompromissodata[index].data = data(dadosRecebidos.slacompromissodata[index].data);
                });

                if (copiar) {
                    dadosRecebidos.codigo = null;
                    dadosRecebidos.nome = null;
                    dadosRecebidos.evento = null;
                    dadosRecebidos.nomeEvento = null;
                    dadosRecebidos.slacompromissodata.forEach((compromissoData, index) => {
                        dadosRecebidos.slacompromissodata[index].data = null;
                    });
                }
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        } else {
            dados.slacompromissodata = [];
        }
    };

    const salvar = () => {
        if (dados.data) {
            dados.data = dataMysql(dados.data);
        }
        
        //converte todas as datas para mysql
        if (dados.slacompromissodata)
            dados.slacompromissodata.forEach((registro, index) => {
                dados.slacompromissodata[index].data = dataMysql(dados.slacompromissodata[index].data);
            });

        if (id && !copiar) {//update
            api({
                method: 'put',
                action: '/sla/' + id,
                token: token,
                data: dados
            })
                .then(function (response) {
                    history.push("/sla");
                })
                .catch(function (error) {
                    //alert(error)
                });
        } else {//insert
            api({
                method: 'post',
                action: '/sla',
                token: token,
                data: { ...dados, ...{ empresa: empresa } }
            })
                .then(function (response) {
                    history.push("/sla");
                })
                .catch(function (error) {
                    //alert(error)
                });
        }
    };

    useEffect(() => { //onload
        document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>SLA</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input readOnly type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nomeTipo}
                                                        />
                                                        <label htmlFor="floatingInput">Tipo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input readOnly type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nomeEvento}
                                                        />
                                                        <label htmlFor="floatingInput">Evento</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input readOnly type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                            value={dados.nome}
                                                        />
                                                        <label htmlFor="floatingInput">Diretriz</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        value={dados.data}
                                                        onChange={date => setDados({ ...dados, ...{ "data": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Compromisso</th>
                                                                            <th>Data</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dados.slacompromissodata ? dados.slacompromissodata.map((row, rowIndex) => {
                                                                            return [
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input readOnly type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                                                value={row.compromisso.nome}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Compromisso</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Data
                                                                                            value={row.data}
                                                                                            onChange={(date) => {
                                                                                                dados.slacompromissodata[rowIndex].data = date;
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-check">
                                                                                            <div className="checkbox">
                                                                                                <label>
                                                                                                    <input type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        value={row.naoaplicavel}
                                                                                                        checked={row.naoaplicavel === "sim" ? true : false}
                                                                                                        onChange={(value) => {
                                                                                                            dados.slacompromissodata[rowIndex].naoaplicavel = dados.slacompromissodata[rowIndex].naoaplicavel === "nao" ? "sim" : "nao";
                                                                                                            setDados({ ...dados });
                                                                                                        }} />
                                                                                                    Não Aplicavel</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ];
                                                                        }) : null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}