import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import '../Login/styles.css';
import { toast } from 'react-toastify';

export default function LoginRapido() {
  const { id, rota } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function buscar() {
      try {
        const respostaDadosParticipante = await api({
          method: "get",
          action: `/dadosParticipante/${id}`,
        });
        const dadosParticipante = respostaDadosParticipante.data.data;
        if (dadosParticipante.evento.logomarca?.link) {//existe logomarca no evento
          localStorage.setItem('logomarcaEvento', dadosParticipante.evento.logomarca.link);
        } else if (dadosParticipante.evento.cliente?.logomarca?.link) { //existe logomarca no cliente do evento
          localStorage.setItem('logomarcaEvento', dadosParticipante.evento.cliente.logomarca.link);
        }

        const respostaLogin = await api({
          method: 'post',
          action: `/loginRapido/${id}`,
        });
        const dadosLogin = respostaLogin.data;

        localStorage.setItem('perfil', "cliente");
        localStorage.setItem('idUsuario', dadosLogin.id);
        localStorage.setItem('nomeUsuario', dadosLogin.nome);
        localStorage.setItem('token', dadosLogin.api_token);

        history.push(`/${rota}`);
      } catch (error) {
        toast.error(`Erro: ${error.response?.data?.error || "ao carregar dados"}`);
      }
    }

    buscar();
  }, [id, rota, history]);

  // Este componente não renderiza nada, apenas realiza o login e redireciona
  return <div>Carregando...</div>;
}