import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown, FaBan, FaCheck } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { bin2base64, bin2hex, dataMysql, extract, hex2bin, utf8Decode, utf8Encode } from '../../../util/util';
import ReactSelect from '../../../components/ReactSelect';
import NavItem from '../../../components/NavItem';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import moment from 'moment';
import MostrarHTML from '../../../components/MostrarHtml';
import Alert from '../../../components/Alert';

export default function CadastroEventoParticipanteAceitarTermo({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [concordaTermo, setConcordaTermo] = useState(false);
    const history = useHistory();
    let { state } = useLocation();
    var idEvento = state?.evento ?? null;

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });
            //if ((dadosRecebidos.aceitouTermo ?? "nao") === 'sim') { //ja aceitou os termos
            //    history.push("/cadastroEventoParticipanteAceitarConvite/" + id);
            //} else {
            api({
                method: "get",
                action: '/eventoCarta/visualizarCartaHtml/' + id,
                token: token,
                params: {
                    tipoCarta: "modelotermoaceite",
                }
            }).then(response => {
                setDados({
                    ...dados,
                    ...dadosRecebidos,
                    ...{ htmlCartaConvite: response.data.data.htmlCarta }
                })
            }).catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            });
            //}
        }).catch(err => {
            //console.log(err)
        });
    };//buscar

    const salvar = (aceitouTermo) => {
        api({
            method: 'put',
            action: '/participante/aceitarTermo/' + id,
            token: token,
            data: { executarAceitouTermo: aceitouTermo ? 'sim' : 'nao' },
        })
            .then(function (response) {
                if (aceitouTermo) {
                    history.push("/cadastroEventoParticipanteConvite/" + id);
                } else {
                    history.push("/cadastroEventoParticipanteConviteRecusado/" + dados.evento.id);
                }
            })
            .catch(function (error) {
                //alert(error)
            });
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['listarsvp'].includes(perfil) ? <NavItem to={"/eventoParticipante/" + idEvento} icon={<FaBackward size={20} />} color="outline-dark">Participantes</NavItem> : null}
                </ul>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <MostrarHTML
                                                    htmlContent={dados.htmlCartaConvite ?? null}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Termo de LGPD</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <p>Todas as informações fornecidas durante o processo de RSVP serão usadas exclusivamente para a realização dos serviços associados a este evento. Esses dados serão gerenciados pela equipe da Access-Mkt e compartilhados com a agência de eventos contratante e/ou a empresa que organiza o evento. Eles, por sua vez, podem compartilhar essas informações com fornecedores como hotéis, agências de transporte, companhias aéreas, organizadores de congressos e feiras, empresas de seguro de viagem, entre outros, conforme necessário para oferecer os serviços relacionados ao seu patrocínio.</p>
                                                <p>A Access-Mkt assume a responsabilidade de adotar medidas de segurança, incluindo medidas técnicas e administrativas, para proteger os dados pessoais contra acessos não autorizados. Para isso, utilizamos um sistema próprio com um desenvolvedor responsável, um banco de dados MySQL altamente seguro, comunicações de dados criptografadas e servidores locais protegidos por firewall e criptografia de arquivos.</p>
                                                <p>Se você desejar, a qualquer momento, terá o direito de acessar os dados coletados pela Access-Mkt. Além disso, pode solicitar a correção, bloqueio ou exclusão desses dados, assim como revogar o consentimento fornecido.</p>
                                                <p>Ao clicar no botão "Aceitar o convite", você está ciente de que está compartilhando dados pessoais, incluindo informações confidenciais ou sensíveis conforme definido pela Lei Geral de Proteção de Dados (LGPD), com o banco de dados de registro e participação da Access-Mkt.</p>
                                                <p>Por outro lado, se você optar por "Declinar o convite" e selecionar a opção de não concordar com os termos da LGPD, seu registro não será submetido ou processado, o que inviabilizará a continuidade dos serviços necessários para sua participação.</p>
                                                <p>Os dados pessoais coletados para este evento serão tratados em estrita conformidade com a Lei Federal nº 13.709/2018 (Lei Geral de Proteção de Dados).</p>
                                                <p>Você pode baixar os termos acima clicando no link: <a rel="noreferrer" target="_blank" href="https://api.infoaccess-mkt.com.br/termo_lgpd.pdf">Baixar Termos LGPD</a></p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={concordaTermo}
                                                                checked={concordaTermo}
                                                                onChange={(value) => {
                                                                    setConcordaTermo(!concordaTermo);
                                                                }} />
                                                            Eu concordo com os termos acima</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger me-1 mb-1"
                                                    onClick={() => {
                                                        Alert({
                                                            title: "Tem certeza?",
                                                            icon: "warning",
                                                            html: <>Para dar andamento aos processos de aceite do convite, é imprescindível o aceite nas normas e regras da LGPD, devido a lei de proteção de dados.</>,
                                                            confirmButton: "Voltar",
                                                            denyButton: "Não aceito os termos",
                                                            callBack: (result) => {
                                                                //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                                                if (result.isDenied) {
                                                                    salvar(false);
                                                                }
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <FaBan size={20} /> Não aceito os termos
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-success me-1 mb-1"
                                                    disabled={!concordaTermo}
                                                    onClick={() => {
                                                        salvar(true);
                                                    }}
                                                >
                                                    <FaCheck size={20} /> Aceito os termos
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}