import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaCalendarAlt, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { data, dataMysql, extract, pontoVirgula, virgulaPonto } from '../../util/util';
import Data from '../../components/Data';
import ModalButton from '../../components/ModalButton';
import { GiLion } from 'react-icons/gi';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import IconButton from '../../components/IconButton';
import { toast } from 'react-toastify';

export default function CadastroCompra({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const usuario = localStorage.getItem("idUsuario");
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({
        tipo: ['diretor', 'gerentefinanceiro'].includes(perfil) ? "1" : "2", // 1 - fatura | 2 - requisição
        modulo: 2,
        vendaitem: []
    }); //2 - compra
    const history = useHistory();

    const calculaTotalCompra = () => {
        var valorTotal = 0;
        dados.vendaitem.forEach(function (valor, index) {
            var quantidade = virgulaPonto(dados.vendaitem[index].quantidade);
            var valorUnitario = virgulaPonto(dados.vendaitem[index].valorUnitario);
            var desconto = virgulaPonto(dados.vendaitem[index].desconto);
            var adicional = virgulaPonto(dados.vendaitem[index].adicional);

            var valorcobrado = virgulaPonto(dados.vendaitem[index].valorcobrado);
            var issaliquotacobrado = virgulaPonto(dados.vendaitem[index].issaliquotacobrado);
            var valorriscosacadocobrado = virgulaPonto(dados.vendaitem[index].valorriscosacadocobrado);
            var valorriscosacadocobradocomimposto = valorriscosacadocobrado + (valorriscosacadocobrado * (issaliquotacobrado / 100));
            var totalcobrado = valorcobrado + valorriscosacadocobradocomimposto;
            var totalliquido = valorcobrado - (valorcobrado * (issaliquotacobrado / 100)) + valorriscosacadocobradocomimposto;
            dados.vendaitem[index].totalcobrado = totalcobrado.toFixed(2);
            dados.vendaitem[index].totalliquido = totalliquido.toFixed(2);

            var issaliquota = virgulaPonto(dados.vendaitem[index].issaliquota);
            var issbasecalculo = virgulaPonto(dados.vendaitem[index].issbasecalculo);
            var issvalor = (issaliquota / 100) * issbasecalculo;
            dados.vendaitem[index].issvalor = issvalor.toFixed(2);

            var irrfaliquota = virgulaPonto(dados.vendaitem[index].irrfaliquota);
            var irrfbasecalculo = virgulaPonto(dados.vendaitem[index].irrfbasecalculo);
            var irrfvalor = (irrfaliquota / 100) * irrfbasecalculo;
            dados.vendaitem[index].irrfvalor = irrfvalor.toFixed(2);

            var inssaliquota = virgulaPonto(dados.vendaitem[index].inssaliquota);
            var inssbasecalculo = virgulaPonto(dados.vendaitem[index].inssbasecalculo);
            var inssvalor = (inssaliquota / 100) * inssbasecalculo;
            dados.vendaitem[index].inssvalor = inssvalor.toFixed(2);

            var csllaliquota = virgulaPonto(dados.vendaitem[index].csllaliquota);
            var csllbasecalculo = virgulaPonto(dados.vendaitem[index].csllbasecalculo);
            var csllvalor = (csllaliquota / 100) * csllbasecalculo;
            dados.vendaitem[index].csllvalor = csllvalor.toFixed(2);

            var icmsaliquota = virgulaPonto(dados.vendaitem[index].icmsaliquota);
            var icmsbasecalculo = virgulaPonto(dados.vendaitem[index].icmsbasecalculo);
            var icmsvalor = (icmsaliquota / 100) * icmsbasecalculo;
            dados.vendaitem[index].icmsvalor = icmsvalor.toFixed(2);

            var ipialiquota = virgulaPonto(dados.vendaitem[index].ipialiquota);
            var ipibasecalculo = virgulaPonto(dados.vendaitem[index].ipibasecalculo);
            var ipivalor = (ipialiquota / 100) * ipibasecalculo;
            dados.vendaitem[index].ipivalor = ipivalor.toFixed(2);

            var pisaliquota = virgulaPonto(dados.vendaitem[index].pisaliquota);
            var pisbasecalculo = virgulaPonto(dados.vendaitem[index].pisbasecalculo);
            var pisvalor = (pisaliquota / 100) * pisbasecalculo;
            dados.vendaitem[index].pisvalor = pisvalor.toFixed(2);

            var cofinsaliquota = virgulaPonto(dados.vendaitem[index].cofinsaliquota);
            var cofinsbasecalculo = virgulaPonto(dados.vendaitem[index].cofinsbasecalculo);
            var cofinsvalor = (cofinsaliquota / 100) * cofinsbasecalculo;
            dados.vendaitem[index].cofinsvalor = cofinsvalor.toFixed(2);

            var total = (quantidade * valorUnitario) - desconto + adicional;
            valorTotal += total;
            dados.vendaitem[index].total = total.toFixed(2);
        });
        dados.valorTotal = valorTotal.toFixed(2);
    }//calculaTotalCompra

    const buscar = () => {
        //puxa dados iniciais da empresa
        api({
            method: "get",
            action: '/participante/' + empresa,
            token: token,
        }).then(response1 => {
            //puxa dados da venda se houver
            if (id) {
                api({
                    method: "get",
                    action: '/venda/' + id,
                    token: token,
                }).then(response2 => {
                    //extrai dados internos para o array principal
                    var dadosRecebidos = extract({
                        jsonLaravel: response2.data.data,
                        chaves: ['evento', 'cliente']
                    });
                    //transforma data para javascript
                    dadosRecebidos.data = data(dadosRecebidos.data);
                    dadosRecebidos.dataVencimento = data(dadosRecebidos.dataVencimento);
                    setDados({
                        ...dadosRecebidos,
                        ...{ issaliquota: response1.data.data.issaliquota }
                    });
                }).catch(err => {
                    //console.log(err)
                });
            } else {
                dados.issaliquota = response1.data.data.issaliquota;
                setDados(dados);
            }
        }).catch(err => {
            //console.log(err)
        });
    };


    const validar = () => {
        if (['gerenteevento', 'gerentebackoffice', 'evento'].includes(perfil)) { //pessoal de eventos precisa informar o evento
            var evento = document.querySelector("#evento");
            if (dados.evento === undefined || dados.evento === '') {
                toast.error('É necessário preencher o evento');
                setTimeout(() => {
                    evento.focus();
                }, 500);
                return false;
            }
        }
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }
        if (dados.data) {
            dados.data = dataMysql(dados.data);
        }
        if (dados.dataVencimento) {
            dados.dataVencimento = dataMysql(dados.dataVencimento);
        }
        if (id) {//update
            api({
                method: 'put',
                action: '/venda/' + id,
                token: token,
                data: {
                    ...dados,
                    ...{ operador: usuario }
                }
            })
                .then(function (response) {
                    history.push("/compra");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert
            api({
                method: 'post',
                action: '/venda',
                token: token,
                data: {
                    ...dados,
                    ...{ empresa: empresa },
                    ...{ operador: usuario }
                }
            })
                .then(function (response) {
                    history.push("/compra");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        }
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Fatura Fornecedor</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            id="evento"
                                                            value={{ value: dados.evento, label: dados.nomeEvento }}
                                                            onChange={e => setDados({ ...dados, ...{ "evento": e.value, "nomeEvento": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label,
                                                                                disabled: registro.situacao === "fechado" ? true : false
                                                                            }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="codigo" placeholder="codigo"
                                                            value={dados.codigo}
                                                            onChange={e => setDados({ ...dados, ...{ "codigo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Número Recibo / Fatura / Nota Fiscal</label>
                                                    </div>
                                                </div>
                                                {['diretor', 'gerentefinanceiro'].includes(perfil) ?
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label>Tipo</label>
                                                            <ReactSelect
                                                                id="tipo"
                                                                value={{ value: dados.tipo, label: dados.nomeTipo }}
                                                                onChange={e => setDados({ ...dados, ...{ "tipo": e.value, "nomeTipo": e.label } })}
                                                                options={[
                                                                    { value: "1", label: 'Fatura' },
                                                                    { value: "2", label: 'Requisição' },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div> : null
                                                }
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Fornecedor</label>
                                                        <ReactSelect
                                                            value={{ value: dados.cliente, label: dados.nomeCliente }}
                                                            onChange={e => setDados({ ...dados, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: ["fornecedor", "empresa"],
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data Emissão"
                                                        value={dados.data}
                                                        onChange={date => setDados({ ...dados, ...{ "data": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data Vencimento"
                                                        value={dados.dataVencimento}
                                                        onChange={date => setDados({ ...dados, ...{ "dataVencimento": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="100px"></td>
                                                                            <td></td>
                                                                            <td width="100px"></td>
                                                                            <td width="100px"></td>
                                                                            <td width="100px"></td>
                                                                            <td width="100px"></td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dados.vendaitem ? dados.vendaitem.map((row, rowIndex) => {
                                                                            return [
                                                                                <tr>
                                                                                    <td>
                                                                                        <IconButton
                                                                                            title="Remover"
                                                                                            color="warning"
                                                                                            icon={<FaTrash size={20} />}
                                                                                            onClick={() => {
                                                                                                dados.vendaitem.splice(rowIndex, 1);
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <ModalButton
                                                                                            title="Impostos"
                                                                                            icon={<GiLion size={20} />}
                                                                                            color="primary"
                                                                                        >
                                                                                            <Tabs className="justify-content-end" defaultActiveKey="valorcobrado">
                                                                                                <Tab eventKey="valorcobrado" title="Valor Cobrado">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="valorcobrado" placeholder="Valor Cobrado"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].valorcobrado)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].valorcobrado = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor Cobrado</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input readOnly={true} type="text" className="form-control form-control-lg right" id="issaliquotacobrado" placeholder="Aliquota Impostos (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].issaliquotacobrado)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].issaliquotacobrado = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Aliquota Impostos (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input readOnly={true} type="text" className="form-control form-control-lg right" id="totalliquido" placeholder="Total Líquido"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].totalliquido)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].totalliquido = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Total Líquido</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="valorriscosacadocobrado" placeholder="Valor Risco Sacado"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].valorriscosacadocobrado)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].valorriscosacadocobrado = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor Risco Sacado</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-check">
                                                                                                                <div className="checkbox">
                                                                                                                    <label>
                                                                                                                        <input type="checkbox"
                                                                                                                            className="form-check-input"
                                                                                                                            value={row.isszerado}
                                                                                                                            checked={row.isszerado === "sim" ? true : false}
                                                                                                                            onChange={(value) => {
                                                                                                                                dados.vendaitem[rowIndex].isszerado = dados.vendaitem[rowIndex].isszerado === "nao" ? "sim" : "nao";
                                                                                                                                dados.vendaitem[rowIndex].issaliquotacobrado = dados.vendaitem[rowIndex].isszerado === "sim" ? 0 : dados.issaliquota;
                                                                                                                                calculaTotalCompra();
                                                                                                                                setDados({ ...dados });
                                                                                                                            }} />
                                                                                                                        SEM IMPOSTOS</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input readOnly={true} type="text" className="form-control form-control-lg right" id="totalcobrado" placeholder="Total Cobrado"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].totalcobrado)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].totalcobrado = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Total Cobrado</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="iss" title="ISS">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-group">
                                                                                                                <label>Indicador</label>
                                                                                                                <ReactSelect
                                                                                                                    value={row.issindicador !== null ? { value: row.issindicador.id, label: row.issindicador.nome } : null}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].issindicador = {};
                                                                                                                        dados.vendaitem[rowIndex].issindicador.id = e.value;
                                                                                                                        dados.vendaitem[rowIndex].issindicador.nome = e.label;
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                    loadOptions={(input) => {
                                                                                                                        return api({
                                                                                                                            method: 'get',
                                                                                                                            action: '/fiscalCst',
                                                                                                                            token: token,
                                                                                                                            params: {
                                                                                                                                tributo: ["iss"],
                                                                                                                                nome: input
                                                                                                                            }
                                                                                                                        })
                                                                                                                            .then(response => {
                                                                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                                                                                return options;
                                                                                                                            })
                                                                                                                            .catch(function (error) {
                                                                                                                                //console.log(error);
                                                                                                                            });
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-group">
                                                                                                                <label>Retido</label>
                                                                                                                <ReactSelect
                                                                                                                    value={{ value: row.issretido, label: row.nomeIssretido }}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].issretido = e.value;
                                                                                                                        dados.vendaitem[rowIndex].nomeIssretido = e.label;
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                    options={[
                                                                                                                        { value: '1', label: 'Sim' },
                                                                                                                        { value: '2', label: 'Não' },
                                                                                                                    ]}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="issbasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].issbasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].issbasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="issaliquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].issaliquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].issaliquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="issvalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].issvalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].issvalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="irrf" title="IRRF">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="irrfbasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].irrfbasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].irrfbasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="irrfaliquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].irrfaliquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].irrfaliquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="irrfvalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].irrfvalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].irrfvalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="inss" title="INSS">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="inssbasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].inssbasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].inssbasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="inssaliquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].inssaliquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].inssaliquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="inssvalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].inssvalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].inssvalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="csll" title="CSLL">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="csllbasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].csllbasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].csllbasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="csllaliquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].csllaliquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].csllaliquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="csllvalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].csllvalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].csllvalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="icms" title="ICMS">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-group">
                                                                                                                <label>CST</label>
                                                                                                                <ReactSelect
                                                                                                                    value={row.icmscst !== null ? { value: row.icmscst.id, label: row.icmscst.nome } : null}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].icmscst = {};
                                                                                                                        dados.vendaitem[rowIndex].icmscst.id = e.value;
                                                                                                                        dados.vendaitem[rowIndex].icmscst.nome = e.label;
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                    loadOptions={(input) => {
                                                                                                                        return api({
                                                                                                                            method: 'get',
                                                                                                                            action: '/fiscalCst',
                                                                                                                            token: token,
                                                                                                                            params: {
                                                                                                                                tributo: ["icms", "csosn"],
                                                                                                                                nome: input
                                                                                                                            }
                                                                                                                        })
                                                                                                                            .then(response => {
                                                                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                                                                                return options;
                                                                                                                            })
                                                                                                                            .catch(function (error) {
                                                                                                                                //console.log(error);
                                                                                                                            });
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="icmsbasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].icmsbasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].icmsbasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="icmsaliquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].icmsaliquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].icmsaliquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="icmsvalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].icmsvalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].icmsvalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="ipi" title="IPI">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-group">
                                                                                                                <label>CST</label>
                                                                                                                <ReactSelect
                                                                                                                    value={row.ipicst !== null ? { value: row.ipicst.id, label: row.ipicst.nome } : null}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].ipicst = {};
                                                                                                                        dados.vendaitem[rowIndex].ipicst.id = e.value;
                                                                                                                        dados.vendaitem[rowIndex].ipicst.nome = e.label;
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                    loadOptions={(input) => {
                                                                                                                        return api({
                                                                                                                            method: 'get',
                                                                                                                            action: '/fiscalCst',
                                                                                                                            token: token,
                                                                                                                            params: {
                                                                                                                                tributo: ["ipi"],
                                                                                                                                tipo: ["entrada"],
                                                                                                                                nome: input
                                                                                                                            }
                                                                                                                        })
                                                                                                                            .then(response => {
                                                                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                                                                                return options;
                                                                                                                            })
                                                                                                                            .catch(function (error) {
                                                                                                                                //console.log(error);
                                                                                                                            });
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="ipibasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].ipibasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].ipibasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="ipialiquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].ipialiquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].ipialiquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="ipivalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].ipivalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].ipivalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="pis" title="PIS">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-group">
                                                                                                                <label>CST</label>
                                                                                                                <ReactSelect
                                                                                                                    value={row.piscst !== null ? { value: row.piscst.id, label: row.piscst.nome } : null}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].piscst = {};
                                                                                                                        dados.vendaitem[rowIndex].piscst.id = e.value;
                                                                                                                        dados.vendaitem[rowIndex].piscst.nome = e.label;
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                    loadOptions={(input) => {
                                                                                                                        return api({
                                                                                                                            method: 'get',
                                                                                                                            action: '/fiscalCst',
                                                                                                                            token: token,
                                                                                                                            params: {
                                                                                                                                tributo: ["pisCofins"],
                                                                                                                                tipo: ["entrada"],
                                                                                                                                nome: input
                                                                                                                            }
                                                                                                                        })
                                                                                                                            .then(response => {
                                                                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                                                                                return options;
                                                                                                                            })
                                                                                                                            .catch(function (error) {
                                                                                                                                //console.log(error);
                                                                                                                            });
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="pisbasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].pisbasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].pisbasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="pisaliquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].pisaliquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].pisaliquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="pisvalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].pisvalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].pisvalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                                <Tab eventKey="cofins" title="COFINS">
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-group">
                                                                                                                <label>CST</label>
                                                                                                                <ReactSelect
                                                                                                                    value={row.cofinscst !== null ? { value: row.cofinscst.id, label: row.cofinscst.nome } : null}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].cofinscst = {};
                                                                                                                        dados.vendaitem[rowIndex].cofinscst.id = e.value;
                                                                                                                        dados.vendaitem[rowIndex].cofinscst.nome = e.label;
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                    loadOptions={(input) => {
                                                                                                                        return api({
                                                                                                                            method: 'get',
                                                                                                                            action: '/fiscalCst',
                                                                                                                            token: token,
                                                                                                                            params: {
                                                                                                                                tributo: ["pisCofins"],
                                                                                                                                tipo: ["entrada"],
                                                                                                                                nome: input
                                                                                                                            }
                                                                                                                        })
                                                                                                                            .then(response => {
                                                                                                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.codigo + " - " + registro.nome }));
                                                                                                                                return options;
                                                                                                                            })
                                                                                                                            .catch(function (error) {
                                                                                                                                //console.log(error);
                                                                                                                            });
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="cofinsbasecalculo" placeholder="Base de Cálculo"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].cofinsbasecalculo)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].cofinsbasecalculo = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Base de Cálculo</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="cofinsaliquota" placeholder="Alíquota (%)"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].cofinsaliquota)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].cofinsaliquota = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Alíquota (%)</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-4">
                                                                                                            <div className="form-floating form-group">
                                                                                                                <input type="text" className="form-control form-control-lg right" id="cofinsvalor" placeholder="Valor"
                                                                                                                    value={pontoVirgula(dados.vendaitem[rowIndex].cofinsvalor)}
                                                                                                                    onChange={(e) => {
                                                                                                                        dados.vendaitem[rowIndex].cofinsvalor = e.target.value;
                                                                                                                        calculaTotalCompra();
                                                                                                                        setDados({ ...dados });
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label htmlFor="floatingInput">Valor</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tab>
                                                                                            </Tabs>
                                                                                        </ModalButton>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg right" id="quantidade" placeholder="Quantidade"
                                                                                                value={pontoVirgula(dados.vendaitem[rowIndex].quantidade)}
                                                                                                onChange={(e) => {
                                                                                                    dados.vendaitem[rowIndex].quantidade = e.target.value;
                                                                                                    calculaTotalCompra();
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Quantidade</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <ReactSelect
                                                                                            value={row.produto !== null ? { value: row.produto.id, label: row.produto.nome } : null}
                                                                                            onChange={(e) => {
                                                                                                dados.vendaitem[rowIndex].produto = {};
                                                                                                dados.vendaitem[rowIndex].produto.id = e.value;
                                                                                                dados.vendaitem[rowIndex].produto.nome = e.label;
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                            defaultOptions
                                                                                            loadOptions={(input) => {
                                                                                                return api({
                                                                                                    method: 'get',
                                                                                                    action: '/produto',
                                                                                                    token: token,
                                                                                                    params: {
                                                                                                        nome: input,
                                                                                                    }
                                                                                                })
                                                                                                    .then(response => {
                                                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                                        return options;
                                                                                                    })
                                                                                                    .catch(function (error) {
                                                                                                        //console.log(error);
                                                                                                    });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg right" id="valorUnitario" placeholder="Valor"
                                                                                                value={pontoVirgula(dados.vendaitem[rowIndex].valorUnitario)}
                                                                                                onChange={(e) => {
                                                                                                    dados.vendaitem[rowIndex].valorUnitario = e.target.value;
                                                                                                    dados.vendaitem[rowIndex].issbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].irrfbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].csllbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].icmsbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].ipibasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].pisbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].cofinsbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].icmsbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    dados.vendaitem[rowIndex].inssbasecalculo = dados.vendaitem[rowIndex].valorUnitario;
                                                                                                    calculaTotalCompra();
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Valor</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg right" id="desconto" placeholder="Desconto"
                                                                                                value={pontoVirgula(dados.vendaitem[rowIndex].desconto)}
                                                                                                onChange={(e) => {
                                                                                                    dados.vendaitem[rowIndex].desconto = e.target.value;
                                                                                                    calculaTotalCompra();
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Desconto</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg right" id="adicional" placeholder="Adicional"
                                                                                                value={pontoVirgula(dados.vendaitem[rowIndex].adicional)}
                                                                                                onChange={(e) => {
                                                                                                    dados.vendaitem[rowIndex].adicional = e.target.value;
                                                                                                    calculaTotalCompra();
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Adicional</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input readOnly type="text" className="form-control form-control-lg right" id="total" placeholder="Total"
                                                                                                value={pontoVirgula(dados.vendaitem[rowIndex].total)}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Total</label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ];
                                                                        }) : null}
                                                                        <tr>
                                                                            <td colSpan="4">
                                                                            </td>
                                                                            <td>
                                                                                <div className="form-floating form-group">
                                                                                    <input readOnly type="text" className="form-control form-control-lg right" id="total" placeholder="Total"
                                                                                        value={pontoVirgula(dados.valorTotal)}
                                                                                    />
                                                                                    <label htmlFor="floatingInput">Total</label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            dados.vendaitem.push({
                                                                issaliquotacobrado: dados.issaliquota,
                                                                isszerado: "nao",
                                                                quantidade: "1",
                                                                produto: {
                                                                    id: null,
                                                                    nome: null
                                                                },
                                                                issindicador: {
                                                                    id: null,
                                                                    nome: null
                                                                },
                                                                icmscst: {
                                                                    id: null,
                                                                    nome: null
                                                                },
                                                                ipicst: {
                                                                    id: null,
                                                                    nome: null
                                                                },
                                                                piscst: {
                                                                    id: null,
                                                                    nome: null
                                                                },
                                                                cofinscst: {
                                                                    id: null,
                                                                    nome: null
                                                                },
                                                            });
                                                            setDados({ ...dados });
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Item</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
