import React from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';
import Processando from './Processando';

import 'semantic-ui-css/semantic.min.css';
import '../css/bootstrap.css';
import '../css/app.css';
import '../css/custom.css';

export default function ErpTemplate({ children, sidebar = true, processando = false }) {
    return (
        <>
            <NavBar />
            {sidebar === true ? <SideBar /> : null}
            <div id="main" style={{ marginLeft: sidebar === true ? "75px" : "0px" }}>
                {children}
                <div style={{ height: "100px" }}></div>
                {processando && <Processando />}
            </div>
        </>
    );
}

