import React, { useState } from "react";
import { FaPlus, FaTrash, FaList } from 'react-icons/fa';
import { data } from "../util/util";
import IconButton from "./IconButton";
import ReactSelect from "./ReactSelect";
import CadastroHistoricoAtividadeChecklist from "./CadastroHistoricoAtividadeChecklist";

export default function ItemChecklist({ dados, stateChanger }) {
    const token = localStorage.getItem('token');
    const [dados2, setDados2] = useState({});
    const [modalStates, setModalStates] = useState([]);

    // Função para abrir um modal específico
    const abrirModal = (atividadeId) => {
        const newModalStates = [...modalStates];
        newModalStates[atividadeId] = true;
        setModalStates(newModalStates);
    };

    // Função para fechar um modal específico
    const fecharModal = (atividadeId) => {
        const newModalStates = [...modalStates];
        newModalStates[atividadeId] = false;
        setModalStates(newModalStates);
    };

    if (dados?.itens !== undefined && dados?.itens?.length > 0) {
        return <>{dados.itens.map((item, index) => {
            var labelPrazo = <></>;
            var corCaixa = "#DDDDDD";
            if (dados.itens[index].situacao === "1") { //1 - pendente
                corCaixa = "#FFFFCC";
                if ((dados.itens[index].distanciaEvento ?? 0) > 0) { //restam
                    labelPrazo = <div style={{ color: "#00AA00", marginLeft: "5px", display: 'inline-block', minWidth: "100px" }}>
                        restam {dados.itens[index].distanciaEvento ?? ""} dias
                    </div>;
                } else { //atraso
                    labelPrazo = <div style={{ color: "#e80d0d", marginLeft: "5px", display: 'inline-block', minWidth: "100px" }}>
                        atraso de {(dados.itens[index].distanciaEvento * -1) ?? ""} dias
                    </div>;
                }
            }
            if (dados.itens[index].situacao === "2") { //2 - finalizada
                corCaixa = "#CCFFCC";
                labelPrazo = <div style={{ color: "#00AA00", marginLeft: "5px", display: 'inline-block', minWidth: "100px" }}>
                    finalizada em {data(dados.itens[index].dataFim ?? new Date().toString())}
                </div>;
            }

            return <div
                key={index}
                style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    padding: "10px",
                    border: "1px solid #dfe3e7",
                    borderRadius: "5px"
                }}>
                <div>
                    <div style={{ display: 'inline-block' }}>
                        <IconButton
                            style={{ marginRight: "5px" }}
                            small={true}
                            icon={<FaList size={20} />}
                            color={"primary"}
                            onClick={() => abrirModal(dados.itens[index].id)}
                        />
                        <CadastroHistoricoAtividadeChecklist
                            key={dados.itens[index].id}
                            idItem={dados.itens[index].id}
                            isOpen={modalStates[dados.itens[index].id] || false}
                            onClose={() => fecharModal(dados.itens[index].id)}
                        />
                    </div>
                    <div style={{ display: 'inline-block', minWidth: "500px" }}>
                        <input style={{ backgroundColor: corCaixa }} readOnly type="text" className="form-control form-control-lg" placeholder="Atividade"
                            value={dados.itens[index].atividade?.nome ?? ""}
                        />
                    </div>
                    <div style={{ marginLeft: "5px", display: 'inline-block', minWidth: "150px" }}>
                        <ReactSelect
                            value={{ value: dados.itens[index].situacao ?? "", label: dados.itens[index].nomeSituacao ?? "" }}
                            onChange={(e) => {
                                dados.itens[index].situacao = e.value;
                                dados.itens[index].nomeSituacao = e.label;
                                setDados2({ value: dados.itens[index].situacao, label: dados.itens[index].nomeSituacao });
                            }}
                            options={[
                                { value: '1', label: 'Pendente' },
                                { value: '2', label: 'Finalizada' },
                                { value: '3', label: 'Não se Aplica' }
                            ]}
                        />
                    </div>
                    {labelPrazo}
                    <ItemChecklist dados={item} stateChanger={stateChanger} />
                </div>
            </div>;
        })}
        </>;
    } else {
        return <div></div>;
    }
}