import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { data, dataMysql, extract, pontoVirgula, virgulaPonto } from '../../util/util';
import Data from '../../components/Data';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

const ExpanableComponent = ({ data, callBack, token, dados, setDados, calculaTotalOrcamento, ...props }) => {
    return <table className='table mb-0' id="table1">
        <tbody>
            {data.subitem ? data.subitem.map((row, rowIndex) => {
                return [
                    <>
                        <tr>
                            <td>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg" placeholder="Fornecedor Pagamento"
                                        value={data.subitem[rowIndex]?.fornecedorpagamento?.nome || ""}
                                        readOnly
                                    />
                                    <label htmlFor="floatingInput">Fornecedor Pagamento</label>
                                </div>
                            </td>
                            <td width={"110px"}>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg right" placeholder="Valor Real"
                                        value={pontoVirgula(data.subitem[rowIndex].pagamentovalorreal)}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].pagamentovalorreal = e.target.value;
                                            calculaTotalOrcamento();
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Valor Real</label>
                                </div>
                            </td>
                            <td width={"110px"}>
                                <div className="form-floating form-group">
                                    <input readOnly type="text" className="form-control form-control-lg right" id="totalFechamento" placeholder="Cobrado"
                                        value={pontoVirgula(data.subitem[rowIndex].totalFechamento)}
                                    />
                                    <label htmlFor="floatingInput">Cobrado</label>
                                </div>
                            </td>
                            <td width={"110px"}>
                                <div className="form-floating form-group">
                                    <input readOnly type="text" className="form-control form-control-lg right" id="pagamentosavingreal" placeholder="Saving"
                                        value={pontoVirgula(data.subitem[rowIndex].pagamentosavingreal)}
                                    />
                                    <label htmlFor="floatingInput">Saving</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg" placeholder="Produto"
                                        value={data?.subitem[rowIndex]?.produto?.nome || ""}
                                        readOnly
                                    />
                                    <label htmlFor="floatingInput">Produto</label>
                                </div>
                            </td>
                            <td width={"170px"}>
                                <ReactSelect
                                    value={row.pagamentoforma !== null ? { value: row.pagamentoforma.id, label: row.pagamentoforma.nome } : null}
                                    onChange={(e) => {
                                        data.subitem[rowIndex].pagamentoforma = {};
                                        data.subitem[rowIndex].pagamentoforma.id = e.value;
                                        data.subitem[rowIndex].pagamentoforma.nome = e.label;
                                        setDados({ ...dados });
                                    }}
                                    defaultOptions
                                    loadOptions={(input) => {
                                        return api({
                                            method: 'get',
                                            action: '/financeiroEspecie',
                                            token: token,
                                            params: {
                                                nome: input,
                                            }
                                        })
                                            .then(response => {
                                                let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                return options;
                                            })
                                            .catch(function (error) {
                                                //console.log(error);
                                            });
                                    }}
                                />
                            </td>
                            <td width={"110px"}>
                                <ReactSelect
                                    value={row.pagamentoprazo !== null ? { value: row.pagamentoprazo, label: row.pagamentoprazoNome } : '0'}
                                    onChange={(e) => {
                                        data.subitem[rowIndex].pagamentoprazo = e.value;
                                        data.subitem[rowIndex].pagamentoprazoNome = e.label;
                                        setDados({ ...dados });
                                    }}
                                    options={[
                                        { value: '0', label: 'À vista' },
                                        { value: '15', label: '15 dias' },
                                        { value: '30', label: '30 dias' },
                                        { value: '60', label: '60 dias' },
                                        { value: '90', label: '90 dias' },
                                        { value: '120', label: '120 dias' },
                                    ]}
                                />
                            </td>
                            <td width={"150px"}>
                                <Data
                                    label="Vencimento"
                                    value={row.pagamentovencimento}
                                    onChange={(date) => {
                                        data.subitem[rowIndex].pagamentovencimento = date;
                                        setDados({ ...dados });
                                    }}
                                />
                            </td>
                            <td width={"150px"}>
                                <Data
                                    label="Entrega NF"
                                    value={row.pagamentoentreganf}
                                    onChange={(date) => {
                                        data.subitem[rowIndex].pagamentoentreganf = date;
                                        setDados({ ...dados });
                                    }}
                                />
                            </td>
                        </tr>
                        <tr style={{ zoom: "0.7" }}>
                            <td colSpan="12">
                                <div className="form-floating form-group">
                                    <input type="text" className="form-control form-control-lg" placeholder="Observação"
                                        value={row.pagamentoobservacao}
                                        onChange={(e) => {
                                            data.subitem[rowIndex].pagamentoobservacao = e.target.value;
                                            setDados({ ...dados });
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Observação</label>
                                </div>
                            </td>
                        </tr>
                    </>
                ];
            }) : null}
        </tbody>
    </table >;
}

export default function CadastroOrcamento({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const usuario = localStorage.getItem("idUsuario");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({
        modulo: 1,
        orcamentoitem: []
    });// 1 - orcamento
    const history = useHistory();
    let { state } = useLocation();

    const calculaTotalOrcamento = () => {
        calculaTotalOrcamento2();
        calculaTotalOrcamento2();
    }//calculaTotalOrcamento

    const calculaTotalOrcamento2 = () => {
        //var valorTotalFinalFechamento = 0;
        dados.orcamentoitem.forEach(function (valor, indexsuperior) {
            //dados.orcamentoitem[indexsuperior]["valorTotalFechamento"] = 0;
            dados.orcamentoitem[indexsuperior].subitem.forEach(function (valor, indexinferior) {
                var pagamentovalorreal = virgulaPonto(dados.orcamentoitem[indexsuperior].subitem[indexinferior].pagamentovalorreal);
                var totalFechamento = virgulaPonto(dados.orcamentoitem[indexsuperior].subitem[indexinferior].totalFechamento);
                var pagamentosavingreal = pagamentovalorreal - totalFechamento;
                if (pagamentosavingreal < 0) pagamentosavingreal = 0;
                dados.orcamentoitem[indexsuperior].subitem[indexinferior].pagamentosavingreal = pagamentosavingreal.toFixed(2);
                //var quantidadeFechamento = virgulaPonto(dados.orcamentoitem[indexsuperior].subitem[indexinferior].quantidadeFechamento);
                //var valorUnitarioFechamento = virgulaPonto(dados.orcamentoitem[indexsuperior].subitem[indexinferior].valorUnitarioFechamento);
                //var diariaFechamento = virgulaPonto(dados.orcamentoitem[indexsuperior].subitem[indexinferior].diariaFechamento);

                //var totalFechamento = quantidadeFechamento * diariaFechamento * valorUnitarioFechamento;
                //dados.orcamentoitem[indexsuperior].valorTotalFechamento += totalFechamento;
                //dados.orcamentoitem[indexsuperior].subitem[indexinferior].totalFechamento = totalFechamento.toFixed(2);
            });
            //valorTotalFinalFechamento += dados.orcamentoitem[indexsuperior].valorTotalFechamento;
            //dados.orcamentoitem[indexsuperior].valorTotalFechamento = dados.orcamentoitem[indexsuperior].valorTotalFechamento.toFixed(2);
        });
        //dados.valorTotalFechamento = valorTotalFinalFechamento.toFixed(2);
    }//calculaTotalOrcamento

    const buscar = () => {
        api({
            method: "get",
            action: '/orcamento/' + id,
            token: token,
            params: {
                apenasPagamentos: "sim",
            }
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: ['evento']
            });
            //transforma data para javascript
            dadosRecebidos.pagamentodata = data(dadosRecebidos.pagamentodata);

            dadosRecebidos.orcamentoitem.forEach(function (item, index) {
                item.subitem.forEach(function (subitem, indexsubitem) {
                    dadosRecebidos.orcamentoitem[index].subitem[indexsubitem].pagamentovencimento = data(dadosRecebidos.orcamentoitem[index].subitem[indexsubitem].pagamentovencimento);
                    dadosRecebidos.orcamentoitem[index].subitem[indexsubitem].pagamentoentreganf = data(dadosRecebidos.orcamentoitem[index].subitem[indexsubitem].pagamentoentreganf);
                });
            });

            setDados(dadosRecebidos);
        }).catch(err => {
            // console.log(err)
        });
    };

    const validar = () => {
        //if ((dados.orcamentoproduto === undefined) || (dados.orcamentoproduto.length === 0)) {
        //    toast.error('É necessário adicionar produtos');
        //    return false;
        //}
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }
        if (dados.pagamentodata) {
            dados.pagamentodata = dataMysql(dados.pagamentodata);
        }

        dados.orcamentoitem.forEach(function (item, index) {
            item.subitem.forEach(function (subitem, indexsubitem) {
                dados.orcamentoitem[index].subitem[indexsubitem].pagamentovencimento = dataMysql(dados.orcamentoitem[index].subitem[indexsubitem].pagamentovencimento);
                dados.orcamentoitem[index].subitem[indexsubitem].pagamentoentreganf = dataMysql(dados.orcamentoitem[index].subitem[indexsubitem].pagamentoentreganf);
            });
        });

        if (id) {//update
            api({
                method: 'put',
                action: '/orcamento/pagamento/' + id,
                token: token,
                data: {
                    ...dados,
                    ...{ operador: usuario }
                }
            })
                .then(function (response) {
                    history.push("/orcamento");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert
            //nao tem opção de insert, apenas update
        }
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Pagamento</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="evento" placeholder="Evento"
                                                            value={dados.nomeEvento}
                                                            readOnly
                                                        />
                                                        <label htmlFor="floatingInput">Evento</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data"
                                                        value={dados.pagamentodata}
                                                        onChange={date => setDados({ ...dados, ...{ "pagamentodata": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <DataTable
                                                                expandableRows
                                                                expandableRowsComponent={ExpanableComponent}
                                                                expandableRowsComponentProps={{
                                                                    token: token,
                                                                    setDados: setDados,
                                                                    calculaTotalOrcamento: calculaTotalOrcamento,
                                                                    dados: dados
                                                                }}
                                                                noDataComponent="Nenhum registro"
                                                                className="table mb-0"
                                                                columns={[
                                                                    {
                                                                        width: "400px",
                                                                        cell: (row, index) =>
                                                                            <div style={{ width: '400px' }}>
                                                                                <div className="form-floating form-group">
                                                                                    <input type="text" className="form-control form-control-lg" placeholder="Grupo"
                                                                                        value={dados?.orcamentoitem[index]?.produto?.nome || ""}
                                                                                        readOnly
                                                                                    />
                                                                                    <label htmlFor="floatingInput">Grupo</label>
                                                                                </div>
                                                                            </div>
                                                                    },
                                                                ]}
                                                                data={dados.orcamentoitem}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}