import React, { useState } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSearch } from 'react-icons/fa';
import Data from '../../components/Data';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from '../../components/ReactSelect';

export default function RelatorioDre() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const [filtro, setFiltro] = useState({
        empresa: empresa,
    });

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Demonstrativo de Resultado do Exercício - DRE</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" method="post" target="_blank" action={process.env.REACT_APP_API_URL + "/financeiroParcela/relatorioDre"}>
                                            <input type="hidden" name="token" value={token} />
                                            <input type="hidden" name="empresa" value={empresa} />
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="pagamento1"
                                                        label="Data Pagamento"
                                                        value={filtro.pagamento1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "pagamento1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="pagamento2"
                                                        label="até a Data Pagamento"
                                                        value={filtro.pagamento2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "pagamento2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
