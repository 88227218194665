import React, { useState, useEffect } from "react";
import api from '../services/api';

export default function MediaExecucao({ label = "Média", ids = "" }) {
    const token = localStorage.getItem('token');
    const [media, setMedia] = useState(0);
    const buscar = () => {
        api({
            method: 'get',
            action: '/slaCompromissoData/mediaExecucao',
            token: token,
            params: { ids: ids }
        })
            .then(response => {
                //                console.log(response.data.data);
                setMedia(response.data.data.media);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => {
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    return (
        <div className="card card-statistic">
            <div className="card-body p-0">
                <div className="d-flex flex-column">
                    <div className="px-3 py-3 d-flex justify-content-between">
                        <h3 className="card-title">{label}</h3>
                        <div className="card-right d-flex align-items-center">
                            <p>{media}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}