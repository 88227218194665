import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaTable } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import FormButton from '../../components/FormButton';
import api from '../../services/api';

export default function EventoRelatorioParticipante({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [checkTodos, setCheckTodos] = useState({ todos: 'sim' });
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    let { state } = useLocation();
    const [dadosEvento, setDadosEvento] = useState({});
    const [campos, setCampos] = useState({
        nomeOriginal: 'sim',
        tipoparticipante: 'sim',
        idconvidado: 'sim',
        especialidade: 'sim',
        crm: 'sim',
        cargo: 'sim',
        instituicao: 'sim',
        socio: 'sim',
        telefone: 'sim',
        celular: 'sim',
        email: 'sim',
        datanascimento: 'sim',
        cpf: 'sim',
        rg: 'sim',
        passaporte: 'sim',
        passaportevencimento: 'sim',
        enderecologradouro: 'sim',
        enderecocep: 'sim',
        enderecobairro: 'sim',
        aereomunicipioembarque: 'sim',
        enderecomunicipionome: 'sim',
        enderecomunicipiouf: 'sim',
        produto: 'sim',
        ordeminterna: 'sim',
        gerentecomercial: 'sim',
        gerentedistrital: 'sim',
        gerentedistritalemail: 'sim',
        representante: 'sim',
        representanteemail: 'sim',
        representantetelefone: 'sim',
        vinculoOrgaoPublico: 'sim',
        bagagemDespachada: 'sim',
        assinaturaCartaConvite: 'sim',
        inscricao: 'sim',
        hotelhospedagem: 'sim',
        hotelnome: 'sim',
        hotelobservacao: 'sim',
        aereo: 'sim',
        aereoida: 'sim',
        aereoidaobservacao: 'sim',
        aereoretorno: 'sim',
        aereoretornoobservacao: 'sim',
        transfer: 'sim',
        transferobservacao: 'sim',
        transferextra: 'sim',
        transferextraobservacao: 'sim',
        situacaoevento: 'sim',
        observacao: 'sim',
        presentenoevento: 'sim',
        inscricaonumero: 'sim',
        dataopcaoaereo: 'sim',
        datacartaconvite: 'sim',
        datacartaassinada: 'sim',
        datacartainformativa: 'sim',
        dataconfirmacaocartainformativa: 'sim',
    });

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/evento/' + id,
                token: token,
            }).then(response => { //extrai dados internos para o array principal
                var dadosRecebidos = response.data.data;
                setDadosEvento(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Evento: {dadosEvento.nome ?? ""}</h3>
                    <h3>Relatorio Participantes</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={checkTodos.todos}
                                                                checked={checkTodos.todos === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    checkTodos.todos = checkTodos.todos === "nao" ? "sim" : "nao";
                                                                    Object.keys(campos).forEach(key => {
                                                                        campos[key] = checkTodos.todos;
                                                                    });
                                                                    setCampos({ ...campos });
                                                                    setCheckTodos({ ...checkTodos });
                                                                }} />
                                                            TODOS</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.nomeOriginal}
                                                                checked={campos.nomeOriginal === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.nomeOriginal = campos.nomeOriginal === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Nome Original</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.tipoparticipante}
                                                                checked={campos.tipoparticipante === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.tipoparticipante = campos.tipoparticipante === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Tipo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.idconvidado}
                                                                checked={campos.idconvidado === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.idconvidado = campos.idconvidado === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            ID</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.especialidade}
                                                                checked={campos.especialidade === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.especialidade = campos.especialidade === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Especialidade</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.crm}
                                                                checked={campos.crm === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.crm = campos.crm === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            CRM</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.cargo}
                                                                checked={campos.cargo === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.cargo = campos.cargo === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Cargo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.instituicao}
                                                                checked={campos.instituicao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.instituicao = campos.instituicao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Instituição</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.socio}
                                                                checked={campos.socio === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.socio = campos.socio === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Sócio</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.telefone}
                                                                checked={campos.telefone === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.telefone = campos.telefone === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Telefone</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.celular}
                                                                checked={campos.celular === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.celular = campos.celular === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Celular</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.email}
                                                                checked={campos.email === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.email = campos.email === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            E-mail</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.datanascimento}
                                                                checked={campos.datanascimento === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.datanascimento = campos.datanascimento === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Data de Nascimento</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.cpf}
                                                                checked={campos.cpf === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.cpf = campos.cpf === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            CPF</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.rg}
                                                                checked={campos.rg === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.rg = campos.rg === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            RG</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.passaporte}
                                                                checked={campos.passaporte === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.passaporte = campos.passaporte === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Passa Porte</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.passaportevencimento}
                                                                checked={campos.passaportevencimento === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.passaportevencimento = campos.passaportevencimento === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Vencimento Passaporte</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.enderecologradouro}
                                                                checked={campos.enderecologradouro === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.enderecologradouro = campos.enderecologradouro === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Endereço</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.enderecocep}
                                                                checked={campos.enderecocep === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.enderecocep = campos.enderecocep === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            CEP</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.enderecobairro}
                                                                checked={campos.enderecobairro === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.enderecobairro = campos.enderecobairro === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Bairro</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.enderecomunicipionome}
                                                                checked={campos.enderecomunicipionome === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.enderecomunicipionome = campos.enderecomunicipionome === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Cidade</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.enderecomunicipiouf}
                                                                checked={campos.enderecomunicipiouf === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.enderecomunicipiouf = campos.enderecomunicipiouf === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Estado</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.produto}
                                                                checked={campos.produto === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.produto = campos.produto === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Produto</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.ordeminterna}
                                                                checked={campos.ordeminterna === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.ordeminterna = campos.ordeminterna === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Ordem Interna</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.gerentecomercial}
                                                                checked={campos.gerentecomercial === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.gerentecomercial = campos.gerentecomercial === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Gerente Comercial</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.gerentedistrital}
                                                                checked={campos.gerentedistrital === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.gerentedistrital = campos.gerentedistrital === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Gerente Distrital</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.gerentedistritalemail}
                                                                checked={campos.gerentedistritalemail === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.gerentedistritalemail = campos.gerentedistritalemail === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            E-mail Ger. Distr.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.representante}
                                                                checked={campos.representante === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.representante = campos.representante === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Representante</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.representanteemail}
                                                                checked={campos.representanteemail === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.representanteemail = campos.representanteemail === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            E-mail Representante</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.representantetelefone}
                                                                checked={campos.representantetelefone === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.representantetelefone = campos.representantetelefone === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Telefone Representante</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.vinculoOrgaoPublico}
                                                                checked={campos.vinculoOrgaoPublico === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.vinculoOrgaoPublico = campos.vinculoOrgaoPublico === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Vínculo Órgão Público</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.assinaturaCartaConvite}
                                                                checked={campos.assinaturaCartaConvite === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.assinaturaCartaConvite = campos.assinaturaCartaConvite === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Assinatura Carta Convite</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.inscricao}
                                                                checked={campos.inscricao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.inscricao = campos.inscricao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Inscrição</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.hotelhospedagem}
                                                                checked={campos.hotelhospedagem === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.hotelhospedagem = campos.hotelhospedagem === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Hospedagem</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.hotelnome}
                                                                checked={campos.hotelnome === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.hotelnome = campos.hotelnome === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Hotel</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.hotelobservacao}
                                                                checked={campos.hotelobservacao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.hotelobservacao = campos.hotelobservacao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Obs. de Hospedagem</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.aereo}
                                                                checked={campos.aereo === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.aereo = campos.aereo === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Aéreo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.bagagemDespachada}
                                                                checked={campos.bagagemDespachada === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.bagagemDespachada = campos.bagagemDespachada === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Bagagem Despachada</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.aereomunicipioembarque}
                                                                checked={campos.aereomunicipioembarque === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.aereomunicipioembarque = campos.aereomunicipioembarque === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Cidade Embarque</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.aereoida}
                                                                checked={campos.aereoida === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.aereoida = campos.aereoida === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Dados Aéreo (Ida)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.aereoidaobservacao}
                                                                checked={campos.aereoidaobservacao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.aereoidaobservacao = campos.aereoidaobservacao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Obs. de Aéreo (Ida)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.aereoretorno}
                                                                checked={campos.aereoretorno === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.aereoretorno = campos.aereoretorno === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Dados Aéreo (Retorno)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.aereoretornoobservacao}
                                                                checked={campos.aereoretornoobservacao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.aereoretornoobservacao = campos.aereoretornoobservacao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Obs. de Aéreo (Retorno)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.transfer}
                                                                checked={campos.transfer === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.transfer = campos.transfer === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Transfer</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.transferobservacao}
                                                                checked={campos.transferobservacao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.transferobservacao = campos.transferobservacao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Obs. de Transfer</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.transferextra}
                                                                checked={campos.transferextra === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.transferextra = campos.transferextra === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Transfer Extra</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.transferextraobservacao}
                                                                checked={campos.transferextraobservacao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.transferextraobservacao = campos.transferextraobservacao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Obs. de Transfer Extra</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.situacaoevento}
                                                                checked={campos.situacaoevento === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.situacaoevento = campos.situacaoevento === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Status</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.observacao}
                                                                checked={campos.observacao === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.observacao = campos.observacao === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Observação</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.presentenoevento}
                                                                checked={campos.presentenoevento === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.presentenoevento = campos.presentenoevento === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Presente no evento</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.necessidadeespecial}
                                                                checked={campos.necessidadeespecial === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.necessidadeespecial = campos.necessidadeespecial === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Necessidade Especial</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.inscricaonumero}
                                                                checked={campos.inscricaonumero === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.inscricaonumero = campos.inscricaonumero === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Número Inscrição</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.dataopcaoaereo}
                                                                checked={campos.dataopcaoaereo === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.dataopcaoaereo = campos.dataopcaoaereo === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Opção Aéreo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.datacartaconvite}
                                                                checked={campos.datacartaconvite === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.datacartaconvite = campos.datacartaconvite === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Data Carta Convite</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.datacartaassinada}
                                                                checked={campos.datacartaassinada === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.datacartaassinada = campos.datacartaassinada === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Data Aceite Convite</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.datacartainformativa}
                                                                checked={campos.datacartainformativa === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.datacartainformativa = campos.datacartainformativa === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Data Carta Informativa</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={campos.dataconfirmacaocartainformativa}
                                                                checked={campos.dataconfirmacaocartainformativa === "sim" ? true : false}
                                                                onChange={(value) => {
                                                                    campos.dataconfirmacaocartainformativa = campos.dataconfirmacaocartainformativa === "nao" ? "sim" : "nao";
                                                                    setCampos({ ...campos });
                                                                }} />
                                                            Data Confirmação Carta Informativa</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <FormButton
                                                        method="post"
                                                        action={process.env.REACT_APP_API_URL + "/evento/exportarParticipantesExcel"}
                                                        icon={<FaTable size={20} />}
                                                        color="primary"
                                                        data={{
                                                            token: localStorage.getItem('token'),
                                                            campos: campos,
                                                            idEvento: id
                                                        }}
                                                    >Gerar Relatorio</FormButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}