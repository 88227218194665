import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaBan, FaCheck } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import { extract } from '../../../util/util';
import { toast } from 'react-toastify';
import MostrarHTML from '../../../components/MostrarHtml';

export default function CadastroEventoParticipanteCartaInformativa({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [concordaConvite, setConcordaConvite] = useState(false);
    const history = useHistory();

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });

            api({
                method: "get",
                action: '/eventoCarta/visualizarCartaHtml/' + id,
                token: token,
                params: {
                    tipoCarta: "modelocarta",
                }
            }).then(response => {
                setDados({
                    ...dados,
                    ...dadosRecebidos,
                    ...{ htmlCarta: response.data.data.htmlCarta }
                })
            }).catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            });
        }).catch(err => {
            //console.log(err)
        });
    };//buscar

    const salvar = (aceitouConvite) => {
        /*
        api({
            method: 'put',
            action: '/participante/aceitarConvite/' + id,
            token: token,
            data: { aceitouConvite: aceitouConvite ? 'sim' : 'nao' },
        })
            .then(function (response) {
                if (aceitouConvite) {
                    history.push("/cadastroEventoParticipanteAceitarTermo/" + id);
                } else {
                    history.push("/cadastroEventoParticipanteConviteRecusado/" + dados.evento.id);
                }
            })
            .catch(function (error) {
                //alert(error)
            });
            */
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Carta Informativa</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <MostrarHTML
                                                    htmlContent={dados.htmlCarta ?? null}
                                                />
                                            </div>
                                        </div>
                                        {/*
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <div className="form-check">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                value={concordaConvite}
                                                                checked={concordaConvite}
                                                                onChange={(value) => {
                                                                    setConcordaConvite(!concordaConvite);
                                                                }} />
                                                            Eu concordo com os termos acima</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger me-1 mb-1"
                                                    onClick={() => {
                                                        salvar(false);
                                                    }}
                                                >
                                                    <FaBan size={20} /> Não aceito o convite
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-success me-1 mb-1"
                                                    disabled={!concordaConvite}
                                                    onClick={() => {
                                                        salvar(true);
                                                    }}
                                                >
                                                    <FaCheck size={20} /> Aceito o convite
                                                </button>
                                            </div>
                                        </div>
                                        */
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </ErpTemplate >
    );
}