import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaChartPie, FaCodeBranch, FaRegCalendarCheck, FaPen, FaList, FaPlus, FaSearch } from 'react-icons/fa';
import { GiStairsGoal } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactSelect from '../../components/ReactSelect';
import FormButton from '../../components/FormButton';

export default function PainelSla() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const [filtro, setFiltro] = useState({
        empresa: empresa,
        modelo: "sim"
    });
    const [slas, setSlas] = useState([]);
    const [tableSelect, setTableSelect] = useState([]);
    const [tableActions, setTableActions] = useState([]);

    const buscar = () => {
        setFiltro({...filtro,...{buscando: true}});
        api({
            method: 'get',
            action: '/sla',
            token: token,
            params: filtro
        })
            .then(response => {
                setSlas(response.data.data);
                setFiltro({...filtro,...{buscando: false}});
            }).catch(err => {
                // console.log(err)
            })
    };

    useEffect(() => { //onload
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    useEffect(() => { //onload
        buscar();
    }, 
    // eslint-disable-next-line
    [filtro.tipo, filtro.evento]);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";

        if (tableSelect.selectedCount > 0) {
            setTableActions([]);
        } else {
            setTableActions([]);
        }
    }, 
    // eslint-disable-next-line
    [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/cadastroSlaModelo" icon={<FaPlus size={20} />} color="primary">Novo Modelo de Sla</NavItem>
                    <NavItem to="/slaTipo" icon={<FaList size={20} />} color="outline-dark">Tipos de SLA</NavItem>
                    <NavItem to="/slaCompromisso" icon={<FaRegCalendarCheck size={20} />} color="outline-dark">Compromissos</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Modelos de Sla</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Tipo</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.tipo, label: filtro.nomeTipo }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "tipo": e.value, "nomeTipo": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/slaTipo',
                                                                    token: token
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows="true"
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: row => <IconButton
                                                        small={true}
                                                        to={"/cadastroSlaModelo/" + row.id}
                                                        icon={<FaPen size={20} />}
                                                        color="primary">Alterar</IconButton>,
                                                    width: "110px",
                                                },
                                                {
                                                    name: 'Código',
                                                    selector: row => row.codigo ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Tipo',
                                                    selector: row => row.tipo?.nome ?? "",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Diretriz',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                },
                                                {
                                                    name: 'Complexidade',
                                                    selector: row => row.complexidade ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.complexidade ? row.complexidade : "";
                                                    },
                                                },
                                                {
                                                    name: 'Formato',
                                                    selector: row => row.tipoRealizacao ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.tipoRealizacao ? row.tipoRealizacao : "";
                                                    },
                                                },
                                            ]}
                                            data={slas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
