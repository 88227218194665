import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaSearch } from 'react-icons/fa';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';

export default function PainelEventoHistorico({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const [eventos, setEventos] = useState([]);

    const buscar = () => {
        api({
            method: "get",
            action: '/eventoHistorico/' + id,
            token: token,
        })
            .then(response => {
                setEventos(response.data.data);
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        buscar();
    }, 
    // eslint-disable-next-line
    []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Histórico</h3>
                </div>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            noContextMenu="true"
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: 'Data',
                                                    selector: row => row.data ?? "",
                                                    sortable: true,
                                                    width: "200px",
                                                    cell: (row) => {
                                                        var data = row.data ? " " + moment(row.data).format("DD/MM/YYYY H:m:s") : "";
                                                        return data;
                                                    }
                                                },
                                                {
                                                    name: 'Usuário',
                                                    selector: row => row.usuario?.nome ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    cell: (row) => {
                                                        return row.usuario ? row.usuario.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Histórico',
                                                    selector: row => row.historico ?? "",
                                                    width: "250px",
                                                    sortable: true,
                                                },
                                            ]}
                                            data={eventos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
