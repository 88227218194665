import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaChartPie, FaCodeBranch, FaRegCalendarCheck, FaPen, FaList, FaPlus, FaSearch } from 'react-icons/fa';
import { GiStairsGoal } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import ReactSelect from '../../components/ReactSelect';
import FormButton from '../../components/FormButton';
import Data from '../../components/Data';

export default function RelatorioCompraVenda() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const [filtro, setFiltro] = useState({
        empresa: empresa,
    });
    const [slas, setSlas] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Relatório de Faturas</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" method="post" target="_blank" action={process.env.REACT_APP_API_URL + "/venda/relatorioCompraVenda"}>
                                            <input type="hidden" name="token" value={localStorage.getItem('token')} />
                                            <input type="hidden" name="empresa" value={localStorage.getItem('empresa')} />
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Evento</label>
                                                        <ReactSelect
                                                            name="evento"
                                                            value={{ value: filtro.evento, label: filtro.nomeEvento }}
                                                            onChange={(e) => {
                                                                setFiltro({ ...filtro, ...{ "evento": e.value, "nomeEvento": e.label } });
                                                            }}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/evento',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacao: ["ativo", "fechado"]
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.nome;
                                                                            if (registro.identificacao) {
                                                                                label = registro.identificacao + " - " + label;
                                                                            }
                                                                            if (registro.cliente) {
                                                                                label = label + ' - ' + registro.cliente.nome;
                                                                            }
                                                                            label = registro.codigo + " - " + label;
                                                                            return { value: registro.id, label: label }
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="data1"
                                                        label="Data"
                                                        value={filtro.data1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        inputName="data2"
                                                        label="até a Data"
                                                        value={filtro.data2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "data2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
