import React, { useState, useEffect, useMemo } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaList, FaUpload, FaCalendarAlt, FaPen, FaPlus, FaSearch, FaThumbsUp, FaUsers, FaTable, FaQrcode, FaQuestion, FaFileAlt } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import Alert from '../../components/Alert';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Data from '../../components/Data';
import { dataMysql } from '../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { toast } from 'react-toastify';
import FormButton from '../../components/FormButton';

export default function PainelEventoOrdemServico() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [dados, setDados] = useState({});
    const [eventos, setEventos] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const params = new URLSearchParams(window.location.search);
    const idEvento = params.get('idEvento');

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        var quarterMap = [];
        if (filtro.quarter !== undefined)
            quarterMap = filtro.quarter.map((e) => { return e.value });
        //console.log(quarterMap);
        api({
            method: 'get',
            action: '/evento',
            token: token,
            params: {
                ...filtro,
                ...{ situacao: ['ativo', 'fechado', 'cancelado'] },
                ...{ perfil: perfil },
                ...{ id: idEvento }
            }
        })
            .then(response => {
                setEventos(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        //document.querySelector("#nome")?.focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerenteevento', 'evento'].includes(perfil)) ?
                    <FormButton
                        small={true}
                        style={{ marginLeft: "10px" }}
                        method="post"
                        action={process.env.REACT_APP_API_URL + "/evento/exportarOrdemServico"}
                        icon={<FaTable size={20} />}
                        color="primary"
                        data={{
                            token: localStorage.getItem('token'),
                            idEvento: tableSelect.selectedRows[0].id
                        }}
                    >Relatório</FormButton> : null,
                (tableSelect.selectedRows.length === 1 && ['diretor', 'gerenteevento', 'evento'].includes(perfil)) ?
                    <IconButton
                        small={true}
                        to={"/eventoOrdemServicoDia/" + tableSelect.selectedRows[0].id}
                        icon={<FaFileAlt size={20} />}
                        color="primary">Ordens de Serviço</IconButton> : null,
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate sidebar={['listarsvp'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Ordens de Serviço dos Eventos</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            id="cliente"
                                                            value={{ value: filtro.cliente, label: filtro.nomeCliente }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "cliente",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input autoFocus type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome do evento"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome do Evento</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows={!['listarsvp'].includes(perfil)}
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            loadOptions
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: 'Código',
                                                    selector: row => row.codigo ?? "",
                                                    sortable: true,
                                                    width: "80px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Situação',
                                                    selector: row => row.situacao ?? "",
                                                    sortable: true,
                                                    width: "80px",
                                                },
                                                {
                                                    name: 'Identificação',
                                                    selector: row => row.identificacao ?? "",
                                                    sortable: true,
                                                    width: "110px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Nome do evento',
                                                    selector: row => row.nome,
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.nome;
                                                    },
                                                },
                                                {
                                                    name: 'Cliente',
                                                    selector: row => row.cliente?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        if (row.cliente) {
                                                            if (row.cliente?.enderecomunicipio) { //tem municipio
                                                                return row.cliente.codigo + ' - ' + row.cliente.nome + ' - ' + row.cliente.enderecomunicipio.nome + ' - ' + row.cliente.enderecomunicipio.uf;
                                                            } else { //nao tem municipio
                                                                return row.cliente.codigo + ' - ' + row.cliente.nome;
                                                            }
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Data',
                                                    selector: row => row.data ?? "",
                                                    sortable: true,
                                                    width: "110px",
                                                    cell: (row) => {
                                                        var dataCompetencia = row.dataCompetencia ? " " + moment(row.dataCompetencia).format("MM/YYYY") : "";
                                                        var dataInicio = row.data ? " " + moment(row.data).format("DD/MM/YYYY") : "";
                                                        dataInicio = row.replanejadoInicio ? " " + moment(row.replanejadoInicio).format("DD/MM/YYYY") : dataInicio;
                                                        var dataFim = row.dataFim ? " " + moment(row.dataFim).format("DD/MM/YYYY") : "";
                                                        dataFim = row.replanejadoFim ? " " + moment(row.replanejadoFim).format("DD/MM/YYYY") : dataFim;
                                                        var dataCancelamento = row.dataCancelamento ? " Cancelado: " + moment(row.dataCancelamento).format("DD/MM/YYYY") : "";
                                                        var dataFechamento = row.dataFechamento ? " Fechado: " + moment(row.dataFechamento).format("DD/MM/YYYY") : "";
                                                        if (dataInicio === dataFim) {
                                                            return dataCompetencia + dataInicio + dataFechamento + dataCancelamento;
                                                        } else {
                                                            return dataCompetencia + dataInicio + dataFim + dataFechamento + dataCancelamento;
                                                        }
                                                    }
                                                },
                                            ]}
                                            data={eventos}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
