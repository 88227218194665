import React, { forwardRef } from 'react';
import InputMask from 'react-input-mask';

const CustomInput = forwardRef(({ inputName = null, onChange = null, placeholder = "", title = "", value = "", id = null, onClick = null, label, readOnly = false, disabled = null }, ref) => (
    <div className="form-floating form-group">
        <InputMask
            disabled={disabled}
            ref={ref}
            title={title}
            mask="99/99/9999"
            readOnly={readOnly}
            type="text"
            className="form-control form-control-lg"
            name={inputName}
            onChange={onChange}
            value={value}
            id={id}
            onClick={onClick}
        />
        <label htmlFor="floatingInput">{label}</label>
    </div>
));

export default CustomInput;
