import React from 'react';
import IconButton from './IconButton';
import { bin2base64 } from '../util/util';

export default function NavItem({ icon = null, to = null, children = null, color = null, target = null }) {
    return (
        <li className="nav-item">
            <IconButton to={to} icon={icon} color={color} target={target}>{children}</IconButton>
        </li>
    );
}