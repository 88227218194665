import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaCalendarAlt, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { data, dataMysql, extract, pontoVirgula, virgulaPonto } from '../../util/util';
import Data from '../../components/Data';
import IconButton from '../../components/IconButton';
import { toast } from 'react-toastify';
import ReactSelect from '../../components/ReactSelect';
import InputMask from 'react-input-mask';

export default function CadastroEventoAgenda({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const usuario = localStorage.getItem("idUsuario");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({ eventoagenda: [] });// 1 - evento
    const history = useHistory();

    const calculaTotal = () => {
    }//calculaTotal

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/evento/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: []
                });
                setDados(dadosRecebidos);
            }).catch(err => {
                // console.log(err)
            });
        } else {
            dados.eventoagenda = [];
        }
    };

    const salvar = () => {
        //converte todas as datas para mysql
        if (dados.eventoagenda)
            dados.eventoagenda.forEach((registro, index) => {
                dados.eventoagenda[index].data = dataMysql(dados.eventoagenda[index].data);
            });

        //update
        api({
            method: 'put',
            action: '/eventoAgenda/' + id,
            token: token,
            data: {
                ...dados,
                ...{ empresa: empresa }
            }
        })
            .then(function (response) {
                history.push("/evento");
            })
            .catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            });
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Agenda</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input readOnly={true} type="text" className="form-control form-control-lg" id="evento" placeholder="Evento"
                                                            value={dados.nome}
                                                        />
                                                        <label htmlFor="floatingInput">Evento</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dados.eventoagenda ? dados.eventoagenda.map((row, rowIndex) => {
                                                                            return [
                                                                                <tr>
                                                                                    <td>
                                                                                        <IconButton
                                                                                            title="Remover"
                                                                                            color="warning"
                                                                                            icon={<FaTrash size={20} />}
                                                                                            onClick={() => {
                                                                                                dados.eventoagenda.splice(rowIndex, 1);
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <Data
                                                                                            value={data(row.data)}
                                                                                            onChange={(date) => {
                                                                                                dados.eventoagenda[rowIndex].data = date;
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <InputMask mask="99:99" className="form-control form-control-lg" id="hora" placeholder="Hora"
                                                                                                value={dados.eventoagenda[rowIndex].hora}
                                                                                                onChange={(e) => {
                                                                                                    dados.eventoagenda[rowIndex].hora = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Hora</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <label>Palestrante</label>
                                                                                            <ReactSelect
                                                                                                value={row.palestrante !== null ? { value: row.palestrante?.id ?? null, label: row.palestrante?.nome ?? null } : null}
                                                                                                onChange={(e) => {
                                                                                                    dados.eventoagenda[rowIndex].palestrante = {};
                                                                                                    dados.eventoagenda[rowIndex].palestrante.id = e.value;
                                                                                                    dados.eventoagenda[rowIndex].palestrante.nome = e.label;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                                defaultOptions
                                                                                                loadOptions={(input) => {
                                                                                                    return api({
                                                                                                        method: 'get',
                                                                                                        action: '/produto',
                                                                                                        token: token,
                                                                                                        params: {
                                                                                                            nome: input,
                                                                                                            modulo: 'servicoOrcamento',
                                                                                                            superiorIsNull: true
                                                                                                        }
                                                                                                    })
                                                                                                        .then(response => {
                                                                                                            let options = response.data.data.map((registro) => {
                                                                                                                return { value: registro.id, label: registro.codigo + ' - ' + registro.nome };
                                                                                                            });
                                                                                                            return options;
                                                                                                        })
                                                                                                        .catch(function (error) {
                                                                                                            //console.log(error);
                                                                                                        });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg right" id="participantes" placeholder="Participantes"
                                                                                                value={pontoVirgula(dados.eventoagenda[rowIndex].participantes)}
                                                                                                onChange={(e) => {
                                                                                                    dados.eventoagenda[rowIndex].participantes = e.target.value;
                                                                                                    calculaTotal();
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Participantes</label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>,
                                                                                <tr>
                                                                                    <td colSpan="5">
                                                                                        <div className="form-floating form-group">
                                                                                            <textarea
                                                                                                className="form-control form-control-lg"
                                                                                                id="topico"
                                                                                                placeholder="Tópico"
                                                                                                style={{ minHeight: "100px" }}
                                                                                                value={dados.eventoagenda[rowIndex].topico}
                                                                                                onChange={(e) => {
                                                                                                    dados.eventoagenda[rowIndex].topico = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Tópico</label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ];
                                                                        }) : null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            dados.eventoagenda.push({});
                                                            setDados({ ...dados });
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Item</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}