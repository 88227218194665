import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadButton from '../../components/UploadButton';
import { FaFileDownload, FaUpload } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import api from '../../services/api';
import FormButton from '../../components/FormButton';

export default function EventoImportarPerguntasSatisfacao({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const [arquivos, setArquivos] = useState([]);
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    let { state } = useLocation();
    var nomeEvento = state?.nomeEvento ?? null;

    const [dados, setDados] = useState({
        empresa: empresa,
        nomeEmpresa: nomeEmpresa
    });
    const history = useHistory();

    const buscar = (idParticipante) => {
        api({
            method: "get",
            action: '/eventoArquivo',
            token: token,
            params: {
                tipo: "perguntasSatisfacao",
                evento: id,
            }
        }).then(response => {
            var dadosRecebidos = response.data.data;
            setArquivos(dadosRecebidos);
        }).catch(err => {
            //console.log(err)
        });
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Evento: {nomeEvento}</h3>
                    <h3>Importar Planilha</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <UploadButton
                                                        icon={<FaUpload size={20} />}
                                                        color="primary"
                                                        acaoApi={"eventoImportarPerguntasSatisfacao"}
                                                        title="Selecionar Arquivo"
                                                        beforeSend={() => {
                                                            toast.info('Aguarde, isso pode levar alguns minutos');
                                                        }}
                                                        callBack={(data) => {
                                                            if (data.result === "sucesso") {
                                                                toast.success('Importação finalizada');
                                                                history.push("/cadastroEventoSatisfacaoPergunta/" + id);
                                                            } else {//error
                                                                toast.error(data.message);
                                                            }
                                                        }}
                                                        dados={{
                                                            empresa: empresa,
                                                            evento: id
                                                        }}
                                                    >Selecionar Arquivo</UploadButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="row">
                                            <h4>Arquivos</h4>
                                            <div className="table-responsive">
                                                <DataTable
                                                    noContextMenu="true"
                                                    noDataComponent="Nenhum registro"
                                                    className="table mb-0"
                                                    columns={[
                                                        {
                                                            name: 'Arquivo',
                                                            selector: (row) => {
                                                                return row.arquivo?.id ?? "";
                                                            },
                                                            cell: row => row.arquivo?.id ? <FormButton
                                                                method="get"
                                                                title="Baixar arquivo"
                                                                action={process.env.REACT_APP_API_URL + "/eventoArquivo/" + row.id}
                                                                icon={<FaFileDownload size={20} />}
                                                                color="primary"
                                                                data={{
                                                                    token: localStorage.getItem('token'),
                                                                }}
                                                            /> : null,
                                                            width: "80px",
                                                        },
                                                        {
                                                            name: 'Data',
                                                            selector: 'created_at',
                                                            width: "200px",
                                                            cell: row => moment(row.created_at).format("DD/MM/YYYY HH:mm"),
                                                        },
                                                        {
                                                            name: 'Operador',
                                                            selector: (row) => {
                                                                return row.operador?.nome ?? "";
                                                            },
                                                            cell: (row) => {
                                                                return row.operador?.nome ?? "";
                                                            },
                                                            width: "200px",
                                                        },
                                                    ]}
                                                    data={arquivos}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}