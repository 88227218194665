import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../../components/ErpTemplate';
import { FaThumbsUp, FaPen, FaPlus, FaSearch, FaFileAlt, FaFileInvoice, FaUpload, FaFileInvoiceDollar, FaEdit, FaThumbsDown } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import NavItem from '../../../../components/NavItem';
import IconButton from '../../../../components/IconButton';
import Alert from '../../../../components/Alert';
import api from '../../../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Data from '../../../../components/Data';
import UploadButton from '../../../../components/UploadButton';
import FormButton from '../../../../components/FormButton';
import { dataMysql, moeda } from '../../../../util/util';
import ReactSelect from '../../../../components/ReactSelect';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

export default function PainelParcelaPagar() {
    let { state } = useLocation();
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const usuario = localStorage.getItem("idUsuario");
    const [filtro, setFiltro] = useState({
        tipo: 1,
        empresa: empresa,
        idTitulo: state?.idTitulo ?? null,
    }); //1 - pagar | 2 - receber
    const [dados, setDados] = useState({ pagamento: new Date() });
    const [parcelas, setParcelas] = useState([]);
    const [tableSelect, setTableSelect] = useState([]);
    const [tableActions, setTableActions] = useState([]);
    const params = new URLSearchParams(window.location.search);
    const grupoTitulo = params.get('grupoTitulo');
    const parcelaabater = state?.parcelaabater ?? null;

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        api({
            method: 'get',
            action: '/financeiroParcela',
            token: token,
            params: {
                ...filtro,
                ...{ grupoTitulo: grupoTitulo },
                ...{ parcelaabater: parcelaabater },
            }
        })
            .then(response => {
                setParcelas(response.data.data);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            })
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        [filtro.tipo, filtro.evento]);

    useEffect(() => { //onload
        var ids = tableSelect.selectedRows ? tableSelect.selectedRows.map((row) => {
            return row.id;
        }).join(",") : "";
        if (tableSelect.selectedCount > 0) {
            setTableActions([
                <IconButton
                    small={true}
                    to={{
                        pathname: "/financeiro/alterarContaFornecedor",
                        state: {
                            idParcelas: tableSelect.selectedRows.map((parcela) => { return parcela.id; }),
                        }
                    }} icon={<FaEdit size={20} />}
                    color="primary">Alterar Conta e Favorecido</IconButton>,
                ((tableSelect.selectedRows.length === 1) && //apenas 1 selecionado
                    (tableSelect.selectedRows[0].situacao === "1")) //apenas se ainda estiver em aberto 
                    ?
                    <IconButton
                        small={true}
                        icon={<FaThumbsUp size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "question",
                                confirmButton: <><FaThumbsUp size={20} /> Pagar</>,
                                denyButton: "Sair",
                                title: "Pagar Parcela",
                                html:
                                    <Data
                                        readOnly={false}
                                        label="Data Pagamento"
                                        value={dados.pagamento}
                                        onChange={(date) => {
                                            dados.pagamento = date;
                                            setDados({ ...dados });
                                        }}
                                    />,
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isConfirmed) {
                                        api({
                                            method: 'put',
                                            action: '/financeiroParcela/' + tableSelect.selectedRows[0].id,
                                            token: token,
                                            data: {
                                                situacao: "2", //2 - pago
                                                pagamento: dataMysql(dados.pagamento)
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Pagar</IconButton > : null,
                ((tableSelect.selectedRows.length === 1) && //apenas 1 selecionado
                    (tableSelect.selectedRows[0].situacao === "1")) //apenas se ainda estiver em aberto 
                    ?
                    <IconButton
                        small={true}
                        icon={<FaThumbsDown size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "question",
                                confirmButton: <><FaThumbsUp size={20} /> Estornar</>,
                                denyButton: "Sair",
                                title: "Estornar Parcela",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isConfirmed) {
                                        api({
                                            method: 'put',
                                            action: '/financeiroParcela/estornar/' + tableSelect.selectedRows[0].id,
                                            token: token,
                                            data: {
                                                situacao: "1", // 1 - aberto
                                            }
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Estornar</IconButton > : null,
                ((tableSelect.selectedRows.length === 1) && //apenas 1 selecionado
                    (
                        ((tableSelect.selectedRows[0].situacao === "1") && (tableSelect.selectedRows[0].operador?.id === usuario)) ||
                        (['diretor', 'gerentefinanceiro'].includes(perfil))
                    )) ? //apenas gerente financeiro 
                    <IconButton
                        small={true}
                        icon={<ImCancelCircle size={20} />}
                        onClick={() => {
                            Alert({
                                icon: "error",
                                confirmButton: "Sair",
                                denyButton: <><FaThumbsUp size={20} /> Excluir</>,
                                title: "Excluir Parcela",
                                callBack: (result) => {
                                    //{isConfirmed: false, isDenied: true, isDismissed: false, value: false}
                                    if (result.isDenied) {
                                        api({
                                            method: 'delete',
                                            action: '/financeiroParcela/' + tableSelect.selectedRows[0].id,
                                            token: token
                                        })
                                            .then(function (response) {
                                                buscar();
                                                //alert("Evento cancelado com sucesso!");
                                            })
                                            .catch(function (error) {
                                                //alert(error)
                                            });
                                    }
                                }
                            });
                        }
                        }
                        color="primary"> Excluir</IconButton > : null,
                tableSelect.selectedRows.length === 1 ? //apenas 1 selecionado
                    <UploadButton
                        small={true}
                        icon={<FaUpload size={20} />}
                        color="primary"
                        acaoApi={"financeiroParcelaArquivo/" + tableSelect.selectedRows[0].id}
                        title="Boleto"
                        callBack={() => {
                            buscar();
                        }}
                        dados={{
                            tipoArquivo: "boleto",
                            modulo: "financeiro",
                            pasta: "boleto"
                        }}
                    >Boleto</UploadButton> : null,
                tableSelect.selectedRows.length === 1 ? //apenas 1 selecionado
                    <UploadButton
                        small={true}
                        icon={<FaUpload size={20} />}
                        color="primary"
                        acaoApi={"financeiroParcelaArquivo/" + tableSelect.selectedRows[0].id}
                        title="Comprovante de pagamento"
                        callBack={() => {
                            buscar();
                        }}
                        dados={{
                            tipoArquivo: "comprovantepagamento",
                            modulo: "financeiro",
                            pasta: "comprovantepagamento"
                        }}
                    >Comprovante</UploadButton> : null
            ]);
        } else {
            setTableActions([]);
        }
    },
        // eslint-disable-next-line
        [tableSelect]);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <ul className="nav">
                    <NavItem to="/cadastroParcelaPagar" icon={<FaPlus size={20} />} color="primary">Nova Despesa</NavItem>
                    <NavItem to={{
                        pathname: "/financeiroParcelaRelatorio",
                        state: {
                            tipo: 1 //1 - pagar
                        }
                    }}
                        icon={<FaFileAlt size={20} />}
                        color="outline-dark">Relatório de contas a pagar</NavItem>
                </ul>
                <div className="page-title">
                    <h3>Pagar</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { filtro.idTitulo = null; buscar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="Data Vencimento"
                                                        value={filtro.dataVencimento1}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataVencimento1": date } })}
                                                    />
                                                </div>
                                                <div className="col-sm-6">
                                                    <Data
                                                        label="até a Data Vencimento"
                                                        value={filtro.dataVencimento2}
                                                        onChange={date => setFiltro({ ...filtro, ...{ "dataVencimento2": date } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Favorecido</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.participante, label: filtro.nomeParticipante }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "participante": e.value, "nomeParticipante": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Plano de Contas</label>
                                                        <ReactSelect
                                                            value={{ value: filtro.conta, label: filtro.nomeConta }}
                                                            onChange={e => setFiltro({ ...filtro, ...{ "conta": e.value, "nomeConta": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/financeiroPlanoConta',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipo: "1",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map(registro => ({ value: registro.id, label: registro.caminho + ' - ' + registro.nome }));
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="descricao" placeholder="Descrição"
                                                            onChange={e => setFiltro({ ...filtro, ...{ "descricao": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Descrição</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSearch size={20} /> Buscar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row mb-4">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            actions={tableActions}
                                            selectableRows="true"
                                            selectableRowsHighlight="true"
                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                setTableSelect({ allSelected, selectedCount, selectedRows });
                                            }}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    selector: 'codigo',
                                                    cell: (row) => {
                                                        if (row.situacao === "1") { //1 - aberto
                                                            return <IconButton
                                                                small={true}
                                                                to={"/cadastroParcelaPagar/" + row.titulo.id}
                                                                icon={<FaPen size={20} />}
                                                                color="primary">Alterar</IconButton>;
                                                        } else {
                                                            return null;
                                                        }
                                                    },
                                                    width: "110px",
                                                },
                                                {
                                                    name: '',
                                                    selector: 'arquivoboleto',
                                                    cell: row => row.arquivoboleto?.id ? <FormButton
                                                        method="get"
                                                        title="Boleto"
                                                        action={process.env.REACT_APP_API_URL + "/financeiroParcelaArquivo/" + row.id}
                                                        icon={<FaFileInvoice size={20} />}
                                                        color="primary"
                                                        data={{
                                                            token: localStorage.getItem('token'),
                                                            tipoArquivo: "boleto"
                                                        }}
                                                    /> : null,
                                                    width: "60px",
                                                },
                                                {
                                                    name: '',
                                                    selector: 'arquivocomprovantepagamento',
                                                    cell: row => row.arquivocomprovantepagamento?.id ? <FormButton
                                                        method="get"
                                                        title="Comprovante Pagamento"
                                                        action={process.env.REACT_APP_API_URL + "/financeiroParcelaArquivo/" + row.id}
                                                        icon={<FaFileInvoiceDollar size={20} />}
                                                        color="primary"
                                                        data={{
                                                            token: localStorage.getItem('token'),
                                                            tipoArquivo: "comprovantepagamento"
                                                        }}
                                                    /> : null,
                                                    width: "60px",
                                                },
                                                {
                                                    name: 'Situação',
                                                    selector: row => row.situacao ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    cell: (row) => {
                                                        return row.situacao === "1" ? "Aberto" : "Pago";
                                                    },
                                                },
                                                {
                                                    name: 'Número',
                                                    selector: row => row.titulo?.numero ?? "",
                                                    sortable: true,
                                                    width: "100px",
                                                    right: true,
                                                },
                                                {
                                                    name: 'Conta',
                                                    width: "150px",
                                                    cell: (row) => {
                                                        return row.conta ? row.conta.caminho + ' - ' + row.conta.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Caixa',
                                                    width: "130px",
                                                    cell: (row) => {
                                                        return row.caixa ? row.caixa.codigo + '-' + row.caixa.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Referência',
                                                    cell: (row) => {
                                                        var textoReferencia = row.titulo.venda.map((venda) => venda.codigo ? venda.codigo : "Fornecedor").join(",");
                                                        var referencia = <div>
                                                            <Link
                                                                title="Faturas relacionadas"
                                                                to={{
                                                                    pathname: "/compra",
                                                                    state: {
                                                                        grupoTitulo: row.titulo.grupo,
                                                                    }
                                                                }}>
                                                                {textoReferencia}
                                                            </Link>
                                                        </div>;

                                                        var descricao = <div><strong>{row.descricao}</strong></div>;
                                                        return <div>
                                                            {referencia}
                                                            {descricao}
                                                            <p title="Usuário que registrou">{row.operador?.nome}</p>
                                                        </div>;
                                                    },
                                                },
                                                {
                                                    name: 'Favorecido',
                                                    selector: row => row.participante?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.participante ? row.participante.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Evento',
                                                    selector: row => row.titulo?.venda?.evento?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return row.titulo?.venda[0]?.evento ? row.titulo.venda[0].evento.nome : "";
                                                    },
                                                },
                                                {
                                                    name: 'Competência',
                                                    selector: row => row.competencia ?? "",
                                                    sortable: true,
                                                    width: "120px",
                                                    cell: (row) => {
                                                        return row.competencia ? " " + moment(row.competencia).format("MM/YYYY") : "";
                                                    },
                                                },
                                                {
                                                    name: 'Vencimento',
                                                    selector: row => row.vencimento ?? "",
                                                    sortable: true,
                                                    width: "120px",
                                                    cell: (row) => {
                                                        return row.vencimento ? " " + moment(row.vencimento).format("DD/MM/YYYY") : "";
                                                    },
                                                },
                                                {
                                                    name: 'Pagamento',
                                                    selector: row => row.pagamento ?? "",
                                                    sortable: true,
                                                    width: "110px",
                                                    cell: (row) => {
                                                        return row.pagamento ? " " + moment(row.pagamento).format("DD/MM/YYYY") : "";
                                                    },
                                                },
                                                {
                                                    name: 'Valor',
                                                    selector: row => row.valor ?? "",
                                                    sortable: true,
                                                    right: true,
                                                    width: "120px",
                                                    cell: (row) => {
                                                        return moeda(row.valor);
                                                    },
                                                },
                                            ]}
                                            data={parcelas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
