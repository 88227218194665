import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaCalendarAlt, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import api from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMysql, extract, pontoVirgula, virgulaPonto } from '../../util/util';
import IconButton from '../../components/IconButton';
import { toast } from 'react-toastify';
import ReactSelect from '../../components/ReactSelect';
import InputMask from 'react-input-mask';

export default function CadastroEventoRsvpOpcaoAereo({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const usuario = localStorage.getItem("idUsuario");
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({ "opcoes": [] });// 1 - evento
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const idParticipante = params.get('idParticipante');

    const calculaTotal = () => {
    }//calculaTotal

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/eventoAereo',
                params: {
                    evento: id,
                    idParticipante: idParticipante
                },
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = response.data.data;
                setDados(dadosRecebidos);
            }).catch(err => {
                // console.log(err)
            });
        } else {
            dados.opcoes = [];
        }
    };

    const salvar = () => {
        //converte todas as datas para mysql
        if (dados.opcoes[0])
            dados.opcoes.forEach((registro, index) => {
                dados.opcoes[index].data = dataMysql(dados.opcoes[index].data);
            });

        //update
        api({
            method: 'put',
            action: '/eventoAereo/' + id,
            token: token,
            data: {
                ...{
                    participante: idParticipante,
                    evento: id,
                    empresa: empresa,
                    opcoes: dados.opcoes
                }
            }
        })
            .then(function (response) {
                if (idParticipante) {
                    history.push("/cadastroEventoParticipante/" + idParticipante);
                } else {
                    history.push("/cadastroEventoRsvp/" + id);
                }
            })
            .catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            });
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Opções de Aéreo</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating form-group">
                                                        <input readOnly={true} type="text" className="form-control form-control-lg" id="evento" placeholder="Evento" value={(dados.evento?.nomersvp ?? dados.evento?.nome ?? "")} />
                                                        <label htmlFor="floatingInput">Evento</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {dados.participante?.id ?
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-floating form-group">
                                                            <input readOnly={true} type="text" className="form-control form-control-lg" id="evento" placeholder="Evento" value={dados.participante?.nome ?? ""} />
                                                            <label htmlFor="floatingInput">Participante</label>
                                                        </div>
                                                    </div>
                                                </div> : null}
                                            <div className="row mb-4">
                                                <div className="col-sm-12">
                                                    <div className="card">
                                                        <div className="card-body px-0 pb-0">
                                                            <div className="table-responsive">
                                                                <table className='table mb-0' id="table1">
                                                                    <thead>
                                                                        <tr>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                            <td width="50px"></td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dados.opcoes[0] ? dados.opcoes.map((row, rowIndex) => {
                                                                            return [
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="aeroportoOrigem" placeholder="Aeroporto de Origem"
                                                                                                value={dados.opcoes[rowIndex].aeroportoOrigem}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].aeroportoOrigem = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Aeroporto de Origem</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="idadata" placeholder="Data ida"
                                                                                                value={dados.opcoes[rowIndex].idadata}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].idadata = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Data Ida</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="idacompanhia" placeholder="Companhia ida"
                                                                                                value={dados.opcoes[rowIndex].idacompanhia}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].idacompanhia = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Companhia Ida</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="idavoo" placeholder="Voo ida"
                                                                                                value={dados.opcoes[rowIndex].idavoo}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].idavoo = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Voo Ida</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="idaorigem" placeholder="Origem ida"
                                                                                                value={dados.opcoes[rowIndex].idaorigem}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].idaorigem = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Origem Ida</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="idadestino" placeholder="Destino ida"
                                                                                                value={dados.opcoes[rowIndex].idadestino}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].idadestino = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Destino Ida</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="idahorasaida" placeholder="Hora Saída ida"
                                                                                                value={dados.opcoes[rowIndex].idahorasaida}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].idahorasaida = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Hora Saída Ida</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="idahorachegada" placeholder="Hora Chegada ida"
                                                                                                value={dados.opcoes[rowIndex].idahorachegada}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].idahorachegada = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Hora Chegada Ida</label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>,
                                                                                <tr>
                                                                                    <td>
                                                                                        <IconButton
                                                                                            title="Remover"
                                                                                            color="warning"
                                                                                            icon={<FaTrash size={20} />}
                                                                                            onClick={() => {
                                                                                                dados.opcoes.splice(rowIndex, 1);
                                                                                                setDados({ ...dados });
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="retornodata" placeholder="Data retorno"
                                                                                                value={dados.opcoes[rowIndex].retornodata}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].retornodata = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Data Retorno</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="retornocompanhia" placeholder="Companhia retorno"
                                                                                                value={dados.opcoes[rowIndex].retornocompanhia}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].retornocompanhia = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Companhia Retorno</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="retornovoo" placeholder="Voo retorno"
                                                                                                value={dados.opcoes[rowIndex].retornovoo}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].retornovoo = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Voo Retorno</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="retornoorigem" placeholder="Origem retorno"
                                                                                                value={dados.opcoes[rowIndex].retornoorigem}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].retornoorigem = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Origem Retorno</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="retornodestino" placeholder="Destino retorno"
                                                                                                value={dados.opcoes[rowIndex].retornodestino}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].retornodestino = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Destino Retorno</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="retornohorasaida" placeholder="Hora Saída retorno"
                                                                                                value={dados.opcoes[rowIndex].retornohorasaida}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].retornohorasaida = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Hora Saída Retorno</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="form-floating form-group">
                                                                                            <input type="text" className="form-control form-control-lg" id="retornohorachegada" placeholder="Hora Chegada retorno"
                                                                                                value={dados.opcoes[rowIndex].retornohorachegada}
                                                                                                onChange={(e) => {
                                                                                                    dados.opcoes[rowIndex].retornohorachegada = e.target.value;
                                                                                                    setDados({ ...dados });
                                                                                                }}
                                                                                            />
                                                                                            <label htmlFor="floatingInput">Hora Chegada Retorno</label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ];
                                                                        }) : null}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 d-flex">
                                                    <button type="button" className="btn btn-primary me-1 mb-1"
                                                        onClick={() => {
                                                            dados.opcoes.push({});
                                                            setDados({ ...dados });
                                                        }}>
                                                        <FaPlus size={20} /> Adicionar Item</button>
                                                </div>
                                            </div>
                                            <div className="row fixoRodape">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}